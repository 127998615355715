import Vue from 'vue'
import Vuetify, {
	VApp,
	VMain,
	VSheet,
	VBottomSheet,
	VBtn,
	VContainer,
	VRow,
	VCol,
	VProgressCircular,
	VIcon,
	VForm,
	VTextField,
	VCard,
	VSelect,
	VStepper,
	VStepperStep,
	VStepperItems,
	VStepperContent,
	VDivider,
	VToolbar,
	VToolbarTitle,
	VSpacer,
	VCardText,
	VCardActions,
	VCardTitle,
	VRadioGroup,
	VRadio,
	VTextarea,
	VCheckbox,
	VDialog,
	VImg,
	VTooltip,
	VAlert,
	VCarousel,
	VCarouselItem,
	VSlider,
	VItemGroup,
	VItem,
	VSlideGroup,
	VSlideItem,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelContent,
	VExpansionPanelHeader,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
	components: {
		VApp,
		VMain,
		VSheet,
		VBottomSheet,
		VBtn,
		VContainer,
		VRow,
		VCol,
		VProgressCircular,
		VIcon,
		VForm,
		VTextField,
		VCard,
		VSelect,
		VStepper,
		VStepperStep,
		VStepperItems,
		VStepperContent,
		VDivider,
		VDialog,
		VToolbar,
		VToolbarTitle,
		VSpacer,
		VCardText,
		VCardActions,
		VCardTitle,
		VRadioGroup,
		VRadio,
		VTextarea,
		VCheckbox,
		VImg,
		VTooltip,
		VAlert,
		VCarousel,
		VCarouselItem,
		VSlider,
		VItemGroup,
		VItem,
		VSlideGroup,
		VSlideItem,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelContent,
		VExpansionPanelHeader,
	},
	directives: {
		Ripple,
	},
})

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	theme: {
		themes: {
			light: {
				primary: '#1976D2',
				secondary: '#424242',
				accent: '#E4E4E4',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			},
		},
	},
	customVariables: ['~/assets/styles/variables.sass'],
})
