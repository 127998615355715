import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/requests'

const getAll = () => {
	return httpClient.get(END_POINT)
}

const getById = (orderId) => {
	return httpClient.get(END_POINT + '/' + orderId)
}

const getForLoggedInCustomer = () => {
	return httpClient.get(END_POINT + '/customer/loggedin')
}

const getByCustomer = (customerId) => {
	return httpClient.get(END_POINT + '/customer/' + customerId)
}

const create = (data) => {
	return httpClient.post(END_POINT + '/create', { data })
}

// const deleteById = (orderId) => {
// 	return httpClient.delete(END_POINT + 'delete', { orderId })
// }

const update = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/update', { data })
}

const addNote = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/add-note', { data })
}

const deleteNote = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/delete-note', { data })
}

const addMessage = (orderId, data) => {
	return httpClient.post(END_POINT + '/' + orderId + '/add-message', { data })
}

const acceptOffer = (orderId) => {
	return httpClient.post(END_POINT + '/' + orderId + '/accept-offer')
}

const declineOffer = (orderId) => {
	return httpClient.post(END_POINT + '/' + orderId + '/decline-offer')
}

const addFile = (orderId, fileId) => {
	return httpClient.post(END_POINT + '/' + orderId + '/add-file', { fileId })
}

export {
	getAll,
	getById,
	getByCustomer,
	update,
	create,
	addNote,
	deleteNote,
	getForLoggedInCustomer,
	addMessage,
	acceptOffer,
	declineOffer,
	addFile,
}
