export default {
	hoodie: {
		editId: 'f528c6ce-69f8-4663-81a1-a66d722259f6',
		ref: {
			_id: '60ca2749e49a5989245a3c64',
			images: ['https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/DFM0_STSU853_C526.jpg'],
			colorImages: [
				{
					colorCode: 'C511',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C511_front_1623861034094.jpg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C511_back_1623861035465.jpg',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C511_detail_1623861037054.jpg',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C511_studio_1623861041485.jpg',
				},
				{
					colorCode: 'C520',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C520_front_1623861043434.jpg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C520_back_1623861044682.jpg',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C520_detail_1623861046315.jpg',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C520_studio_1623861049237.jpg',
				},
				{
					colorCode: 'C526',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C526_front_1623861051029.jpg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C526_back_1623861052353.jpg',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C526_detail_1623861053873.jpg',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C526_studio_1623861057410.jpg',
				},
				{
					colorCode: 'C522',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C522_front_1623861058908.jpg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C522_back_1623861060132.jpg',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C522_detail_1623861061651.jpg',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C522_studio_1623861064436.jpg',
				},
			],
			externalArticleNumber: 'STSU853',
			shopName: 'stanley-stella',
			name: 'Cruiser Vintage',
			description:
				'Eingesetzter Ärmel (Set-In) \nKapuze mit abgesetztem Futter, aus Eigenstoff\nRunde gleichfarbige Kordel, Kordelenden aus Metall\nMetallösen am Kordelzug\nNackenband aus Single Jersey\nAbgesetzter Halbmond im Nacken\nEinzelsteppung am Ausschnitt und rund um die Kapuzenöffnung\n1x1-Rippstrick an Ärmelenden und unterem Saum\nDoppelsteppung an Ärmelansätzen, Ärmelenden und unterem Saum\nKängurutasche vorn\nVerwaschene Optik an allen Nähten und gealterte Farben ergeben zusätzlich einen unregelmäßig verwaschenen Effekt auf der Stoffoberfläche.\nStückgefärbtes Produkt, jedes Kleidungsstück kann etwas anders aussehen, die Farbe kann sich auswaschen, Befolgen sie unserem Pflegeetiket\nLesen Sie bitte die Druckempfehlungen sorgfältig durch\n',
			grammar: '300',
			certificates: 'Global Organic Textile Standard | Peta - Approved Vegan',
			style: 'standard',
			productType: 'hoodie-zipper',
			materials: '100% gekämmte ringgesponnene Bio-Baumwolle',
			label: '',
			category: 'hoodie',
			visible: true,
			sizes: [
				{ _id: '60ca2749e49a5989245a3c65', value: 'XS' },
				{ _id: '60ca2749e49a5989245a3c66', value: 'S', amount: 4 },
				{ _id: '60ca2749e49a5989245a3c67', value: 'M', amount: 10 },
				{ _id: '60ca2749e49a5989245a3c68', value: 'L', amount: 10 },
				{ _id: '60ca2749e49a5989245a3c69', value: 'XL', amount: 5 },
				{ _id: '60ca2749e49a5989245a3c6a', value: 'XXL', amount: 1 },
			],
			colors: [
				{ _id: '60ca2749e49a5989245a3c6b', value: 'G. Dyed Cadet Blue', hexValue: 'C511' },
				{ _id: '60ca2749e49a5989245a3c6c', value: 'G. Dyed Aged India Ink Grey', hexValue: 'C520' },
				{ _id: '60ca2749e49a5989245a3c6d', value: 'G. Dyed Aged Lilac Petal', hexValue: 'C526' },
				{ _id: '60ca2749e49a5989245a3c6e', value: 'G. Dyed Aged Rose Clay', hexValue: 'C522' },
			],
			variants: [
				{
					_id: '60ca2749e49a5989245a3c6f',
					size: 'XS',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C511XS',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c70',
					size: 'S',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C5111S',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c71',
					size: 'M',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C5111M',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c72',
					size: 'L',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C5111L',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c73',
					size: 'XL',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C5111X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c74',
					size: 'XXL',
					color: 'G. Dyed Cadet Blue',
					hexColor: 'C511',
					articleNumber: 'STSU853C5112X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c75',
					size: 'XS',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C520XS',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c76',
					size: 'S',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C5201S',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c77',
					size: 'M',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C5201M',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c78',
					size: 'L',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C5201L',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c79',
					size: 'XL',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C5201X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7a',
					size: 'XXL',
					color: 'G. Dyed Aged India Ink Grey',
					hexColor: 'C520',
					articleNumber: 'STSU853C5202X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7b',
					size: 'XS',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C526XS',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7c',
					size: 'S',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C5261S',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7d',
					size: 'M',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C5261M',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7e',
					size: 'L',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C5261L',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c7f',
					size: 'XL',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C5261X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c80',
					size: 'XXL',
					color: 'G. Dyed Aged Lilac Petal',
					hexColor: 'C526',
					articleNumber: 'STSU853C5262X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c81',
					size: 'XS',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C522XS',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c82',
					size: 'S',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C5221S',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c83',
					size: 'M',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C5221M',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c84',
					size: 'L',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C5221L',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c85',
					size: 'XL',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C5221X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
				{
					_id: '60ca2749e49a5989245a3c86',
					size: 'XXL',
					color: 'G. Dyed Aged Rose Clay',
					hexColor: 'C522',
					articleNumber: 'STSU853C5222X',
					purchasingPrice: {
						'purchasingPrice<10': 33.9,
						'purchasingPrice>10': 33.9,
						'purchasingPrice>50': 32.15,
						'purchasingPrice>100': 30.35,
						'purchasingPrice>250': 28.55,
						'purchasingPrice>500': 26.8,
						'purchasingPrice>1000': 25.55,
					},
				},
			],
			'purchasingPrice<10': 33,
			'purchasingPrice>10': 33,
			'purchasingPrice>50': 32,
			'purchasingPrice>100': 30,
			'purchasingPrice>250': 28,
			'purchasingPrice>500': 26,
			'purchasingPrice>1000': 25,
			piecesPerBox: 20,
			productionCountry: 'BD',
			hsCode: '61102091',
			weight: 0,
			mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Cruiser-Vintage_C511_front_1623861034094.jpg',
			createdAt: '2021-06-16T16:31:05.940Z',
			updates: [],
			__v: 0,
		},
		internalId: '60ca2749e49a5989245a3c64',
		externalId: 'STSU853',
		shop: 'stanley-stella',
		name: 'Cruiser Vintage',
		type: 'hoodie',
		colors: [
			{ _id: '60ca2749e49a5989245a3c6b', value: 'G. Dyed Cadet Blue', hexValue: 'C511' },
			{ _id: '60ca2749e49a5989245a3c6c', value: 'G. Dyed Aged India Ink Grey', hexValue: 'C520' },
			{ _id: '60ca2749e49a5989245a3c6d', value: 'G. Dyed Aged Lilac Petal', hexValue: 'C526' },
			{ _id: '60ca2749e49a5989245a3c6e', value: 'G. Dyed Aged Rose Clay', hexValue: 'C522' },
		],
		variants: {
			0: {
				hexColor: '',
				color: 'G. Dyed Cadet Blue',
				approved: false,
				finishes: { 0: { type: 'single', colors: [{ hexColor: '#000000' }] } },
				sizes: [
					{ size: 'S', amount: 15 },
					{ size: 'M', amount: 10 },
					{ size: 'L', amount: 10 },
				],
				extractedHexColor: '#2c4f8a',
			},
		},
		selectedSizes: ['S', 'M', 'L'],
		finishes: {
			0: {
				technique: 'print',
				techniqueType: null,
				fileName: 'dummy_design_1_1237892374925',
				editId: null,
				size: { width: 6, height: 0 },
				sizeMeasure: 'width',
				side: 'front',
				position: 'front-left',
				coords: {},
				imageWidth: 0,
				imageHeight: 0,
				idx: '0',
			},
		},
		addons: [],
	},
	cap: {
		editId: '6ffe8e64-72b6-4665-aa6c-8e3f8b916fb5',
		ref: {
			_id: '60ca42cda4cacda694bef5d2',
			images: [
				'AT301_Navy.jpg',
				'AT301_White.jpg',
				'AT301_Dark-Grey.jpg',
				'AT301_Camouflage.jpg',
				'AT301_Khaki.jpg',
				'AT301_Olive.jpg',
				'AT301_Black.jpg',
			],
			colorImages: [
				{
					colorCode: '1D252D',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936552339.jpeg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
				},
				{
					colorCode: 'C4BBA3',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936532775.jpeg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
				},
				{
					colorCode: 'FFFFFF',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936538830.jpeg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
				},
				{
					colorCode: '535435',
					front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936545726.jpeg',
					back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
					studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
				},
			],
			externalArticleNumber: 'AT301',
			name: 'Tank Cap',
			description:
				'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
			grammar: '',
			certificates: '',
			materials: '100% Baumwolle',
			label: 'Gedrucktes Label',
			sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
			colors: [
				{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
				{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
				{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
				{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
			],
			variants: [
				{
					_id: '60cb4e2df03dcf24ba1a3d91',
					size: 'One Size',
					color: 'White',
					hexColor: 'FFFFFF',
					articleNumber: '1000019860',
				},
				{
					_id: '60cb4e2df03dcf24ba1a3d92',
					size: 'One Size',
					color: 'Olive',
					hexColor: '535435',
					articleNumber: '1000019859',
				},
				{
					_id: '60cb4e2df03dcf24ba1a3d93',
					size: 'One Size',
					color: 'Dark Grey',
					hexColor: '1D252D',
					articleNumber: '1000279872',
				},
				{
					_id: '60cb4e2df03dcf24ba1a3d94',
					size: 'One Size',
					color: 'Khaki',
					hexColor: 'C4BBA3',
					articleNumber: '1000019857',
				},
			],
			'purchasingPrice<10': 2,
			'purchasingPrice>10': 2,
			'purchasingPrice>50': 2,
			'purchasingPrice>100': 2,
			'purchasingPrice>250': 2,
			'purchasingPrice>500': 2,
			'purchasingPrice>1000': 2,
			piecesPerBox: 200,
			productionCountry: 'CN',
			hsCode: '65050030',
			weight: 0,
			mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936552339.jpeg',
			shopName: 'l-shop',
			category: 'cap',
			style: 'standard',
			productType: 'cap',
			visible: true,
			createdAt: '2021-06-16T18:28:29.588Z',
			updates: [
				{
					_id: '60ca42e4a4cacda694bef5dc',
					user: 'admin',
					type: 'update',
					data: {
						product: {
							images: [
								'AT301_Olive.jpg',
								'AT301_Black.jpg',
								'AT301_Navy.jpg',
								'AT301_Dark-Grey.jpg',
								'AT301_White.jpg',
								'AT301_Khaki.jpg',
								'AT301_Camouflage.jpg',
							],
							externalArticleNumber: 'AT301',
							name: 'Tank Cap',
							description:
								'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
							grammar: '',
							certificates: '',
							materials: '100% Baumwolle',
							label: 'Gedrucktes Label',
							sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
							colors: [
								{ value: 'Khaki', hexValue: 'C4BBA3' },
								{ value: 'White', hexValue: 'FFFFFF' },
								{ value: 'Olive', hexValue: '535435' },
								{ value: 'Dark Grey', hexValue: '1D252D' },
							],
							variants: [
								{ size: 'One Size', color: 'Khaki', hexColor: 'C4BBA3', articleNumber: '1000019857' },
								{ size: 'One Size', color: 'Dark Grey', hexColor: '1D252D', articleNumber: '1000279872' },
								{ size: 'One Size', color: 'Olive', hexColor: '535435', articleNumber: '1000019859' },
								{ size: 'One Size', color: 'White', hexColor: 'FFFFFF', articleNumber: '1000019860' },
							],
							'purchasingPrice<10': 2,
							'purchasingPrice>10': 2,
							'purchasingPrice>50': 2,
							'purchasingPrice>100': 2,
							'purchasingPrice>250': 2,
							'purchasingPrice>500': 2,
							'purchasingPrice>1000': 2,
							piecesPerBox: 200,
							productionCountry: 'CN',
							hsCode: '65050030',
							weight: 0,
							mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
							shopName: 'l-shop',
							category: 'cap',
							style: 'standard',
							productType: 'cap',
							visible: true,
							createdAt: '2021-06-16T18:28:29.588Z',
							updates: [],
							colorImages: [
								{
									colorCode: '1D252D',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
								},
								{
									colorCode: 'C4BBA3',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
								},
								{
									colorCode: 'FFFFFF',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
								},
								{
									colorCode: '535435',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								},
							],
						},
						colors: [
							{ value: 'Khaki', hexValue: 'C4BBA3' },
							{ value: 'White', hexValue: 'FFFFFF' },
							{ value: 'Olive', hexValue: '535435' },
							{ value: 'Dark Grey', hexValue: '1D252D' },
						],
						sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
						images: [
							{
								colorCode: '1D252D',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
							},
							{
								colorCode: 'C4BBA3',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
							},
							{
								colorCode: 'FFFFFF',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
							},
							{
								colorCode: '535435',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
							},
						],
					},
					updatedAt: '2021-06-16T18:28:52.437Z',
				},
				{
					_id: '60cb4dbcf03dcf24ba1a3d86',
					user: 'admin',
					type: 'update',
					data: {
						product: {
							images: [
								'AT301_Olive.jpg',
								'AT301_Khaki.jpg',
								'AT301_Black.jpg',
								'AT301_Camouflage.jpg',
								'AT301_Dark-Grey.jpg',
								'AT301_White.jpg',
								'AT301_Navy.jpg',
							],
							externalArticleNumber: 'AT301',
							name: 'Tank Cap',
							description:
								'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
							grammar: '',
							certificates: '',
							materials: '100% Baumwolle',
							label: 'Gedrucktes Label',
							sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
							colors: [
								{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
								{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
								{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
								{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
							],
							variants: [
								{ size: 'One Size', color: 'White', hexColor: 'FFFFFF', articleNumber: '1000019860' },
								{ size: 'One Size', color: 'Olive', hexColor: '535435', articleNumber: '1000019859' },
								{ size: 'One Size', color: 'Dark Grey', hexColor: '1D252D', articleNumber: '1000279872' },
								{ size: 'One Size', color: 'Khaki', hexColor: 'C4BBA3', articleNumber: '1000019857' },
							],
							'purchasingPrice<10': 2,
							'purchasingPrice>10': 2,
							'purchasingPrice>50': 2,
							'purchasingPrice>100': 2,
							'purchasingPrice>250': 2,
							'purchasingPrice>500': 2,
							'purchasingPrice>1000': 2,
							piecesPerBox: 200,
							productionCountry: 'CN',
							hsCode: '65050030',
							weight: 0,
							mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
							shopName: 'l-shop',
							category: 'cap',
							style: 'standard',
							productType: 'cap',
							visible: true,
							createdAt: '2021-06-16T18:28:29.588Z',
							updates: [
								{
									_id: '60ca42e4a4cacda694bef5dc',
									user: 'admin',
									type: 'update',
									data: {
										product: {
											images: [
												'AT301_Olive.jpg',
												'AT301_Black.jpg',
												'AT301_Navy.jpg',
												'AT301_Dark-Grey.jpg',
												'AT301_White.jpg',
												'AT301_Khaki.jpg',
												'AT301_Camouflage.jpg',
											],
											externalArticleNumber: 'AT301',
											name: 'Tank Cap',
											description:
												'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
											grammar: '',
											certificates: '',
											materials: '100% Baumwolle',
											label: 'Gedrucktes Label',
											sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
											colors: [
												{ value: 'Khaki', hexValue: 'C4BBA3' },
												{ value: 'White', hexValue: 'FFFFFF' },
												{ value: 'Olive', hexValue: '535435' },
												{ value: 'Dark Grey', hexValue: '1D252D' },
											],
											variants: [
												{
													size: 'One Size',
													color: 'Khaki',
													hexColor: 'C4BBA3',
													articleNumber: '1000019857',
												},
												{
													size: 'One Size',
													color: 'Dark Grey',
													hexColor: '1D252D',
													articleNumber: '1000279872',
												},
												{
													size: 'One Size',
													color: 'Olive',
													hexColor: '535435',
													articleNumber: '1000019859',
												},
												{
													size: 'One Size',
													color: 'White',
													hexColor: 'FFFFFF',
													articleNumber: '1000019860',
												},
											],
											'purchasingPrice<10': 2,
											'purchasingPrice>10': 2,
											'purchasingPrice>50': 2,
											'purchasingPrice>100': 2,
											'purchasingPrice>250': 2,
											'purchasingPrice>500': 2,
											'purchasingPrice>1000': 2,
											piecesPerBox: 200,
											productionCountry: 'CN',
											hsCode: '65050030',
											weight: 0,
											mainImage:
												'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
											shopName: 'l-shop',
											category: 'cap',
											style: 'standard',
											productType: 'cap',
											visible: true,
											createdAt: '2021-06-16T18:28:29.588Z',
											updates: [],
											colorImages: [
												{
													colorCode: '1D252D',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
												},
												{
													colorCode: 'C4BBA3',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
												},
												{
													colorCode: 'FFFFFF',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
												},
												{
													colorCode: '535435',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												},
											],
										},
										colors: [
											{ value: 'Khaki', hexValue: 'C4BBA3' },
											{ value: 'White', hexValue: 'FFFFFF' },
											{ value: 'Olive', hexValue: '535435' },
											{ value: 'Dark Grey', hexValue: '1D252D' },
										],
										sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
										images: [
											{
												colorCode: '1D252D',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
											},
											{
												colorCode: 'C4BBA3',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
											},
											{
												colorCode: 'FFFFFF',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
											},
											{
												colorCode: '535435',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
											},
										],
									},
									updatedAt: '2021-06-16T18:28:52.437Z',
								},
							],
							__v: 0,
							colorImages: [
								{
									colorCode: '1D252D',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
								},
								{
									colorCode: 'C4BBA3',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
								},
								{
									colorCode: 'FFFFFF',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
								},
								{
									colorCode: '535435',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
								},
							],
						},
						colors: [
							{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
							{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
							{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
							{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
						],
						sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
						images: [
							{
								colorCode: '1D252D',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
							},
							{
								colorCode: 'C4BBA3',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
							},
							{
								colorCode: 'FFFFFF',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
							},
							{
								colorCode: '535435',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
							},
						],
					},
					updatedAt: '2021-06-17T13:27:24.435Z',
				},
				{
					_id: '60cb4e2df03dcf24ba1a3d90',
					user: 'admin',
					type: 'update',
					data: {
						product: {
							images: [
								'AT301_Navy.jpg',
								'AT301_White.jpg',
								'AT301_Dark-Grey.jpg',
								'AT301_Camouflage.jpg',
								'AT301_Khaki.jpg',
								'AT301_Olive.jpg',
								'AT301_Black.jpg',
							],
							externalArticleNumber: 'AT301',
							name: 'Tank Cap',
							description:
								'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
							grammar: '',
							certificates: '',
							materials: '100% Baumwolle',
							label: 'Gedrucktes Label',
							sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
							colors: [
								{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
								{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
								{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
								{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
							],
							variants: [
								{ size: 'One Size', color: 'White', hexColor: 'FFFFFF', articleNumber: '1000019860' },
								{ size: 'One Size', color: 'Olive', hexColor: '535435', articleNumber: '1000019859' },
								{ size: 'One Size', color: 'Dark Grey', hexColor: '1D252D', articleNumber: '1000279872' },
								{ size: 'One Size', color: 'Khaki', hexColor: 'C4BBA3', articleNumber: '1000019857' },
							],
							'purchasingPrice<10': 2,
							'purchasingPrice>10': 2,
							'purchasingPrice>50': 2,
							'purchasingPrice>100': 2,
							'purchasingPrice>250': 2,
							'purchasingPrice>500': 2,
							'purchasingPrice>1000': 2,
							piecesPerBox: 200,
							productionCountry: 'CN',
							hsCode: '65050030',
							weight: 0,
							mainImage: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936552339.jpeg',
							shopName: 'l-shop',
							category: 'cap',
							style: 'standard',
							productType: 'cap',
							visible: true,
							createdAt: '2021-06-16T18:28:29.588Z',
							updates: [
								{
									_id: '60ca42e4a4cacda694bef5dc',
									user: 'admin',
									type: 'update',
									data: {
										product: {
											images: [
												'AT301_Olive.jpg',
												'AT301_Black.jpg',
												'AT301_Navy.jpg',
												'AT301_Dark-Grey.jpg',
												'AT301_White.jpg',
												'AT301_Khaki.jpg',
												'AT301_Camouflage.jpg',
											],
											externalArticleNumber: 'AT301',
											name: 'Tank Cap',
											description:
												'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
											grammar: '',
											certificates: '',
											materials: '100% Baumwolle',
											label: 'Gedrucktes Label',
											sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
											colors: [
												{ value: 'Khaki', hexValue: 'C4BBA3' },
												{ value: 'White', hexValue: 'FFFFFF' },
												{ value: 'Olive', hexValue: '535435' },
												{ value: 'Dark Grey', hexValue: '1D252D' },
											],
											variants: [
												{
													size: 'One Size',
													color: 'Khaki',
													hexColor: 'C4BBA3',
													articleNumber: '1000019857',
												},
												{
													size: 'One Size',
													color: 'Dark Grey',
													hexColor: '1D252D',
													articleNumber: '1000279872',
												},
												{
													size: 'One Size',
													color: 'Olive',
													hexColor: '535435',
													articleNumber: '1000019859',
												},
												{
													size: 'One Size',
													color: 'White',
													hexColor: 'FFFFFF',
													articleNumber: '1000019860',
												},
											],
											'purchasingPrice<10': 2,
											'purchasingPrice>10': 2,
											'purchasingPrice>50': 2,
											'purchasingPrice>100': 2,
											'purchasingPrice>250': 2,
											'purchasingPrice>500': 2,
											'purchasingPrice>1000': 2,
											piecesPerBox: 200,
											productionCountry: 'CN',
											hsCode: '65050030',
											weight: 0,
											mainImage:
												'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
											shopName: 'l-shop',
											category: 'cap',
											style: 'standard',
											productType: 'cap',
											visible: true,
											createdAt: '2021-06-16T18:28:29.588Z',
											updates: [],
											colorImages: [
												{
													colorCode: '1D252D',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
												},
												{
													colorCode: 'C4BBA3',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
												},
												{
													colorCode: 'FFFFFF',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
												},
												{
													colorCode: '535435',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												},
											],
										},
										colors: [
											{ value: 'Khaki', hexValue: 'C4BBA3' },
											{ value: 'White', hexValue: 'FFFFFF' },
											{ value: 'Olive', hexValue: '535435' },
											{ value: 'Dark Grey', hexValue: '1D252D' },
										],
										sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
										images: [
											{
												colorCode: '1D252D',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
											},
											{
												colorCode: 'C4BBA3',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
											},
											{
												colorCode: 'FFFFFF',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
											},
											{
												colorCode: '535435',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
											},
										],
									},
									updatedAt: '2021-06-16T18:28:52.437Z',
								},
								{
									_id: '60cb4dbcf03dcf24ba1a3d86',
									user: 'admin',
									type: 'update',
									data: {
										product: {
											images: [
												'AT301_Olive.jpg',
												'AT301_Khaki.jpg',
												'AT301_Black.jpg',
												'AT301_Camouflage.jpg',
												'AT301_Dark-Grey.jpg',
												'AT301_White.jpg',
												'AT301_Navy.jpg',
											],
											externalArticleNumber: 'AT301',
											name: 'Tank Cap',
											description:
												'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
											grammar: '',
											certificates: '',
											materials: '100% Baumwolle',
											label: 'Gedrucktes Label',
											sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
											colors: [
												{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
												{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
												{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
												{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
											],
											variants: [
												{
													size: 'One Size',
													color: 'White',
													hexColor: 'FFFFFF',
													articleNumber: '1000019860',
												},
												{
													size: 'One Size',
													color: 'Olive',
													hexColor: '535435',
													articleNumber: '1000019859',
												},
												{
													size: 'One Size',
													color: 'Dark Grey',
													hexColor: '1D252D',
													articleNumber: '1000279872',
												},
												{
													size: 'One Size',
													color: 'Khaki',
													hexColor: 'C4BBA3',
													articleNumber: '1000019857',
												},
											],
											'purchasingPrice<10': 2,
											'purchasingPrice>10': 2,
											'purchasingPrice>50': 2,
											'purchasingPrice>100': 2,
											'purchasingPrice>250': 2,
											'purchasingPrice>500': 2,
											'purchasingPrice>1000': 2,
											piecesPerBox: 200,
											productionCountry: 'CN',
											hsCode: '65050030',
											weight: 0,
											mainImage:
												'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
											shopName: 'l-shop',
											category: 'cap',
											style: 'standard',
											productType: 'cap',
											visible: true,
											createdAt: '2021-06-16T18:28:29.588Z',
											updates: [
												{
													_id: '60ca42e4a4cacda694bef5dc',
													user: 'admin',
													type: 'update',
													data: {
														product: {
															images: [
																'AT301_Olive.jpg',
																'AT301_Black.jpg',
																'AT301_Navy.jpg',
																'AT301_Dark-Grey.jpg',
																'AT301_White.jpg',
																'AT301_Khaki.jpg',
																'AT301_Camouflage.jpg',
															],
															externalArticleNumber: 'AT301',
															name: 'Tank Cap',
															description:
																'Militär-Stil | Gebürstete Baumwolle | Klettverschluss | Schweißband: 35% Baumwolle / 65% Polyester | Unstrukturiertes Frontpanel | Vier Ziernähte auf Mützenschirm | Zierelement am Mützenschirmrand',
															grammar: '',
															certificates: '',
															materials: '100% Baumwolle',
															label: 'Gedrucktes Label',
															sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
															colors: [
																{ value: 'Khaki', hexValue: 'C4BBA3' },
																{ value: 'White', hexValue: 'FFFFFF' },
																{ value: 'Olive', hexValue: '535435' },
																{ value: 'Dark Grey', hexValue: '1D252D' },
															],
															variants: [
																{
																	size: 'One Size',
																	color: 'Khaki',
																	hexColor: 'C4BBA3',
																	articleNumber: '1000019857',
																},
																{
																	size: 'One Size',
																	color: 'Dark Grey',
																	hexColor: '1D252D',
																	articleNumber: '1000279872',
																},
																{
																	size: 'One Size',
																	color: 'Olive',
																	hexColor: '535435',
																	articleNumber: '1000019859',
																},
																{
																	size: 'One Size',
																	color: 'White',
																	hexColor: 'FFFFFF',
																	articleNumber: '1000019860',
																},
															],
															'purchasingPrice<10': 2,
															'purchasingPrice>10': 2,
															'purchasingPrice>50': 2,
															'purchasingPrice>100': 2,
															'purchasingPrice>250': 2,
															'purchasingPrice>500': 2,
															'purchasingPrice>1000': 2,
															piecesPerBox: 200,
															productionCountry: 'CN',
															hsCode: '65050030',
															weight: 0,
															mainImage:
																'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
															shopName: 'l-shop',
															category: 'cap',
															style: 'standard',
															productType: 'cap',
															visible: true,
															createdAt: '2021-06-16T18:28:29.588Z',
															updates: [],
															colorImages: [
																{
																	colorCode: '1D252D',
																	front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
																},
																{
																	colorCode: 'C4BBA3',
																	front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
																},
																{
																	colorCode: 'FFFFFF',
																	front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
																},
																{
																	colorCode: '535435',
																	front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																	studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																},
															],
														},
														colors: [
															{ value: 'Khaki', hexValue: 'C4BBA3' },
															{ value: 'White', hexValue: 'FFFFFF' },
															{ value: 'Olive', hexValue: '535435' },
															{ value: 'Dark Grey', hexValue: '1D252D' },
														],
														sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
														images: [
															{
																colorCode: '1D252D',
																front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868074518.jpeg',
															},
															{
																colorCode: 'C4BBA3',
																front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868129255.jpeg',
															},
															{
																colorCode: 'FFFFFF',
																front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
															},
															{
																colorCode: '535435',
																front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
																studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
															},
														],
													},
													updatedAt: '2021-06-16T18:28:52.437Z',
												},
											],
											__v: 0,
											colorImages: [
												{
													colorCode: '1D252D',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
												},
												{
													colorCode: 'C4BBA3',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
												},
												{
													colorCode: 'FFFFFF',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
												},
												{
													colorCode: '535435',
													front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
													studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
												},
											],
										},
										colors: [
											{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
											{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
											{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
											{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
										],
										sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
										images: [
											{
												colorCode: '1D252D',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
											},
											{
												colorCode: 'C4BBA3',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
											},
											{
												colorCode: 'FFFFFF',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
											},
											{
												colorCode: '535435',
												front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
												studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
											},
										],
									},
									updatedAt: '2021-06-17T13:27:24.435Z',
								},
							],
							__v: 0,
							colorImages: [
								{
									colorCode: '1D252D',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936552339.jpeg',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
								},
								{
									colorCode: 'C4BBA3',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936532775.jpeg',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
								},
								{
									colorCode: 'FFFFFF',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936538830.jpeg',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
								},
								{
									colorCode: '535435',
									front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936545726.jpeg',
									back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
									studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
								},
							],
						},
						colors: [
							{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
							{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
							{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
							{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
						],
						sizes: [{ _id: '60ca42cda4cacda694bef5d3', value: 'One Size' }],
						images: [
							{
								colorCode: '1D252D',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936552339.jpeg',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936431330.jpeg',
							},
							{
								colorCode: 'C4BBA3',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936532775.jpeg',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936341273.jpeg',
							},
							{
								colorCode: 'FFFFFF',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936538830.jpeg',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/BLAAAA_NEW_IMAGE_1623868062263.jpeg',
							},
							{
								colorCode: '535435',
								front: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936545726.jpeg',
								back: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								detail: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								side: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
								studio: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/Tank-Cap_1623936438434.jpeg',
							},
						],
					},
					updatedAt: '2021-06-17T13:29:17.878Z',
				},
			],
			__v: 0,
		},
		internalId: '60ca42cda4cacda694bef5d2',
		externalId: 'AT301',
		shop: 'l-shop',
		name: 'Tank Cap',
		type: 'cap',
		colors: [
			{ _id: '60ca42e4a4cacda694bef5e1', value: 'Khaki', hexValue: 'C4BBA3' },
			{ _id: '60ca42e4a4cacda694bef5e2', value: 'White', hexValue: 'FFFFFF' },
			{ _id: '60ca42e4a4cacda694bef5e3', value: 'Olive', hexValue: '535435' },
			{ _id: '60ca42e4a4cacda694bef5e4', value: 'Dark Grey', hexValue: '1D252D' },
		],
		variants: {
			0: {
				hexColor: '',
				color: 'Olive',
				approved: false,
				finishes: { 0: { type: 'single', colors: [{ hexColor: '#000000' }] } },
				sizes: [{ size: 'One Size', amount: 30 }],
				extractedHexColor: '#535435',
			},
		},
		selectedSizes: ['One Size'],
		finishes: {
			0: {
				technique: 'print',
				techniqueType: null,
				fileName: 'dummy_design_1_1237892374925',
				editId: null,
				size: { width: 6, height: 0 },
				sizeMeasure: 'width',
				side: 'front',
				position: 'front-center',
				coords: {},
				imageWidth: 0,
				imageHeight: 0,
				idx: '0',
			},
		},
		addons: [],
	},
	shirt1: {
		editId: '46211873-c30c-4919-bc4a-c5a4a1c0da44',
		ref: {
			_id: '5fc0e9d90711927cc5923df0',
			images: ['https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg'],
			colorImages: [
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C011.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C011.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C011.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C011.jpg',
					colorCode: 'C011',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C032.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C032.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C032.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C032.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C032.jpg',
					colorCode: 'C032',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C051.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C051.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C051.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C051.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C051.jpg',
					colorCode: 'C051',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C050.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C050.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C050.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C050.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C050.jpg',
					colorCode: 'C050',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C688.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C688.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C688.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C688.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C688.jpg',
					colorCode: 'C688',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C682.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C682.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C682.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C682.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C682.jpg',
					colorCode: 'C682',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C016.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C016.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C016.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C016.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C016.jpg',
					colorCode: 'C016',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C695.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C695.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C695.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C695.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C695.jpg',
					colorCode: 'C695',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C589.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C589.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C589.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C589.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C589.jpg',
					colorCode: 'C589',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C049.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C049.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C049.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C049.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C049.jpg',
					colorCode: 'C049',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C030.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C030.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C030.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C030.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C030.jpg',
					colorCode: 'C030',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C664.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C664.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C664.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C664.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C664.jpg',
					colorCode: 'C664',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C687.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C687.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C687.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C687.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C687.jpg',
					colorCode: 'C687',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C052.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C052.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C052.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C052.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C052.jpg',
					colorCode: 'C052',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C657.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C657.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C657.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C657.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C657.jpg',
					colorCode: 'C657',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C024.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C024.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C024.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C024.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C024.jpg',
					colorCode: 'C024',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C670.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C670.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C670.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C670.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C670.jpg',
					colorCode: 'C670',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C588.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C588.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C588.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C588.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C588.jpg',
					colorCode: 'C588',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C652.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C652.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C652.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C652.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C652.jpg',
					colorCode: 'C652',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C715.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C715.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C715.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C715.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C715.jpg',
					colorCode: 'C715',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C700.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C700.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C700.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C700.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C700.jpg',
					colorCode: 'C700',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C034.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C034.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C034.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C034.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C034.jpg',
					colorCode: 'C034',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C587.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C587.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C587.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C587.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C587.jpg',
					colorCode: 'C587',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C053.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C053.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C053.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C053.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C053.jpg',
					colorCode: 'C053',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C653.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C653.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C653.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C653.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C653.jpg',
					colorCode: 'C653',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C015.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C015.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C015.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C015.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C015.jpg',
					colorCode: 'C015',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C585.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C585.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C585.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C585.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C585.jpg',
					colorCode: 'C585',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C725.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C725.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C725.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C725.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C725.jpg',
					colorCode: 'C725',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C702.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C702.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C702.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C702.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C702.jpg',
					colorCode: 'C702',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C710.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C710.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C710.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C710.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C710.jpg',
					colorCode: 'C710',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C656.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C656.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C656.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C656.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C656.jpg',
					colorCode: 'C656',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C724.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C724.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C724.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C724.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C724.jpg',
					colorCode: 'C724',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C047.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C047.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C047.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C047.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C047.jpg',
					colorCode: 'C047',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C659.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C659.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C659.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C659.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C659.jpg',
					colorCode: 'C659',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C008.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C008.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C008.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C008.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C008.jpg',
					colorCode: 'C008',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C036.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C036.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C036.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C036.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C036.jpg',
					colorCode: 'C036',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C686.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C686.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C686.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C686.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C686.jpg',
					colorCode: 'C686',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C014.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C014.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C014.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C014.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C014.jpg',
					colorCode: 'C014',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C012.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C012.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C012.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C012.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C012.jpg',
					colorCode: 'C012',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C048.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C048.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C048.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C048.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C048.jpg',
					colorCode: 'C048',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C046.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C046.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C046.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C046.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C046.jpg',
					colorCode: 'C046',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C041.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C041.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C041.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C041.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C041.jpg',
					colorCode: 'C041',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C010.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C010.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C010.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C010.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C010.jpg',
					colorCode: 'C010',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C592.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C592.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C592.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C592.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C592.jpg',
					colorCode: 'C592',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C584.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C584.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C584.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C584.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C584.jpg',
					colorCode: 'C584',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C028.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C028.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C028.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C028.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C028.jpg',
					colorCode: 'C028',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C054.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C054.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C054.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C054.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C054.jpg',
					colorCode: 'C054',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C018.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C018.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C018.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C018.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C018.jpg',
					colorCode: 'C018',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C693.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C693.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C693.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C693.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C693.jpg',
					colorCode: 'C693',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C680.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C680.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C680.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C680.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C680.jpg',
					colorCode: 'C680',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C671.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C671.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C671.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C671.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C671.jpg',
					colorCode: 'C671',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C251.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C251.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C251.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C251.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C251.jpg',
					colorCode: 'C251',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C650.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C650.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C650.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C650.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C650.jpg',
					colorCode: 'C650',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C673.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C673.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C673.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C673.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C673.jpg',
					colorCode: 'C673',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C697.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C697.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C697.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C697.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C697.jpg',
					colorCode: 'C697',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C031.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C031.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C031.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C031.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C031.jpg',
					colorCode: 'C031',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C026.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C026.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C026.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C026.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C026.jpg',
					colorCode: 'C026',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C678.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C678.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C678.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C678.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C678.jpg',
					colorCode: 'C678',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C027.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C027.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C027.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C027.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C027.jpg',
					colorCode: 'C027',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C033.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C033.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C033.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C033.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C033.jpg',
					colorCode: 'C033',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C035.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C035.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C035.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C035.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C035.jpg',
					colorCode: 'C035',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C037.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C037.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C037.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C037.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C037.jpg',
					colorCode: 'C037',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C699.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C699.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C699.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C699.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C699.jpg',
					colorCode: 'C699',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C660.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C660.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C660.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C660.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C660.jpg',
					colorCode: 'C660',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C040.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C040.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C040.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C040.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C040.jpg',
					colorCode: 'C040',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C662.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C662.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C662.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C662.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C662.jpg',
					colorCode: 'C662',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C594.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C594.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C594.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C594.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C594.jpg',
					colorCode: 'C594',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C025.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C025.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C025.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C025.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C025.jpg',
					colorCode: 'C025',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C022.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU755_C022.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU755_C022.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU755_C022.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU755_C022.jpg',
					colorCode: 'C022',
				},
			],
			externalArticleNumber: 'STTU755',
			shopName: 'stanley-stella',
			name: 'Creator',
			description:
				'Eingesetzte Ärmel\n1x1 Rippstrick am Halsausschnitt\nAbgesetztes Nackenband\nBreite Doppelabsteppung an Ärmelenden und unterem Saum',
			grammar: '180',
			certificates: 'Global Organic Textile Standard | Peta - Approved Vegan |  |  |  | OEKO-TEX Standard 100',
			style: 'standard',
			materials: '100% gekämmte ringgesponnene Bio-Baumwolle',
			label: '',
			category: 'shirt',
			sizes: [
				{ _id: '5fc0e9d90711927cc5923df1', value: 'XXS' },
				{ _id: '5fc0e9d90711927cc5923df2', value: 'XS' },
				{ _id: '5fc0e9d90711927cc5923df3', value: 'S', amount: 4 },
				{ _id: '5fc0e9d90711927cc5923df4', value: 'M', amount: 10 },
				{ _id: '5fc0e9d90711927cc5923df5', value: 'L', amount: 10 },
				{ _id: '5fc0e9d90711927cc5923df6', value: 'XL', amount: 5 },
				{ _id: '5fc0e9d90711927cc5923df7', value: 'XXL', amount: 1 },
				{ _id: '5fc0e9d90711927cc5923df8', value: '3XL' },
				{ _id: '5fc0e9d90711927cc5923df9', value: '4XL' },
				{ _id: '5fc0e9d90711927cc5923dfa', value: '5XL' },
			],
			colors: [
				{ _id: '5fc0e9d90711927cc5923dfb', value: 'Bright Red', hexValue: 'C011' },
				{ _id: '5fc0e9d90711927cc5923dfc', value: 'Tangerine', hexValue: 'C032' },
				{ _id: '5fc0e9d90711927cc5923dfd', value: 'Bright Orange', hexValue: 'C013' },
				{ _id: '5fc0e9d90711927cc5923dfe', value: 'Melon Code', hexValue: 'C051' },
				{ _id: '5fc0e9d90711927cc5923dff', value: 'Rose Clay', hexValue: 'C050' },
				{ _id: '5fc0e9d90711927cc5923e00', value: 'Heather Neppy Pink', hexValue: 'C688' },
				{ _id: '5fc0e9d90711927cc5923e01', value: 'Cotton Pink', hexValue: 'C005' },
				{ _id: '5fc0e9d90711927cc5923e02', value: 'Cream Heather Pink', hexValue: 'C682' },
				{ _id: '5fc0e9d90711927cc5923e03', value: 'Candy Pink', hexValue: 'C016' },
				{ _id: '5fc0e9d90711927cc5923e04', value: 'Ecru Neppy Mandarine', hexValue: 'C695' },
				{ _id: '5fc0e9d90711927cc5923e05', value: 'Black Heather Cranberry', hexValue: 'C589' },
				{ _id: '5fc0e9d90711927cc5923e06', value: 'Mauve', hexValue: 'C049' },
				{ _id: '5fc0e9d90711927cc5923e07', value: 'Lavender Dawn', hexValue: 'C030' },
				{ _id: '5fc0e9d90711927cc5923e08', value: 'Plum', hexValue: 'C241' },
				{ _id: '5fc0e9d90711927cc5923e09', value: 'Heather Grape Red', hexValue: 'C664' },
				{ _id: '5fc0e9d90711927cc5923e0a', value: 'Burgundy', hexValue: 'C244' },
				{ _id: '5fc0e9d90711927cc5923e0b', value: 'Heather Neppy Burgundy', hexValue: 'C687' },
				{ _id: '5fc0e9d90711927cc5923e0c', value: 'Red', hexValue: 'C004' },
				{ _id: '5fc0e9d90711927cc5923e0d', value: 'Carmine Red', hexValue: 'C052' },
				{ _id: '5fc0e9d90711927cc5923e0e', value: 'Mid Heather Red', hexValue: 'C657' },
				{ _id: '5fc0e9d90711927cc5923e0f', value: 'Pink Punch', hexValue: 'C024' },
				{ _id: '5fc0e9d90711927cc5923e10', value: 'French Navy', hexValue: 'C727' },
				{ _id: '5fc0e9d90711927cc5923e11', value: 'Dark Heather Denim', hexValue: 'C670' },
				{ _id: '5fc0e9d90711927cc5923e12', value: 'Black Heather Blue', hexValue: 'C588' },
				{ _id: '5fc0e9d90711927cc5923e13', value: 'Dark Heather Indigo', hexValue: 'C658' },
				{ _id: '5fc0e9d90711927cc5923e14', value: 'Dark Heather Blue', hexValue: 'C652' },
				{ _id: '5fc0e9d90711927cc5923e15', value: 'India Ink Grey', hexValue: 'C715' },
				{ _id: '5fc0e9d90711927cc5923e16', value: 'Heather Snow Mid Blue', hexValue: 'C700' },
				{ _id: '5fc0e9d90711927cc5923e17', value: 'Majorelle Blue', hexValue: 'C034' },
				{ _id: '5fc0e9d90711927cc5923e18', value: 'Mid Heather Royal Blue', hexValue: 'C587' },
				{ _id: '5fc0e9d90711927cc5923e19', value: 'Royal Blue', hexValue: 'C230' },
				{ _id: '5fc0e9d90711927cc5923e1a', value: 'Azur', hexValue: 'C231' },
				{ _id: '5fc0e9d90711927cc5923e1b', value: 'Bright Blue', hexValue: 'C053' },
				{ _id: '5fc0e9d90711927cc5923e1c', value: 'Mid Heather Blue', hexValue: 'C653' },
				{ _id: '5fc0e9d90711927cc5923e1d', value: 'Sky blue', hexValue: 'C232' },
				{ _id: '5fc0e9d90711927cc5923e1e', value: 'Baby Blue', hexValue: 'C015' },
				{ _id: '5fc0e9d90711927cc5923e1f', value: 'Heather Ice Blue', hexValue: 'C585' },
				{ _id: '5fc0e9d90711927cc5923e20', value: 'Citadel Blue', hexValue: 'C725' },
				{ _id: '5fc0e9d90711927cc5923e21', value: 'Stargazer', hexValue: 'C702' },
				{ _id: '5fc0e9d90711927cc5923e22', value: 'Ocean Depth', hexValue: 'C710' },
				{ _id: '5fc0e9d90711927cc5923e23', value: 'Mid Heather Green', hexValue: 'C656' },
				{ _id: '5fc0e9d90711927cc5923e24', value: 'Caribbean Blue', hexValue: 'C724' },
				{ _id: '5fc0e9d90711927cc5923e25', value: 'Sage', hexValue: 'C047' },
				{ _id: '5fc0e9d90711927cc5923e26', value: 'Mid Heather Khaki', hexValue: 'C659' },
				{ _id: '5fc0e9d90711927cc5923e27', value: 'Khaki', hexValue: 'C223' },
				{ _id: '5fc0e9d90711927cc5923e28', value: 'British Khaki', hexValue: 'C008' },
				{ _id: '5fc0e9d90711927cc5923e29', value: 'Bottle Green', hexValue: 'C224' },
				{ _id: '5fc0e9d90711927cc5923e2a', value: 'Glazed Green', hexValue: 'C036' },
				{ _id: '5fc0e9d90711927cc5923e2b', value: 'Heather Snow Glazed Green', hexValue: 'C686' },
				{ _id: '5fc0e9d90711927cc5923e2c', value: 'Varsity Green', hexValue: 'C029' },
				{ _id: '5fc0e9d90711927cc5923e2d', value: 'Fresh Green', hexValue: 'C014' },
				{ _id: '5fc0e9d90711927cc5923e2e', value: 'Golden Yellow', hexValue: 'C012' },
				{ _id: '5fc0e9d90711927cc5923e2f', value: 'Spectra Yellow', hexValue: 'C204' },
				{ _id: '5fc0e9d90711927cc5923e30', value: 'Ochre', hexValue: 'C048' },
				{ _id: '5fc0e9d90711927cc5923e31', value: 'Caramel', hexValue: 'C046' },
				{ _id: '5fc0e9d90711927cc5923e32', value: 'Deep Chocolate', hexValue: 'C041' },
				{ _id: '5fc0e9d90711927cc5923e33', value: 'Camel', hexValue: 'C010' },
				{ _id: '5fc0e9d90711927cc5923e34', value: 'Heather Sand', hexValue: 'C592' },
				{ _id: '5fc0e9d90711927cc5923e35', value: 'Wooden Heather', hexValue: 'C584' },
				{ _id: '5fc0e9d90711927cc5923e36', value: 'Desert Dust', hexValue: 'C028' },
				{ _id: '5fc0e9d90711927cc5923e37', value: 'Natural Raw', hexValue: 'C054' },
				{ _id: '5fc0e9d90711927cc5923e38', value: 'Vintage White', hexValue: 'C504' },
				{ _id: '5fc0e9d90711927cc5923e39', value: 'Off White', hexValue: 'C018' },
				{ _id: '5fc0e9d90711927cc5923e3a', value: 'White', hexValue: 'C001' },
				{ _id: '5fc0e9d90711927cc5923e3b', value: 'Heather Ash', hexValue: 'C693' },
				{ _id: '5fc0e9d90711927cc5923e3c', value: 'Cream Heather Grey', hexValue: 'C680' },
				{ _id: '5fc0e9d90711927cc5923e3d', value: 'Slub Heather Grey', hexValue: 'C671' },
				{ _id: '5fc0e9d90711927cc5923e3e', value: 'Heather Grey', hexValue: 'C250' },
				{ _id: '5fc0e9d90711927cc5923e3f', value: 'Opal', hexValue: 'C251' },
				{ _id: '5fc0e9d90711927cc5923e40', value: 'Mid Heather Grey', hexValue: 'C650' },
				{ _id: '5fc0e9d90711927cc5923e41', value: 'Slub Heather Steel Grey', hexValue: 'C673' },
				{ _id: '5fc0e9d90711927cc5923e42', value: 'Anthracite', hexValue: 'C253' },
				{ _id: '5fc0e9d90711927cc5923e43', value: 'Dark Heather Grey', hexValue: 'C651' },
				{ _id: '5fc0e9d90711927cc5923e44', value: 'Heather Black Denim', hexValue: 'C697' },
				{ _id: '5fc0e9d90711927cc5923e45', value: 'Black', hexValue: 'C002' },
				{ _id: '5fc0e9d90711927cc5923e46', value: 'Geyser Green', hexValue: 'C037' },
				{ _id: '5fc0e9d90711927cc5923e47', value: 'Purple LED', hexValue: 'C022' },
				{ _id: '5fc0e9d90711927cc5923e48', value: 'Hay Yellow', hexValue: 'C040' },
				{ _id: '5fc0e9d90711927cc5923e49', value: 'Dark Heather Burgundy', hexValue: 'C660' },
				{ _id: '5fc0e9d90711927cc5923e4a', value: 'Marble Slub Heather Black', hexValue: 'C678' },
				{ _id: '5fc0e9d90711927cc5923e4b', value: 'Heather Scarab Green', hexValue: 'C662' },
				{ _id: '5fc0e9d90711927cc5923e4c', value: 'Sunset Orange', hexValue: 'C025' },
				{ _id: '5fc0e9d90711927cc5923e4d', value: 'Black Heather Orange', hexValue: 'C594' },
				{ _id: '5fc0e9d90711927cc5923e4e', value: 'Heather Neppy Lemon Grass', hexValue: 'C699' },
				{ _id: '5fc0e9d90711927cc5923e4f', value: 'Roasted Orange', hexValue: 'C031' },
				{ _id: '5fc0e9d90711927cc5923e50', value: 'Moss Green', hexValue: 'C033' },
				{ _id: '5fc0e9d90711927cc5923e51', value: 'Yellow Mist', hexValue: 'C035' },
				{ _id: '5fc0e9d90711927cc5923e52', value: 'Scale Green', hexValue: 'C026' },
				{ _id: '5fc0e9d90711927cc5923e53', value: 'Chameleon Green', hexValue: 'C027' },
			],
			variants: [
				{
					_id: '5fc0e9d90711927cc5923e54',
					size: 'XXS',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0112S',
				},
				{
					_id: '5fc0e9d90711927cc5923e55',
					size: 'XS',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C011XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e56',
					size: 'S',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0111S',
				},
				{
					_id: '5fc0e9d90711927cc5923e57',
					size: 'M',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0111M',
				},
				{
					_id: '5fc0e9d90711927cc5923e58',
					size: 'L',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0111L',
				},
				{
					_id: '5fc0e9d90711927cc5923e59',
					size: 'XL',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0111X',
				},
				{
					_id: '5fc0e9d90711927cc5923e5a',
					size: 'XXL',
					color: 'Bright Red',
					hexColor: 'C011',
					articleNumber: 'STTU755C0112X',
				},
				{
					_id: '5fc0e9d90711927cc5923e5b',
					size: 'XS',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C032XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e5c',
					size: 'S',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C0321S',
				},
				{
					_id: '5fc0e9d90711927cc5923e5d',
					size: 'M',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C0321M',
				},
				{
					_id: '5fc0e9d90711927cc5923e5e',
					size: 'L',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C0321L',
				},
				{
					_id: '5fc0e9d90711927cc5923e5f',
					size: 'XL',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C0321X',
				},
				{
					_id: '5fc0e9d90711927cc5923e60',
					size: 'XXL',
					color: 'Tangerine',
					hexColor: 'C032',
					articleNumber: 'STTU755C0322X',
				},
				{
					_id: '5fc0e9d90711927cc5923e61',
					size: 'XS',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C013XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e62',
					size: 'S',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C0131S',
				},
				{
					_id: '5fc0e9d90711927cc5923e63',
					size: 'M',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C0131M',
				},
				{
					_id: '5fc0e9d90711927cc5923e64',
					size: 'L',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C0131L',
				},
				{
					_id: '5fc0e9d90711927cc5923e65',
					size: 'XL',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C0131X',
				},
				{
					_id: '5fc0e9d90711927cc5923e66',
					size: 'XXL',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU755C0132X',
				},
				{
					_id: '5fc0e9d90711927cc5923e67',
					size: 'XS',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C051XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e68',
					size: 'S',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C0511S',
				},
				{
					_id: '5fc0e9d90711927cc5923e69',
					size: 'M',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C0511M',
				},
				{
					_id: '5fc0e9d90711927cc5923e6a',
					size: 'L',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C0511L',
				},
				{
					_id: '5fc0e9d90711927cc5923e6b',
					size: 'XL',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C0511X',
				},
				{
					_id: '5fc0e9d90711927cc5923e6c',
					size: 'XXL',
					color: 'Melon Code',
					hexColor: 'C051',
					articleNumber: 'STTU755C0512X',
				},
				{
					_id: '5fc0e9d90711927cc5923e6d',
					size: 'XS',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C050XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e6e',
					size: 'S',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C0501S',
				},
				{
					_id: '5fc0e9d90711927cc5923e6f',
					size: 'M',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C0501M',
				},
				{
					_id: '5fc0e9d90711927cc5923e70',
					size: 'L',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C0501L',
				},
				{
					_id: '5fc0e9d90711927cc5923e71',
					size: 'XL',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C0501X',
				},
				{
					_id: '5fc0e9d90711927cc5923e72',
					size: 'XXL',
					color: 'Rose Clay',
					hexColor: 'C050',
					articleNumber: 'STTU755C0502X',
				},
				{
					_id: '5fc0e9d90711927cc5923e73',
					size: 'XS',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C688XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e74',
					size: 'S',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C6881S',
				},
				{
					_id: '5fc0e9d90711927cc5923e75',
					size: 'M',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C6881M',
				},
				{
					_id: '5fc0e9d90711927cc5923e76',
					size: 'L',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C6881L',
				},
				{
					_id: '5fc0e9d90711927cc5923e77',
					size: 'XL',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C6881X',
				},
				{
					_id: '5fc0e9d90711927cc5923e78',
					size: 'XXL',
					color: 'Heather Neppy Pink',
					hexColor: 'C688',
					articleNumber: 'STTU755C6882X',
				},
				{
					_id: '5fc0e9d90711927cc5923e79',
					size: 'XXS',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0052S',
				},
				{
					_id: '5fc0e9d90711927cc5923e7a',
					size: 'XS',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C005XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e7b',
					size: 'S',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0051S',
				},
				{
					_id: '5fc0e9d90711927cc5923e7c',
					size: 'M',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0051M',
				},
				{
					_id: '5fc0e9d90711927cc5923e7d',
					size: 'L',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0051L',
				},
				{
					_id: '5fc0e9d90711927cc5923e7e',
					size: 'XL',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0051X',
				},
				{
					_id: '5fc0e9d90711927cc5923e7f',
					size: 'XXL',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU755C0052X',
				},
				{
					_id: '5fc0e9d90711927cc5923e80',
					size: 'XXS',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6822S',
				},
				{
					_id: '5fc0e9d90711927cc5923e81',
					size: 'XS',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C682XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e82',
					size: 'S',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6821S',
				},
				{
					_id: '5fc0e9d90711927cc5923e83',
					size: 'M',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6821M',
				},
				{
					_id: '5fc0e9d90711927cc5923e84',
					size: 'L',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6821L',
				},
				{
					_id: '5fc0e9d90711927cc5923e85',
					size: 'XL',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6821X',
				},
				{
					_id: '5fc0e9d90711927cc5923e86',
					size: 'XXL',
					color: 'Cream Heather Pink',
					hexColor: 'C682',
					articleNumber: 'STTU755C6822X',
				},
				{
					_id: '5fc0e9d90711927cc5923e87',
					size: 'XS',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C016XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e88',
					size: 'S',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C0161S',
				},
				{
					_id: '5fc0e9d90711927cc5923e89',
					size: 'M',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C0161M',
				},
				{
					_id: '5fc0e9d90711927cc5923e8a',
					size: 'L',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C0161L',
				},
				{
					_id: '5fc0e9d90711927cc5923e8b',
					size: 'XL',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C0161X',
				},
				{
					_id: '5fc0e9d90711927cc5923e8c',
					size: 'XXL',
					color: 'Candy Pink',
					hexColor: 'C016',
					articleNumber: 'STTU755C0162X',
				},
				{
					_id: '5fc0e9d90711927cc5923e8d',
					size: 'XS',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C695XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e8e',
					size: 'S',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C6951S',
				},
				{
					_id: '5fc0e9d90711927cc5923e8f',
					size: 'M',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C6951M',
				},
				{
					_id: '5fc0e9d90711927cc5923e90',
					size: 'L',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C6951L',
				},
				{
					_id: '5fc0e9d90711927cc5923e91',
					size: 'XL',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C6951X',
				},
				{
					_id: '5fc0e9d90711927cc5923e92',
					size: 'XXL',
					color: 'Ecru Neppy Mandarine',
					hexColor: 'C695',
					articleNumber: 'STTU755C6952X',
				},
				{
					_id: '5fc0e9d90711927cc5923e93',
					size: 'XS',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C589XS',
				},
				{
					_id: '5fc0e9d90711927cc5923e94',
					size: 'S',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C5891S',
				},
				{
					_id: '5fc0e9d90711927cc5923e95',
					size: 'M',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C5891M',
				},
				{
					_id: '5fc0e9d90711927cc5923e96',
					size: 'L',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C5891L',
				},
				{
					_id: '5fc0e9d90711927cc5923e97',
					size: 'XL',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C5891X',
				},
				{
					_id: '5fc0e9d90711927cc5923e98',
					size: 'XXL',
					color: 'Black Heather Cranberry',
					hexColor: 'C589',
					articleNumber: 'STTU755C5892X',
				},
				{ _id: '5fc0e9d90711927cc5923e99', size: 'XS', color: 'Mauve', hexColor: 'C049', articleNumber: 'STTU755C049XS' },
				{ _id: '5fc0e9d90711927cc5923e9a', size: 'S', color: 'Mauve', hexColor: 'C049', articleNumber: 'STTU755C0491S' },
				{ _id: '5fc0e9d90711927cc5923e9b', size: 'M', color: 'Mauve', hexColor: 'C049', articleNumber: 'STTU755C0491M' },
				{ _id: '5fc0e9d90711927cc5923e9c', size: 'L', color: 'Mauve', hexColor: 'C049', articleNumber: 'STTU755C0491L' },
				{ _id: '5fc0e9d90711927cc5923e9d', size: 'XL', color: 'Mauve', hexColor: 'C049', articleNumber: 'STTU755C0491X' },
				{
					_id: '5fc0e9d90711927cc5923e9e',
					size: 'XXL',
					color: 'Mauve',
					hexColor: 'C049',
					articleNumber: 'STTU755C0492X',
				},
				{
					_id: '5fc0e9d90711927cc5923e9f',
					size: 'XS',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C030XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ea0',
					size: 'S',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C0301S',
				},
				{
					_id: '5fc0e9d90711927cc5923ea1',
					size: 'M',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C0301M',
				},
				{
					_id: '5fc0e9d90711927cc5923ea2',
					size: 'L',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C0301L',
				},
				{
					_id: '5fc0e9d90711927cc5923ea3',
					size: 'XL',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C0301X',
				},
				{
					_id: '5fc0e9d90711927cc5923ea4',
					size: 'XXL',
					color: 'Lavender Dawn',
					hexColor: 'C030',
					articleNumber: 'STTU755C0302X',
				},
				{ _id: '5fc0e9d90711927cc5923ea5', size: 'XS', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C241XS' },
				{ _id: '5fc0e9d90711927cc5923ea6', size: 'S', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C2411S' },
				{ _id: '5fc0e9d90711927cc5923ea7', size: 'M', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C2411M' },
				{ _id: '5fc0e9d90711927cc5923ea8', size: 'L', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C2411L' },
				{ _id: '5fc0e9d90711927cc5923ea9', size: 'XL', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C2411X' },
				{ _id: '5fc0e9d90711927cc5923eaa', size: 'XXL', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU755C2412X' },
				{
					_id: '5fc0e9d90711927cc5923eab',
					size: 'XS',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C664XS',
				},
				{
					_id: '5fc0e9d90711927cc5923eac',
					size: 'S',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C6641S',
				},
				{
					_id: '5fc0e9d90711927cc5923ead',
					size: 'M',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C6641M',
				},
				{
					_id: '5fc0e9d90711927cc5923eae',
					size: 'L',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C6641L',
				},
				{
					_id: '5fc0e9d90711927cc5923eaf',
					size: 'XL',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C6641X',
				},
				{
					_id: '5fc0e9d90711927cc5923eb0',
					size: 'XXL',
					color: 'Heather Grape Red',
					hexColor: 'C664',
					articleNumber: 'STTU755C6642X',
				},
				{
					_id: '5fc0e9d90711927cc5923eb1',
					size: 'XXS',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2442S',
				},
				{
					_id: '5fc0e9d90711927cc5923eb2',
					size: 'XS',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C244XS',
				},
				{
					_id: '5fc0e9d90711927cc5923eb3',
					size: 'S',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2441S',
				},
				{
					_id: '5fc0e9d90711927cc5923eb4',
					size: 'M',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2441M',
				},
				{
					_id: '5fc0e9d90711927cc5923eb5',
					size: 'L',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2441L',
				},
				{
					_id: '5fc0e9d90711927cc5923eb6',
					size: 'XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2441X',
				},
				{
					_id: '5fc0e9d90711927cc5923eb7',
					size: 'XXL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2442X',
				},
				{
					_id: '5fc0e9d90711927cc5923eb8',
					size: '3XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2443X',
				},
				{
					_id: '5fc0e9d90711927cc5923eb9',
					size: '4XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU755C2444X',
				},
				{
					_id: '5fc0e9d90711927cc5923eba',
					size: 'XS',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C687XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ebb',
					size: 'S',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C6871S',
				},
				{
					_id: '5fc0e9d90711927cc5923ebc',
					size: 'M',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C6871M',
				},
				{
					_id: '5fc0e9d90711927cc5923ebd',
					size: 'L',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C6871L',
				},
				{
					_id: '5fc0e9d90711927cc5923ebe',
					size: 'XL',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C6871X',
				},
				{
					_id: '5fc0e9d90711927cc5923ebf',
					size: 'XXL',
					color: 'Heather Neppy Burgundy',
					hexColor: 'C687',
					articleNumber: 'STTU755C6872X',
				},
				{ _id: '5fc0e9d90711927cc5923ec0', size: 'XXS', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0042S' },
				{ _id: '5fc0e9d90711927cc5923ec1', size: 'XS', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C004XS' },
				{ _id: '5fc0e9d90711927cc5923ec2', size: 'S', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0041S' },
				{ _id: '5fc0e9d90711927cc5923ec3', size: 'M', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0041M' },
				{ _id: '5fc0e9d90711927cc5923ec4', size: 'L', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0041L' },
				{ _id: '5fc0e9d90711927cc5923ec5', size: 'XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0041X' },
				{ _id: '5fc0e9d90711927cc5923ec6', size: 'XXL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0042X' },
				{ _id: '5fc0e9d90711927cc5923ec7', size: '3XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0043X' },
				{ _id: '5fc0e9d90711927cc5923ec8', size: '4XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU755C0044X' },
				{
					_id: '5fc0e9d90711927cc5923ec9',
					size: 'XS',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C052XS',
				},
				{
					_id: '5fc0e9d90711927cc5923eca',
					size: 'S',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C0521S',
				},
				{
					_id: '5fc0e9d90711927cc5923ecb',
					size: 'M',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C0521M',
				},
				{
					_id: '5fc0e9d90711927cc5923ecc',
					size: 'L',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C0521L',
				},
				{
					_id: '5fc0e9d90711927cc5923ecd',
					size: 'XL',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C0521X',
				},
				{
					_id: '5fc0e9d90711927cc5923ece',
					size: 'XXL',
					color: 'Carmine Red',
					hexColor: 'C052',
					articleNumber: 'STTU755C0522X',
				},
				{
					_id: '5fc0e9d90711927cc5923ecf',
					size: 'XS',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C657XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ed0',
					size: 'S',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C6571S',
				},
				{
					_id: '5fc0e9d90711927cc5923ed1',
					size: 'M',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C6571M',
				},
				{
					_id: '5fc0e9d90711927cc5923ed2',
					size: 'L',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C6571L',
				},
				{
					_id: '5fc0e9d90711927cc5923ed3',
					size: 'XL',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C6571X',
				},
				{
					_id: '5fc0e9d90711927cc5923ed4',
					size: 'XXL',
					color: 'Mid Heather Red',
					hexColor: 'C657',
					articleNumber: 'STTU755C6572X',
				},
				{
					_id: '5fc0e9d90711927cc5923ed5',
					size: 'XXS',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0242S',
				},
				{
					_id: '5fc0e9d90711927cc5923ed6',
					size: 'XS',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C024XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ed7',
					size: 'S',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0241S',
				},
				{
					_id: '5fc0e9d90711927cc5923ed8',
					size: 'M',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0241M',
				},
				{
					_id: '5fc0e9d90711927cc5923ed9',
					size: 'L',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0241L',
				},
				{
					_id: '5fc0e9d90711927cc5923eda',
					size: 'XL',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0241X',
				},
				{
					_id: '5fc0e9d90711927cc5923edb',
					size: 'XXL',
					color: 'Pink Punch',
					hexColor: 'C024',
					articleNumber: 'STTU755C0242X',
				},
				{
					_id: '5fc0e9d90711927cc5923edc',
					size: 'XXS',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7272S',
				},
				{
					_id: '5fc0e9d90711927cc5923edd',
					size: 'XS',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C727XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ede',
					size: 'S',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7271S',
				},
				{
					_id: '5fc0e9d90711927cc5923edf',
					size: 'M',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7271M',
				},
				{
					_id: '5fc0e9d90711927cc5923ee0',
					size: 'L',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7271L',
				},
				{
					_id: '5fc0e9d90711927cc5923ee1',
					size: 'XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7271X',
				},
				{
					_id: '5fc0e9d90711927cc5923ee2',
					size: 'XXL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7272X',
				},
				{
					_id: '5fc0e9d90711927cc5923ee3',
					size: '3XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7273X',
				},
				{
					_id: '5fc0e9d90711927cc5923ee4',
					size: '4XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7274X',
				},
				{
					_id: '5fc0e9d90711927cc5923ee5',
					size: '5XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU755C7275X',
				},
				{
					_id: '5fc0e9d90711927cc5923ee6',
					size: 'XXS',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6702S',
				},
				{
					_id: '5fc0e9d90711927cc5923ee7',
					size: 'XS',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C670XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ee8',
					size: 'S',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6701S',
				},
				{
					_id: '5fc0e9d90711927cc5923ee9',
					size: 'M',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6701M',
				},
				{
					_id: '5fc0e9d90711927cc5923eea',
					size: 'L',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6701L',
				},
				{
					_id: '5fc0e9d90711927cc5923eeb',
					size: 'XL',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6701X',
				},
				{
					_id: '5fc0e9d90711927cc5923eec',
					size: 'XXL',
					color: 'Dark Heather Denim',
					hexColor: 'C670',
					articleNumber: 'STTU755C6702X',
				},
				{
					_id: '5fc0e9d90711927cc5923eed',
					size: 'XXS',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5882S',
				},
				{
					_id: '5fc0e9d90711927cc5923eee',
					size: 'XS',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C588XS',
				},
				{
					_id: '5fc0e9d90711927cc5923eef',
					size: 'S',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5881S',
				},
				{
					_id: '5fc0e9d90711927cc5923ef0',
					size: 'M',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5881M',
				},
				{
					_id: '5fc0e9d90711927cc5923ef1',
					size: 'L',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5881L',
				},
				{
					_id: '5fc0e9d90711927cc5923ef2',
					size: 'XL',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5881X',
				},
				{
					_id: '5fc0e9d90711927cc5923ef3',
					size: 'XXL',
					color: 'Black Heather Blue',
					hexColor: 'C588',
					articleNumber: 'STTU755C5882X',
				},
				{
					_id: '5fc0e9d90711927cc5923ef4',
					size: 'XS',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C658XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ef5',
					size: 'S',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C6581S',
				},
				{
					_id: '5fc0e9d90711927cc5923ef6',
					size: 'M',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C6581M',
				},
				{
					_id: '5fc0e9d90711927cc5923ef7',
					size: 'L',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C6581L',
				},
				{
					_id: '5fc0e9d90711927cc5923ef8',
					size: 'XL',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C6581X',
				},
				{
					_id: '5fc0e9d90711927cc5923ef9',
					size: 'XXL',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU755C6582X',
				},
				{
					_id: '5fc0e9d90711927cc5923efa',
					size: 'XS',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C652XS',
				},
				{
					_id: '5fc0e9d90711927cc5923efb',
					size: 'S',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C6521S',
				},
				{
					_id: '5fc0e9d90711927cc5923efc',
					size: 'M',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C6521M',
				},
				{
					_id: '5fc0e9d90711927cc5923efd',
					size: 'L',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C6521L',
				},
				{
					_id: '5fc0e9d90711927cc5923efe',
					size: 'XL',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C6521X',
				},
				{
					_id: '5fc0e9d90711927cc5923eff',
					size: 'XXL',
					color: 'Dark Heather Blue',
					hexColor: 'C652',
					articleNumber: 'STTU755C6522X',
				},
				{
					_id: '5fc0e9d90711927cc5923f00',
					size: 'XS',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C715XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f01',
					size: 'S',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C7151S',
				},
				{
					_id: '5fc0e9d90711927cc5923f02',
					size: 'M',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C7151M',
				},
				{
					_id: '5fc0e9d90711927cc5923f03',
					size: 'L',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C7151L',
				},
				{
					_id: '5fc0e9d90711927cc5923f04',
					size: 'XL',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C7151X',
				},
				{
					_id: '5fc0e9d90711927cc5923f05',
					size: 'XXL',
					color: 'India Ink Grey',
					hexColor: 'C715',
					articleNumber: 'STTU755C7152X',
				},
				{
					_id: '5fc0e9d90711927cc5923f06',
					size: 'XXS',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7002S',
				},
				{
					_id: '5fc0e9d90711927cc5923f07',
					size: 'XS',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C700XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f08',
					size: 'S',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7001S',
				},
				{
					_id: '5fc0e9d90711927cc5923f09',
					size: 'M',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7001M',
				},
				{
					_id: '5fc0e9d90711927cc5923f0a',
					size: 'L',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7001L',
				},
				{
					_id: '5fc0e9d90711927cc5923f0b',
					size: 'XL',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7001X',
				},
				{
					_id: '5fc0e9d90711927cc5923f0c',
					size: 'XXL',
					color: 'Heather Snow Mid Blue',
					hexColor: 'C700',
					articleNumber: 'STTU755C7002X',
				},
				{
					_id: '5fc0e9d90711927cc5923f0d',
					size: 'XXS',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0342S',
				},
				{
					_id: '5fc0e9d90711927cc5923f0e',
					size: 'XS',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C034XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f0f',
					size: 'S',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0341S',
				},
				{
					_id: '5fc0e9d90711927cc5923f10',
					size: 'M',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0341M',
				},
				{
					_id: '5fc0e9d90711927cc5923f11',
					size: 'L',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0341L',
				},
				{
					_id: '5fc0e9d90711927cc5923f12',
					size: 'XL',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0341X',
				},
				{
					_id: '5fc0e9d90711927cc5923f13',
					size: 'XXL',
					color: 'Majorelle Blue',
					hexColor: 'C034',
					articleNumber: 'STTU755C0342X',
				},
				{
					_id: '5fc0e9d90711927cc5923f14',
					size: 'XS',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C587XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f15',
					size: 'S',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C5871S',
				},
				{
					_id: '5fc0e9d90711927cc5923f16',
					size: 'M',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C5871M',
				},
				{
					_id: '5fc0e9d90711927cc5923f17',
					size: 'L',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C5871L',
				},
				{
					_id: '5fc0e9d90711927cc5923f18',
					size: 'XL',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C5871X',
				},
				{
					_id: '5fc0e9d90711927cc5923f19',
					size: 'XXL',
					color: 'Mid Heather Royal Blue',
					hexColor: 'C587',
					articleNumber: 'STTU755C5872X',
				},
				{
					_id: '5fc0e9d90711927cc5923f1a',
					size: 'XXS',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2302S',
				},
				{
					_id: '5fc0e9d90711927cc5923f1b',
					size: 'XS',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C230XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f1c',
					size: 'S',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2301S',
				},
				{
					_id: '5fc0e9d90711927cc5923f1d',
					size: 'M',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2301M',
				},
				{
					_id: '5fc0e9d90711927cc5923f1e',
					size: 'L',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2301L',
				},
				{
					_id: '5fc0e9d90711927cc5923f1f',
					size: 'XL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2301X',
				},
				{
					_id: '5fc0e9d90711927cc5923f20',
					size: 'XXL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2302X',
				},
				{
					_id: '5fc0e9d90711927cc5923f21',
					size: '3XL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU755C2303X',
				},
				{ _id: '5fc0e9d90711927cc5923f22', size: 'XS', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C231XS' },
				{ _id: '5fc0e9d90711927cc5923f23', size: 'S', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C2311S' },
				{ _id: '5fc0e9d90711927cc5923f24', size: 'M', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C2311M' },
				{ _id: '5fc0e9d90711927cc5923f25', size: 'L', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C2311L' },
				{ _id: '5fc0e9d90711927cc5923f26', size: 'XL', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C2311X' },
				{ _id: '5fc0e9d90711927cc5923f27', size: 'XXL', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU755C2312X' },
				{
					_id: '5fc0e9d90711927cc5923f28',
					size: 'XS',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C053XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f29',
					size: 'S',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C0531S',
				},
				{
					_id: '5fc0e9d90711927cc5923f2a',
					size: 'M',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C0531M',
				},
				{
					_id: '5fc0e9d90711927cc5923f2b',
					size: 'L',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C0531L',
				},
				{
					_id: '5fc0e9d90711927cc5923f2c',
					size: 'XL',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C0531X',
				},
				{
					_id: '5fc0e9d90711927cc5923f2d',
					size: 'XXL',
					color: 'Bright Blue',
					hexColor: 'C053',
					articleNumber: 'STTU755C0532X',
				},
				{
					_id: '5fc0e9d90711927cc5923f2e',
					size: 'XXS',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6532S',
				},
				{
					_id: '5fc0e9d90711927cc5923f2f',
					size: 'XS',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C653XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f30',
					size: 'S',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6531S',
				},
				{
					_id: '5fc0e9d90711927cc5923f31',
					size: 'M',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6531M',
				},
				{
					_id: '5fc0e9d90711927cc5923f32',
					size: 'L',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6531L',
				},
				{
					_id: '5fc0e9d90711927cc5923f33',
					size: 'XL',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6531X',
				},
				{
					_id: '5fc0e9d90711927cc5923f34',
					size: 'XXL',
					color: 'Mid Heather Blue',
					hexColor: 'C653',
					articleNumber: 'STTU755C6532X',
				},
				{
					_id: '5fc0e9d90711927cc5923f35',
					size: 'XS',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C232XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f36',
					size: 'S',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C2321S',
				},
				{
					_id: '5fc0e9d90711927cc5923f37',
					size: 'M',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C2321M',
				},
				{
					_id: '5fc0e9d90711927cc5923f38',
					size: 'L',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C2321L',
				},
				{
					_id: '5fc0e9d90711927cc5923f39',
					size: 'XL',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C2321X',
				},
				{
					_id: '5fc0e9d90711927cc5923f3a',
					size: 'XXL',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU755C2322X',
				},
				{
					_id: '5fc0e9d90711927cc5923f3b',
					size: 'XS',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C015XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f3c',
					size: 'S',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C0151S',
				},
				{
					_id: '5fc0e9d90711927cc5923f3d',
					size: 'M',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C0151M',
				},
				{
					_id: '5fc0e9d90711927cc5923f3e',
					size: 'L',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C0151L',
				},
				{
					_id: '5fc0e9d90711927cc5923f3f',
					size: 'XL',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C0151X',
				},
				{
					_id: '5fc0e9d90711927cc5923f40',
					size: 'XXL',
					color: 'Baby Blue',
					hexColor: 'C015',
					articleNumber: 'STTU755C0152X',
				},
				{
					_id: '5fc0e9d90711927cc5923f41',
					size: 'XS',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C585XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f42',
					size: 'S',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C5851S',
				},
				{
					_id: '5fc0e9d90711927cc5923f43',
					size: 'M',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C5851M',
				},
				{
					_id: '5fc0e9d90711927cc5923f44',
					size: 'L',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C5851L',
				},
				{
					_id: '5fc0e9d90711927cc5923f45',
					size: 'XL',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C5851X',
				},
				{
					_id: '5fc0e9d90711927cc5923f46',
					size: 'XXL',
					color: 'Heather Ice Blue',
					hexColor: 'C585',
					articleNumber: 'STTU755C5852X',
				},
				{
					_id: '5fc0e9d90711927cc5923f47',
					size: 'XS',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C725XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f48',
					size: 'S',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C7251S',
				},
				{
					_id: '5fc0e9d90711927cc5923f49',
					size: 'M',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C7251M',
				},
				{
					_id: '5fc0e9d90711927cc5923f4a',
					size: 'L',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C7251L',
				},
				{
					_id: '5fc0e9d90711927cc5923f4b',
					size: 'XL',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C7251X',
				},
				{
					_id: '5fc0e9d90711927cc5923f4c',
					size: 'XXL',
					color: 'Citadel Blue',
					hexColor: 'C725',
					articleNumber: 'STTU755C7252X',
				},
				{
					_id: '5fc0e9d90711927cc5923f4d',
					size: 'XXS',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7022S',
				},
				{
					_id: '5fc0e9d90711927cc5923f4e',
					size: 'XS',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C702XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f4f',
					size: 'S',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7021S',
				},
				{
					_id: '5fc0e9d90711927cc5923f50',
					size: 'M',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7021M',
				},
				{
					_id: '5fc0e9d90711927cc5923f51',
					size: 'L',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7021L',
				},
				{
					_id: '5fc0e9d90711927cc5923f52',
					size: 'XL',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7021X',
				},
				{
					_id: '5fc0e9d90711927cc5923f53',
					size: 'XXL',
					color: 'Stargazer',
					hexColor: 'C702',
					articleNumber: 'STTU755C7022X',
				},
				{
					_id: '5fc0e9d90711927cc5923f54',
					size: 'XS',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C710XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f55',
					size: 'S',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C7101S',
				},
				{
					_id: '5fc0e9d90711927cc5923f56',
					size: 'M',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C7101M',
				},
				{
					_id: '5fc0e9d90711927cc5923f57',
					size: 'L',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C7101L',
				},
				{
					_id: '5fc0e9d90711927cc5923f58',
					size: 'XL',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C7101X',
				},
				{
					_id: '5fc0e9d90711927cc5923f59',
					size: 'XXL',
					color: 'Ocean Depth',
					hexColor: 'C710',
					articleNumber: 'STTU755C7102X',
				},
				{
					_id: '5fc0e9d90711927cc5923f5a',
					size: 'XXS',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6562S',
				},
				{
					_id: '5fc0e9d90711927cc5923f5b',
					size: 'XS',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C656XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f5c',
					size: 'S',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6561S',
				},
				{
					_id: '5fc0e9d90711927cc5923f5d',
					size: 'M',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6561M',
				},
				{
					_id: '5fc0e9d90711927cc5923f5e',
					size: 'L',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6561L',
				},
				{
					_id: '5fc0e9d90711927cc5923f5f',
					size: 'XL',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6561X',
				},
				{
					_id: '5fc0e9d90711927cc5923f60',
					size: 'XXL',
					color: 'Mid Heather Green',
					hexColor: 'C656',
					articleNumber: 'STTU755C6562X',
				},
				{
					_id: '5fc0e9d90711927cc5923f61',
					size: 'XS',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C724XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f62',
					size: 'S',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C7241S',
				},
				{
					_id: '5fc0e9d90711927cc5923f63',
					size: 'M',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C7241M',
				},
				{
					_id: '5fc0e9d90711927cc5923f64',
					size: 'L',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C7241L',
				},
				{
					_id: '5fc0e9d90711927cc5923f65',
					size: 'XL',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C7241X',
				},
				{
					_id: '5fc0e9d90711927cc5923f66',
					size: 'XXL',
					color: 'Caribbean Blue',
					hexColor: 'C724',
					articleNumber: 'STTU755C7242X',
				},
				{ _id: '5fc0e9d90711927cc5923f67', size: 'XS', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C047XS' },
				{ _id: '5fc0e9d90711927cc5923f68', size: 'S', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C0471S' },
				{ _id: '5fc0e9d90711927cc5923f69', size: 'M', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C0471M' },
				{ _id: '5fc0e9d90711927cc5923f6a', size: 'L', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C0471L' },
				{ _id: '5fc0e9d90711927cc5923f6b', size: 'XL', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C0471X' },
				{ _id: '5fc0e9d90711927cc5923f6c', size: 'XXL', color: 'Sage', hexColor: 'C047', articleNumber: 'STTU755C0472X' },
				{
					_id: '5fc0e9d90711927cc5923f6d',
					size: 'XXS',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6592S',
				},
				{
					_id: '5fc0e9d90711927cc5923f6e',
					size: 'XS',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C659XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f6f',
					size: 'S',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6591S',
				},
				{
					_id: '5fc0e9d90711927cc5923f70',
					size: 'M',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6591M',
				},
				{
					_id: '5fc0e9d90711927cc5923f71',
					size: 'L',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6591L',
				},
				{
					_id: '5fc0e9d90711927cc5923f72',
					size: 'XL',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6591X',
				},
				{
					_id: '5fc0e9d90711927cc5923f73',
					size: 'XXL',
					color: 'Mid Heather Khaki',
					hexColor: 'C659',
					articleNumber: 'STTU755C6592X',
				},
				{
					_id: '5fc0e9d90711927cc5923f74',
					size: 'XXS',
					color: 'Khaki',
					hexColor: 'C223',
					articleNumber: 'STTU755C2232S',
				},
				{ _id: '5fc0e9d90711927cc5923f75', size: 'XS', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU755C223XS' },
				{ _id: '5fc0e9d90711927cc5923f76', size: 'S', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU755C2231S' },
				{ _id: '5fc0e9d90711927cc5923f77', size: 'M', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU755C2231M' },
				{ _id: '5fc0e9d90711927cc5923f78', size: 'L', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU755C2231L' },
				{ _id: '5fc0e9d90711927cc5923f79', size: 'XL', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU755C2231X' },
				{
					_id: '5fc0e9d90711927cc5923f7a',
					size: 'XXL',
					color: 'Khaki',
					hexColor: 'C223',
					articleNumber: 'STTU755C2232X',
				},
				{
					_id: '5fc0e9d90711927cc5923f7b',
					size: '3XL',
					color: 'Khaki',
					hexColor: 'C223',
					articleNumber: 'STTU755C2233X',
				},
				{
					_id: '5fc0e9d90711927cc5923f7c',
					size: 'XS',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C008XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f7d',
					size: 'S',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C0081S',
				},
				{
					_id: '5fc0e9d90711927cc5923f7e',
					size: 'M',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C0081M',
				},
				{
					_id: '5fc0e9d90711927cc5923f7f',
					size: 'L',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C0081L',
				},
				{
					_id: '5fc0e9d90711927cc5923f80',
					size: 'XL',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C0081X',
				},
				{
					_id: '5fc0e9d90711927cc5923f81',
					size: 'XXL',
					color: 'British Khaki',
					hexColor: 'C008',
					articleNumber: 'STTU755C0082X',
				},
				{
					_id: '5fc0e9d90711927cc5923f82',
					size: 'XS',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C224XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f83',
					size: 'S',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2241S',
				},
				{
					_id: '5fc0e9d90711927cc5923f84',
					size: 'M',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2241M',
				},
				{
					_id: '5fc0e9d90711927cc5923f85',
					size: 'L',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2241L',
				},
				{
					_id: '5fc0e9d90711927cc5923f86',
					size: 'XL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2241X',
				},
				{
					_id: '5fc0e9d90711927cc5923f87',
					size: 'XXL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2242X',
				},
				{
					_id: '5fc0e9d90711927cc5923f88',
					size: '3XL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU755C2243X',
				},
				{
					_id: '5fc0e9d90711927cc5923f89',
					size: 'XXS',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0362S',
				},
				{
					_id: '5fc0e9d90711927cc5923f8a',
					size: 'XS',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C036XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f8b',
					size: 'S',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0361S',
				},
				{
					_id: '5fc0e9d90711927cc5923f8c',
					size: 'M',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0361M',
				},
				{
					_id: '5fc0e9d90711927cc5923f8d',
					size: 'L',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0361L',
				},
				{
					_id: '5fc0e9d90711927cc5923f8e',
					size: 'XL',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0361X',
				},
				{
					_id: '5fc0e9d90711927cc5923f8f',
					size: 'XXL',
					color: 'Glazed Green',
					hexColor: 'C036',
					articleNumber: 'STTU755C0362X',
				},
				{
					_id: '5fc0e9d90711927cc5923f90',
					size: 'XXS',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6862S',
				},
				{
					_id: '5fc0e9d90711927cc5923f91',
					size: 'XS',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C686XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f92',
					size: 'S',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6861S',
				},
				{
					_id: '5fc0e9d90711927cc5923f93',
					size: 'M',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6861M',
				},
				{
					_id: '5fc0e9d90711927cc5923f94',
					size: 'L',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6861L',
				},
				{
					_id: '5fc0e9d90711927cc5923f95',
					size: 'XL',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6861X',
				},
				{
					_id: '5fc0e9d90711927cc5923f96',
					size: 'XXL',
					color: 'Heather Snow Glazed Green',
					hexColor: 'C686',
					articleNumber: 'STTU755C6862X',
				},
				{
					_id: '5fc0e9d90711927cc5923f97',
					size: 'XS',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C029XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f98',
					size: 'S',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C0291S',
				},
				{
					_id: '5fc0e9d90711927cc5923f99',
					size: 'M',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C0291M',
				},
				{
					_id: '5fc0e9d90711927cc5923f9a',
					size: 'L',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C0291L',
				},
				{
					_id: '5fc0e9d90711927cc5923f9b',
					size: 'XL',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C0291X',
				},
				{
					_id: '5fc0e9d90711927cc5923f9c',
					size: 'XXL',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU755C0292X',
				},
				{
					_id: '5fc0e9d90711927cc5923f9d',
					size: 'XS',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C014XS',
				},
				{
					_id: '5fc0e9d90711927cc5923f9e',
					size: 'S',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C0141S',
				},
				{
					_id: '5fc0e9d90711927cc5923f9f',
					size: 'M',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C0141M',
				},
				{
					_id: '5fc0e9d90711927cc5923fa0',
					size: 'L',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C0141L',
				},
				{
					_id: '5fc0e9d90711927cc5923fa1',
					size: 'XL',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C0141X',
				},
				{
					_id: '5fc0e9d90711927cc5923fa2',
					size: 'XXL',
					color: 'Fresh Green',
					hexColor: 'C014',
					articleNumber: 'STTU755C0142X',
				},
				{
					_id: '5fc0e9d90711927cc5923fa3',
					size: 'XS',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C012XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fa4',
					size: 'S',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C0121S',
				},
				{
					_id: '5fc0e9d90711927cc5923fa5',
					size: 'M',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C0121M',
				},
				{
					_id: '5fc0e9d90711927cc5923fa6',
					size: 'L',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C0121L',
				},
				{
					_id: '5fc0e9d90711927cc5923fa7',
					size: 'XL',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C0121X',
				},
				{
					_id: '5fc0e9d90711927cc5923fa8',
					size: 'XXL',
					color: 'Golden Yellow',
					hexColor: 'C012',
					articleNumber: 'STTU755C0122X',
				},
				{
					_id: '5fc0e9d90711927cc5923fa9',
					size: 'XS',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C204XS',
				},
				{
					_id: '5fc0e9d90711927cc5923faa',
					size: 'S',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C2041S',
				},
				{
					_id: '5fc0e9d90711927cc5923fab',
					size: 'M',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C2041M',
				},
				{
					_id: '5fc0e9d90711927cc5923fac',
					size: 'L',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C2041L',
				},
				{
					_id: '5fc0e9d90711927cc5923fad',
					size: 'XL',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C2041X',
				},
				{
					_id: '5fc0e9d90711927cc5923fae',
					size: 'XXL',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU755C2042X',
				},
				{ _id: '5fc0e9d90711927cc5923faf', size: 'XS', color: 'Ochre', hexColor: 'C048', articleNumber: 'STTU755C048XS' },
				{ _id: '5fc0e9d90711927cc5923fb0', size: 'S', color: 'Ochre', hexColor: 'C048', articleNumber: 'STTU755C0481S' },
				{ _id: '5fc0e9d90711927cc5923fb1', size: 'M', color: 'Ochre', hexColor: 'C048', articleNumber: 'STTU755C0481M' },
				{ _id: '5fc0e9d90711927cc5923fb2', size: 'L', color: 'Ochre', hexColor: 'C048', articleNumber: 'STTU755C0481L' },
				{ _id: '5fc0e9d90711927cc5923fb3', size: 'XL', color: 'Ochre', hexColor: 'C048', articleNumber: 'STTU755C0481X' },
				{
					_id: '5fc0e9d90711927cc5923fb4',
					size: 'XXL',
					color: 'Ochre',
					hexColor: 'C048',
					articleNumber: 'STTU755C0482X',
				},
				{
					_id: '5fc0e9d90711927cc5923fb5',
					size: 'XS',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C046XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fb6',
					size: 'S',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C0461S',
				},
				{
					_id: '5fc0e9d90711927cc5923fb7',
					size: 'M',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C0461M',
				},
				{
					_id: '5fc0e9d90711927cc5923fb8',
					size: 'L',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C0461L',
				},
				{
					_id: '5fc0e9d90711927cc5923fb9',
					size: 'XL',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C0461X',
				},
				{
					_id: '5fc0e9d90711927cc5923fba',
					size: 'XXL',
					color: 'Caramel',
					hexColor: 'C046',
					articleNumber: 'STTU755C0462X',
				},
				{
					_id: '5fc0e9d90711927cc5923fbb',
					size: 'XS',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C041XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fbc',
					size: 'S',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C0411S',
				},
				{
					_id: '5fc0e9d90711927cc5923fbd',
					size: 'M',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C0411M',
				},
				{
					_id: '5fc0e9d90711927cc5923fbe',
					size: 'L',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C0411L',
				},
				{
					_id: '5fc0e9d90711927cc5923fbf',
					size: 'XL',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C0411X',
				},
				{
					_id: '5fc0e9d90711927cc5923fc0',
					size: 'XXL',
					color: 'Deep Chocolate',
					hexColor: 'C041',
					articleNumber: 'STTU755C0412X',
				},
				{ _id: '5fc0e9d90711927cc5923fc1', size: 'XS', color: 'Camel', hexColor: 'C010', articleNumber: 'STTU755C010XS' },
				{ _id: '5fc0e9d90711927cc5923fc2', size: 'S', color: 'Camel', hexColor: 'C010', articleNumber: 'STTU755C0101S' },
				{ _id: '5fc0e9d90711927cc5923fc3', size: 'M', color: 'Camel', hexColor: 'C010', articleNumber: 'STTU755C0101M' },
				{ _id: '5fc0e9d90711927cc5923fc4', size: 'L', color: 'Camel', hexColor: 'C010', articleNumber: 'STTU755C0101L' },
				{ _id: '5fc0e9d90711927cc5923fc5', size: 'XL', color: 'Camel', hexColor: 'C010', articleNumber: 'STTU755C0101X' },
				{
					_id: '5fc0e9d90711927cc5923fc6',
					size: 'XXL',
					color: 'Camel',
					hexColor: 'C010',
					articleNumber: 'STTU755C0102X',
				},
				{
					_id: '5fc0e9d90711927cc5923fc7',
					size: 'XS',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C592XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fc8',
					size: 'S',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C5921S',
				},
				{
					_id: '5fc0e9d90711927cc5923fc9',
					size: 'M',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C5921M',
				},
				{
					_id: '5fc0e9d90711927cc5923fca',
					size: 'L',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C5921L',
				},
				{
					_id: '5fc0e9d90711927cc5923fcb',
					size: 'XL',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C5921X',
				},
				{
					_id: '5fc0e9d90711927cc5923fcc',
					size: 'XXL',
					color: 'Heather Sand',
					hexColor: 'C592',
					articleNumber: 'STTU755C5922X',
				},
				{
					_id: '5fc0e9d90711927cc5923fcd',
					size: 'XS',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C584XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fce',
					size: 'S',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C5841S',
				},
				{
					_id: '5fc0e9d90711927cc5923fcf',
					size: 'M',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C5841M',
				},
				{
					_id: '5fc0e9d90711927cc5923fd0',
					size: 'L',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C5841L',
				},
				{
					_id: '5fc0e9d90711927cc5923fd1',
					size: 'XL',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C5841X',
				},
				{
					_id: '5fc0e9d90711927cc5923fd2',
					size: 'XXL',
					color: 'Wooden Heather',
					hexColor: 'C584',
					articleNumber: 'STTU755C5842X',
				},
				{
					_id: '5fc0e9d90711927cc5923fd3',
					size: 'XS',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C028XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fd4',
					size: 'S',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C0281S',
				},
				{
					_id: '5fc0e9d90711927cc5923fd5',
					size: 'M',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C0281M',
				},
				{
					_id: '5fc0e9d90711927cc5923fd6',
					size: 'L',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C0281L',
				},
				{
					_id: '5fc0e9d90711927cc5923fd7',
					size: 'XL',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C0281X',
				},
				{
					_id: '5fc0e9d90711927cc5923fd8',
					size: 'XXL',
					color: 'Desert Dust',
					hexColor: 'C028',
					articleNumber: 'STTU755C0282X',
				},
				{
					_id: '5fc0e9d90711927cc5923fd9',
					size: 'XS',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C054XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fda',
					size: 'S',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C0541S',
				},
				{
					_id: '5fc0e9d90711927cc5923fdb',
					size: 'M',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C0541M',
				},
				{
					_id: '5fc0e9d90711927cc5923fdc',
					size: 'L',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C0541L',
				},
				{
					_id: '5fc0e9d90711927cc5923fdd',
					size: 'XL',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C0541X',
				},
				{
					_id: '5fc0e9d90711927cc5923fde',
					size: 'XXL',
					color: 'Natural Raw',
					hexColor: 'C054',
					articleNumber: 'STTU755C0542X',
				},
				{
					_id: '5fc0e9d90711927cc5923fdf',
					size: 'XXS',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5042S',
				},
				{
					_id: '5fc0e9d90711927cc5923fe0',
					size: 'XS',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C504XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fe1',
					size: 'S',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5041S',
				},
				{
					_id: '5fc0e9d90711927cc5923fe2',
					size: 'M',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5041M',
				},
				{
					_id: '5fc0e9d90711927cc5923fe3',
					size: 'L',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5041L',
				},
				{
					_id: '5fc0e9d90711927cc5923fe4',
					size: 'XL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5041X',
				},
				{
					_id: '5fc0e9d90711927cc5923fe5',
					size: 'XXL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5042X',
				},
				{
					_id: '5fc0e9d90711927cc5923fe6',
					size: '3XL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU755C5043X',
				},
				{
					_id: '5fc0e9d90711927cc5923fe7',
					size: 'XS',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C018XS',
				},
				{
					_id: '5fc0e9d90711927cc5923fe8',
					size: 'S',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C0181S',
				},
				{
					_id: '5fc0e9d90711927cc5923fe9',
					size: 'M',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C0181M',
				},
				{
					_id: '5fc0e9d90711927cc5923fea',
					size: 'L',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C0181L',
				},
				{
					_id: '5fc0e9d90711927cc5923feb',
					size: 'XL',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C0181X',
				},
				{
					_id: '5fc0e9d90711927cc5923fec',
					size: 'XXL',
					color: 'Off White',
					hexColor: 'C018',
					articleNumber: 'STTU755C0182X',
				},
				{
					_id: '5fc0e9d90711927cc5923fed',
					size: 'XXS',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU755C0012S',
				},
				{ _id: '5fc0e9d90711927cc5923fee', size: 'XS', color: 'White', hexColor: 'C001', articleNumber: 'STTU755C001XS' },
				{ _id: '5fc0e9d90711927cc5923fef', size: 'S', color: 'White', hexColor: 'C001', articleNumber: 'STTU755C0011S' },
				{ _id: '5fc0e9d90711927cc5923ff0', size: 'M', color: 'White', hexColor: 'C001', articleNumber: 'STTU755C0011M' },
				{ _id: '5fc0e9d90711927cc5923ff1', size: 'L', color: 'White', hexColor: 'C001', articleNumber: 'STTU755C0011L' },
				{ _id: '5fc0e9d90711927cc5923ff2', size: 'XL', color: 'White', hexColor: 'C001', articleNumber: 'STTU755C0011X' },
				{
					_id: '5fc0e9d90711927cc5923ff3',
					size: 'XXL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU755C0012X',
				},
				{
					_id: '5fc0e9d90711927cc5923ff4',
					size: '3XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU755C0013X',
				},
				{
					_id: '5fc0e9d90711927cc5923ff5',
					size: '4XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU755C0014X',
				},
				{
					_id: '5fc0e9d90711927cc5923ff6',
					size: '5XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU755C0015X',
				},
				{
					_id: '5fc0e9d90711927cc5923ff7',
					size: 'XXS',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6932S',
				},
				{
					_id: '5fc0e9d90711927cc5923ff8',
					size: 'XS',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C693XS',
				},
				{
					_id: '5fc0e9d90711927cc5923ff9',
					size: 'S',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6931S',
				},
				{
					_id: '5fc0e9d90711927cc5923ffa',
					size: 'M',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6931M',
				},
				{
					_id: '5fc0e9d90711927cc5923ffb',
					size: 'L',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6931L',
				},
				{
					_id: '5fc0e9d90711927cc5923ffc',
					size: 'XL',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6931X',
				},
				{
					_id: '5fc0e9d90711927cc5923ffd',
					size: 'XXL',
					color: 'Heather Ash',
					hexColor: 'C693',
					articleNumber: 'STTU755C6932X',
				},
				{
					_id: '5fc0e9d90711927cc5923ffe',
					size: 'XXS',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6802S',
				},
				{
					_id: '5fc0e9d90711927cc5923fff',
					size: 'XS',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C680XS',
				},
				{
					_id: '5fc0e9d90711927cc5924000',
					size: 'S',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6801S',
				},
				{
					_id: '5fc0e9d90711927cc5924001',
					size: 'M',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6801M',
				},
				{
					_id: '5fc0e9d90711927cc5924002',
					size: 'L',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6801L',
				},
				{
					_id: '5fc0e9d90711927cc5924003',
					size: 'XL',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6801X',
				},
				{
					_id: '5fc0e9d90711927cc5924004',
					size: 'XXL',
					color: 'Cream Heather Grey',
					hexColor: 'C680',
					articleNumber: 'STTU755C6802X',
				},
				{
					_id: '5fc0e9d90711927cc5924005',
					size: 'XS',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C671XS',
				},
				{
					_id: '5fc0e9d90711927cc5924006',
					size: 'S',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C6711S',
				},
				{
					_id: '5fc0e9d90711927cc5924007',
					size: 'M',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C6711M',
				},
				{
					_id: '5fc0e9d90711927cc5924008',
					size: 'L',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C6711L',
				},
				{
					_id: '5fc0e9d90711927cc5924009',
					size: 'XL',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C6711X',
				},
				{
					_id: '5fc0e9d90711927cc592400a',
					size: 'XXL',
					color: 'Slub Heather Grey',
					hexColor: 'C671',
					articleNumber: 'STTU755C6712X',
				},
				{
					_id: '5fc0e9d90711927cc592400b',
					size: 'XXS',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2502S',
				},
				{
					_id: '5fc0e9d90711927cc592400c',
					size: 'XS',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C250XS',
				},
				{
					_id: '5fc0e9d90711927cc592400d',
					size: 'S',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2501S',
				},
				{
					_id: '5fc0e9d90711927cc592400e',
					size: 'M',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2501M',
				},
				{
					_id: '5fc0e9d90711927cc592400f',
					size: 'L',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2501L',
				},
				{
					_id: '5fc0e9d90711927cc5924010',
					size: 'XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2501X',
				},
				{
					_id: '5fc0e9d90711927cc5924011',
					size: 'XXL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2502X',
				},
				{
					_id: '5fc0e9d90711927cc5924012',
					size: '3XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2503X',
				},
				{
					_id: '5fc0e9d90711927cc5924013',
					size: '4XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2504X',
				},
				{
					_id: '5fc0e9d90711927cc5924014',
					size: '5XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU755C2505X',
				},
				{ _id: '5fc0e9d90711927cc5924015', size: 'XS', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C251XS' },
				{ _id: '5fc0e9d90711927cc5924016', size: 'S', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C2511S' },
				{ _id: '5fc0e9d90711927cc5924017', size: 'M', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C2511M' },
				{ _id: '5fc0e9d90711927cc5924018', size: 'L', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C2511L' },
				{ _id: '5fc0e9d90711927cc5924019', size: 'XL', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C2511X' },
				{ _id: '5fc0e9d90711927cc592401a', size: 'XXL', color: 'Opal', hexColor: 'C251', articleNumber: 'STTU755C2512X' },
				{
					_id: '5fc0e9d90711927cc592401b',
					size: 'XXS',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6502S',
				},
				{
					_id: '5fc0e9d90711927cc592401c',
					size: 'XS',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C650XS',
				},
				{
					_id: '5fc0e9d90711927cc592401d',
					size: 'S',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6501S',
				},
				{
					_id: '5fc0e9d90711927cc592401e',
					size: 'M',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6501M',
				},
				{
					_id: '5fc0e9d90711927cc592401f',
					size: 'L',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6501L',
				},
				{
					_id: '5fc0e9d90711927cc5924020',
					size: 'XL',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6501X',
				},
				{
					_id: '5fc0e9d90711927cc5924021',
					size: 'XXL',
					color: 'Mid Heather Grey',
					hexColor: 'C650',
					articleNumber: 'STTU755C6502X',
				},
				{
					_id: '5fc0e9d90711927cc5924022',
					size: 'XS',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C673XS',
				},
				{
					_id: '5fc0e9d90711927cc5924023',
					size: 'S',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C6731S',
				},
				{
					_id: '5fc0e9d90711927cc5924024',
					size: 'M',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C6731M',
				},
				{
					_id: '5fc0e9d90711927cc5924025',
					size: 'L',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C6731L',
				},
				{
					_id: '5fc0e9d90711927cc5924026',
					size: 'XL',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C6731X',
				},
				{
					_id: '5fc0e9d90711927cc5924027',
					size: 'XXL',
					color: 'Slub Heather Steel Grey',
					hexColor: 'C673',
					articleNumber: 'STTU755C6732X',
				},
				{
					_id: '5fc0e9d90711927cc5924028',
					size: 'XXS',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2532S',
				},
				{
					_id: '5fc0e9d90711927cc5924029',
					size: 'XS',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C253XS',
				},
				{
					_id: '5fc0e9d90711927cc592402a',
					size: 'S',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2531S',
				},
				{
					_id: '5fc0e9d90711927cc592402b',
					size: 'M',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2531M',
				},
				{
					_id: '5fc0e9d90711927cc592402c',
					size: 'L',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2531L',
				},
				{
					_id: '5fc0e9d90711927cc592402d',
					size: 'XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2531X',
				},
				{
					_id: '5fc0e9d90711927cc592402e',
					size: 'XXL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2532X',
				},
				{
					_id: '5fc0e9d90711927cc592402f',
					size: '3XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2533X',
				},
				{
					_id: '5fc0e9d90711927cc5924030',
					size: '4XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU755C2534X',
				},
				{
					_id: '5fc0e9d90711927cc5924031',
					size: 'XXS',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6512S',
				},
				{
					_id: '5fc0e9d90711927cc5924032',
					size: 'XS',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C651XS',
				},
				{
					_id: '5fc0e9d90711927cc5924033',
					size: 'S',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6511S',
				},
				{
					_id: '5fc0e9d90711927cc5924034',
					size: 'M',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6511M',
				},
				{
					_id: '5fc0e9d90711927cc5924035',
					size: 'L',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6511L',
				},
				{
					_id: '5fc0e9d90711927cc5924036',
					size: 'XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6511X',
				},
				{
					_id: '5fc0e9d90711927cc5924037',
					size: 'XXL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6512X',
				},
				{
					_id: '5fc0e9d90711927cc5924038',
					size: '3XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6513X',
				},
				{
					_id: '5fc0e9d90711927cc5924039',
					size: '4XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU755C6514X',
				},
				{
					_id: '5fc0e9d90711927cc592403a',
					size: 'XS',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C697XS',
				},
				{
					_id: '5fc0e9d90711927cc592403b',
					size: 'S',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C6971S',
				},
				{
					_id: '5fc0e9d90711927cc592403c',
					size: 'M',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C6971M',
				},
				{
					_id: '5fc0e9d90711927cc592403d',
					size: 'L',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C6971L',
				},
				{
					_id: '5fc0e9d90711927cc592403e',
					size: 'XL',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C6971X',
				},
				{
					_id: '5fc0e9d90711927cc592403f',
					size: 'XXL',
					color: 'Heather Black Denim',
					hexColor: 'C697',
					articleNumber: 'STTU755C6972X',
				},
				{
					_id: '5fc0e9d90711927cc5924040',
					size: 'XXS',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU755C0022S',
				},
				{ _id: '5fc0e9d90711927cc5924041', size: 'XS', color: 'Black', hexColor: 'C002', articleNumber: 'STTU755C002XS' },
				{ _id: '5fc0e9d90711927cc5924042', size: 'S', color: 'Black', hexColor: 'C002', articleNumber: 'STTU755C0021S' },
				{ _id: '5fc0e9d90711927cc5924043', size: 'M', color: 'Black', hexColor: 'C002', articleNumber: 'STTU755C0021M' },
				{ _id: '5fc0e9d90711927cc5924044', size: 'L', color: 'Black', hexColor: 'C002', articleNumber: 'STTU755C0021L' },
				{ _id: '5fc0e9d90711927cc5924045', size: 'XL', color: 'Black', hexColor: 'C002', articleNumber: 'STTU755C0021X' },
				{
					_id: '5fc0e9d90711927cc5924046',
					size: 'XXL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU755C0022X',
				},
				{
					_id: '5fc0e9d90711927cc5924047',
					size: '3XL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU755C0023X',
				},
				{
					_id: '5fc0e9d90711927cc5924048',
					size: '4XL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU755C0024X',
				},
				{
					_id: '5fc0e9d90711927cc5924049',
					size: '5XL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU755C0025X',
				},
				{
					_id: '5fc0e9d90711927cc592404a',
					size: 'XXS',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0372S',
				},
				{
					_id: '5fc0e9d90711927cc592404b',
					size: 'XS',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C022XS',
				},
				{
					_id: '5fc0e9d90711927cc592404c',
					size: 'XS',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C040XS',
				},
				{
					_id: '5fc0e9d90711927cc592404d',
					size: 'XS',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C660XS',
				},
				{
					_id: '5fc0e9d90711927cc592404e',
					size: 'XS',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C678XS',
				},
				{
					_id: '5fc0e9d90711927cc592404f',
					size: 'XS',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C662XS',
				},
				{
					_id: '5fc0e9d90711927cc5924050',
					size: 'XS',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C037XS',
				},
				{
					_id: '5fc0e9d90711927cc5924051',
					size: 'XS',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C025XS',
				},
				{
					_id: '5fc0e9d90711927cc5924052',
					size: 'XS',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C594XS',
				},
				{
					_id: '5fc0e9d90711927cc5924053',
					size: 'XS',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C699XS',
				},
				{
					_id: '5fc0e9d90711927cc5924054',
					size: 'XS',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C031XS',
				},
				{
					_id: '5fc0e9d90711927cc5924055',
					size: 'XS',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C033XS',
				},
				{
					_id: '5fc0e9d90711927cc5924056',
					size: 'XS',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C035XS',
				},
				{
					_id: '5fc0e9d90711927cc5924057',
					size: 'XS',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C026XS',
				},
				{
					_id: '5fc0e9d90711927cc5924058',
					size: 'XS',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C027XS',
				},
				{
					_id: '5fc0e9d90711927cc5924059',
					size: 'S',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C0331S',
				},
				{
					_id: '5fc0e9d90711927cc592405a',
					size: 'S',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C0351S',
				},
				{
					_id: '5fc0e9d90711927cc592405b',
					size: 'S',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C0261S',
				},
				{
					_id: '5fc0e9d90711927cc592405c',
					size: 'S',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C6601S',
				},
				{
					_id: '5fc0e9d90711927cc592405d',
					size: 'S',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C0311S',
				},
				{
					_id: '5fc0e9d90711927cc592405e',
					size: 'S',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C0401S',
				},
				{
					_id: '5fc0e9d90711927cc592405f',
					size: 'S',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C0251S',
				},
				{
					_id: '5fc0e9d90711927cc5924060',
					size: 'S',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0371S',
				},
				{
					_id: '5fc0e9d90711927cc5924061',
					size: 'S',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C0271S',
				},
				{
					_id: '5fc0e9d90711927cc5924062',
					size: 'S',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C6991S',
				},
				{
					_id: '5fc0e9d90711927cc5924063',
					size: 'S',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C6781S',
				},
				{
					_id: '5fc0e9d90711927cc5924064',
					size: 'S',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C5941S',
				},
				{
					_id: '5fc0e9d90711927cc5924065',
					size: 'S',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C0221S',
				},
				{
					_id: '5fc0e9d90711927cc5924066',
					size: 'S',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C6621S',
				},
				{
					_id: '5fc0e9d90711927cc5924067',
					size: 'M',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C6781M',
				},
				{
					_id: '5fc0e9d90711927cc5924068',
					size: 'M',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C5941M',
				},
				{
					_id: '5fc0e9d90711927cc5924069',
					size: 'M',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C6991M',
				},
				{
					_id: '5fc0e9d90711927cc592406a',
					size: 'M',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C0221M',
				},
				{
					_id: '5fc0e9d90711927cc592406b',
					size: 'M',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0371M',
				},
				{
					_id: '5fc0e9d90711927cc592406c',
					size: 'M',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C0311M',
				},
				{
					_id: '5fc0e9d90711927cc592406d',
					size: 'M',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C0271M',
				},
				{
					_id: '5fc0e9d90711927cc592406e',
					size: 'M',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C0401M',
				},
				{
					_id: '5fc0e9d90711927cc592406f',
					size: 'M',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C6621M',
				},
				{
					_id: '5fc0e9d90711927cc5924070',
					size: 'M',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C0251M',
				},
				{
					_id: '5fc0e9d90711927cc5924071',
					size: 'M',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C0331M',
				},
				{
					_id: '5fc0e9d90711927cc5924072',
					size: 'M',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C0351M',
				},
				{
					_id: '5fc0e9d90711927cc5924073',
					size: 'M',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C0261M',
				},
				{
					_id: '5fc0e9d90711927cc5924074',
					size: 'M',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C6601M',
				},
				{
					_id: '5fc0e9d90711927cc5924075',
					size: 'L',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C0261L',
				},
				{
					_id: '5fc0e9d90711927cc5924076',
					size: 'L',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C0331L',
				},
				{
					_id: '5fc0e9d90711927cc5924077',
					size: 'L',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C0271L',
				},
				{
					_id: '5fc0e9d90711927cc5924078',
					size: 'L',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C0351L',
				},
				{
					_id: '5fc0e9d90711927cc5924079',
					size: 'L',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C0251L',
				},
				{
					_id: '5fc0e9d90711927cc592407a',
					size: 'L',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C5941L',
				},
				{
					_id: '5fc0e9d90711927cc592407b',
					size: 'L',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C0311L',
				},
				{
					_id: '5fc0e9d90711927cc592407c',
					size: 'L',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0371L',
				},
				{
					_id: '5fc0e9d90711927cc592407d',
					size: 'L',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C0401L',
				},
				{
					_id: '5fc0e9d90711927cc592407e',
					size: 'L',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C0221L',
				},
				{
					_id: '5fc0e9d90711927cc592407f',
					size: 'L',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C6991L',
				},
				{
					_id: '5fc0e9d90711927cc5924080',
					size: 'L',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C6621L',
				},
				{
					_id: '5fc0e9d90711927cc5924081',
					size: 'L',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C6781L',
				},
				{
					_id: '5fc0e9d90711927cc5924082',
					size: 'L',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C6601L',
				},
				{
					_id: '5fc0e9d90711927cc5924083',
					size: 'XL',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C0251X',
				},
				{
					_id: '5fc0e9d90711927cc5924084',
					size: 'XL',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C5941X',
				},
				{
					_id: '5fc0e9d90711927cc5924085',
					size: 'XL',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0371X',
				},
				{
					_id: '5fc0e9d90711927cc5924086',
					size: 'XL',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C6621X',
				},
				{
					_id: '5fc0e9d90711927cc5924087',
					size: 'XL',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C0221X',
				},
				{
					_id: '5fc0e9d90711927cc5924088',
					size: 'XL',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C0401X',
				},
				{
					_id: '5fc0e9d90711927cc5924089',
					size: 'XL',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C6991X',
				},
				{
					_id: '5fc0e9d90711927cc592408a',
					size: 'XL',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C0271X',
				},
				{
					_id: '5fc0e9d90711927cc592408b',
					size: 'XL',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C0351X',
				},
				{
					_id: '5fc0e9d90711927cc592408c',
					size: 'XL',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C0311X',
				},
				{
					_id: '5fc0e9d90711927cc592408d',
					size: 'XL',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C0331X',
				},
				{
					_id: '5fc0e9d90711927cc592408e',
					size: 'XL',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C6601X',
				},
				{
					_id: '5fc0e9d90711927cc592408f',
					size: 'XL',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C0261X',
				},
				{
					_id: '5fc0e9d90711927cc5924090',
					size: 'XL',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C6781X',
				},
				{
					_id: '5fc0e9d90711927cc5924091',
					size: 'XXL',
					color: 'Roasted Orange',
					hexColor: 'C031',
					articleNumber: 'STTU755C0312X',
				},
				{
					_id: '5fc0e9d90711927cc5924092',
					size: 'XXL',
					color: 'Scale Green',
					hexColor: 'C026',
					articleNumber: 'STTU755C0262X',
				},
				{
					_id: '5fc0e9d90711927cc5924093',
					size: 'XXL',
					color: 'Marble Slub Heather Black',
					hexColor: 'C678',
					articleNumber: 'STTU755C6782X',
				},
				{
					_id: '5fc0e9d90711927cc5924094',
					size: 'XXL',
					color: 'Chameleon Green',
					hexColor: 'C027',
					articleNumber: 'STTU755C0272X',
				},
				{
					_id: '5fc0e9d90711927cc5924095',
					size: 'XXL',
					color: 'Moss Green',
					hexColor: 'C033',
					articleNumber: 'STTU755C0332X',
				},
				{
					_id: '5fc0e9d90711927cc5924096',
					size: 'XXL',
					color: 'Yellow Mist',
					hexColor: 'C035',
					articleNumber: 'STTU755C0352X',
				},
				{
					_id: '5fc0e9d90711927cc5924097',
					size: 'XXL',
					color: 'Geyser Green',
					hexColor: 'C037',
					articleNumber: 'STTU755C0372X',
				},
				{
					_id: '5fc0e9d90711927cc5924098',
					size: 'XXL',
					color: 'Heather Neppy Lemon Grass',
					hexColor: 'C699',
					articleNumber: 'STTU755C6992X',
				},
				{
					_id: '5fc0e9d90711927cc5924099',
					size: 'XXL',
					color: 'Dark Heather Burgundy',
					hexColor: 'C660',
					articleNumber: 'STTU755C6602X',
				},
				{
					_id: '5fc0e9d90711927cc592409a',
					size: 'XXL',
					color: 'Hay Yellow',
					hexColor: 'C040',
					articleNumber: 'STTU755C0402X',
				},
				{
					_id: '5fc0e9d90711927cc592409b',
					size: 'XXL',
					color: 'Heather Scarab Green',
					hexColor: 'C662',
					articleNumber: 'STTU755C6622X',
				},
				{
					_id: '5fc0e9d90711927cc592409c',
					size: 'XXL',
					color: 'Black Heather Orange',
					hexColor: 'C594',
					articleNumber: 'STTU755C5942X',
				},
				{
					_id: '5fc0e9d90711927cc592409d',
					size: 'XXL',
					color: 'Sunset Orange',
					hexColor: 'C025',
					articleNumber: 'STTU755C0252X',
				},
				{
					_id: '5fc0e9d90711927cc592409e',
					size: 'XXL',
					color: 'Purple LED',
					hexColor: 'C022',
					articleNumber: 'STTU755C0222X',
				},
			],
			'purchasingPrice<10': 5,
			'purchasingPrice>10': 5,
			'purchasingPrice>50': 5,
			'purchasingPrice>100': 4,
			'purchasingPrice>250': 4,
			'purchasingPrice>500': 4,
			'purchasingPrice>1000': 4,
			piecesPerBox: 100,
			productionCountry: 'BD',
			hsCode: '61091000',
			weight: 0,
			mainImage: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU755_C011.jpg',
			createdAt: '2020-11-27T11:58:17.594Z',
			updates: [],
			__v: 0,
			productType: 'shirt-pocket',
			visible: true,
			sortIndex: 7,
		},
		internalId: '5fc0e9d90711927cc5923df0',
		externalId: 'STTU755',
		shop: 'stanley-stella',
		name: 'Creator',
		type: 'shirt',
		colors: [
			{ _id: '5fc0e9d90711927cc5923dfb', value: 'Bright Red', hexValue: 'C011' },
			{ _id: '5fc0e9d90711927cc5923dfc', value: 'Tangerine', hexValue: 'C032' },
			{ _id: '5fc0e9d90711927cc5923dfd', value: 'Bright Orange', hexValue: 'C013' },
			{ _id: '5fc0e9d90711927cc5923dfe', value: 'Melon Code', hexValue: 'C051' },
			{ _id: '5fc0e9d90711927cc5923dff', value: 'Rose Clay', hexValue: 'C050' },
			{ _id: '5fc0e9d90711927cc5923e00', value: 'Heather Neppy Pink', hexValue: 'C688' },
			{ _id: '5fc0e9d90711927cc5923e01', value: 'Cotton Pink', hexValue: 'C005' },
			{ _id: '5fc0e9d90711927cc5923e02', value: 'Cream Heather Pink', hexValue: 'C682' },
			{ _id: '5fc0e9d90711927cc5923e03', value: 'Candy Pink', hexValue: 'C016' },
			{ _id: '5fc0e9d90711927cc5923e04', value: 'Ecru Neppy Mandarine', hexValue: 'C695' },
			{ _id: '5fc0e9d90711927cc5923e05', value: 'Black Heather Cranberry', hexValue: 'C589' },
			{ _id: '5fc0e9d90711927cc5923e06', value: 'Mauve', hexValue: 'C049' },
			{ _id: '5fc0e9d90711927cc5923e07', value: 'Lavender Dawn', hexValue: 'C030' },
			{ _id: '5fc0e9d90711927cc5923e08', value: 'Plum', hexValue: 'C241' },
			{ _id: '5fc0e9d90711927cc5923e09', value: 'Heather Grape Red', hexValue: 'C664' },
			{ _id: '5fc0e9d90711927cc5923e0a', value: 'Burgundy', hexValue: 'C244' },
			{ _id: '5fc0e9d90711927cc5923e0b', value: 'Heather Neppy Burgundy', hexValue: 'C687' },
			{ _id: '5fc0e9d90711927cc5923e0c', value: 'Red', hexValue: 'C004' },
			{ _id: '5fc0e9d90711927cc5923e0d', value: 'Carmine Red', hexValue: 'C052' },
			{ _id: '5fc0e9d90711927cc5923e0e', value: 'Mid Heather Red', hexValue: 'C657' },
			{ _id: '5fc0e9d90711927cc5923e0f', value: 'Pink Punch', hexValue: 'C024' },
			{ _id: '5fc0e9d90711927cc5923e10', value: 'French Navy', hexValue: 'C727' },
			{ _id: '5fc0e9d90711927cc5923e11', value: 'Dark Heather Denim', hexValue: 'C670' },
			{ _id: '5fc0e9d90711927cc5923e12', value: 'Black Heather Blue', hexValue: 'C588' },
			{ _id: '5fc0e9d90711927cc5923e13', value: 'Dark Heather Indigo', hexValue: 'C658' },
			{ _id: '5fc0e9d90711927cc5923e14', value: 'Dark Heather Blue', hexValue: 'C652' },
			{ _id: '5fc0e9d90711927cc5923e15', value: 'India Ink Grey', hexValue: 'C715' },
			{ _id: '5fc0e9d90711927cc5923e16', value: 'Heather Snow Mid Blue', hexValue: 'C700' },
			{ _id: '5fc0e9d90711927cc5923e17', value: 'Majorelle Blue', hexValue: 'C034' },
			{ _id: '5fc0e9d90711927cc5923e18', value: 'Mid Heather Royal Blue', hexValue: 'C587' },
			{ _id: '5fc0e9d90711927cc5923e19', value: 'Royal Blue', hexValue: 'C230' },
			{ _id: '5fc0e9d90711927cc5923e1a', value: 'Azur', hexValue: 'C231' },
			{ _id: '5fc0e9d90711927cc5923e1b', value: 'Bright Blue', hexValue: 'C053' },
			{ _id: '5fc0e9d90711927cc5923e1c', value: 'Mid Heather Blue', hexValue: 'C653' },
			{ _id: '5fc0e9d90711927cc5923e1d', value: 'Sky blue', hexValue: 'C232' },
			{ _id: '5fc0e9d90711927cc5923e1e', value: 'Baby Blue', hexValue: 'C015' },
			{ _id: '5fc0e9d90711927cc5923e1f', value: 'Heather Ice Blue', hexValue: 'C585' },
			{ _id: '5fc0e9d90711927cc5923e20', value: 'Citadel Blue', hexValue: 'C725' },
			{ _id: '5fc0e9d90711927cc5923e21', value: 'Stargazer', hexValue: 'C702' },
			{ _id: '5fc0e9d90711927cc5923e22', value: 'Ocean Depth', hexValue: 'C710' },
			{ _id: '5fc0e9d90711927cc5923e23', value: 'Mid Heather Green', hexValue: 'C656' },
			{ _id: '5fc0e9d90711927cc5923e24', value: 'Caribbean Blue', hexValue: 'C724' },
			{ _id: '5fc0e9d90711927cc5923e25', value: 'Sage', hexValue: 'C047' },
			{ _id: '5fc0e9d90711927cc5923e26', value: 'Mid Heather Khaki', hexValue: 'C659' },
			{ _id: '5fc0e9d90711927cc5923e27', value: 'Khaki', hexValue: 'C223' },
			{ _id: '5fc0e9d90711927cc5923e28', value: 'British Khaki', hexValue: 'C008' },
			{ _id: '5fc0e9d90711927cc5923e29', value: 'Bottle Green', hexValue: 'C224' },
			{ _id: '5fc0e9d90711927cc5923e2a', value: 'Glazed Green', hexValue: 'C036' },
			{ _id: '5fc0e9d90711927cc5923e2b', value: 'Heather Snow Glazed Green', hexValue: 'C686' },
			{ _id: '5fc0e9d90711927cc5923e2c', value: 'Varsity Green', hexValue: 'C029' },
			{ _id: '5fc0e9d90711927cc5923e2d', value: 'Fresh Green', hexValue: 'C014' },
			{ _id: '5fc0e9d90711927cc5923e2e', value: 'Golden Yellow', hexValue: 'C012' },
			{ _id: '5fc0e9d90711927cc5923e2f', value: 'Spectra Yellow', hexValue: 'C204' },
			{ _id: '5fc0e9d90711927cc5923e30', value: 'Ochre', hexValue: 'C048' },
			{ _id: '5fc0e9d90711927cc5923e31', value: 'Caramel', hexValue: 'C046' },
			{ _id: '5fc0e9d90711927cc5923e32', value: 'Deep Chocolate', hexValue: 'C041' },
			{ _id: '5fc0e9d90711927cc5923e33', value: 'Camel', hexValue: 'C010' },
			{ _id: '5fc0e9d90711927cc5923e34', value: 'Heather Sand', hexValue: 'C592' },
			{ _id: '5fc0e9d90711927cc5923e35', value: 'Wooden Heather', hexValue: 'C584' },
			{ _id: '5fc0e9d90711927cc5923e36', value: 'Desert Dust', hexValue: 'C028' },
			{ _id: '5fc0e9d90711927cc5923e37', value: 'Natural Raw', hexValue: 'C054' },
			{ _id: '5fc0e9d90711927cc5923e38', value: 'Vintage White', hexValue: 'C504' },
			{ _id: '5fc0e9d90711927cc5923e39', value: 'Off White', hexValue: 'C018' },
			{ _id: '5fc0e9d90711927cc5923e3a', value: 'White', hexValue: 'C001' },
			{ _id: '5fc0e9d90711927cc5923e3b', value: 'Heather Ash', hexValue: 'C693' },
			{ _id: '5fc0e9d90711927cc5923e3c', value: 'Cream Heather Grey', hexValue: 'C680' },
			{ _id: '5fc0e9d90711927cc5923e3d', value: 'Slub Heather Grey', hexValue: 'C671' },
			{ _id: '5fc0e9d90711927cc5923e3e', value: 'Heather Grey', hexValue: 'C250' },
			{ _id: '5fc0e9d90711927cc5923e3f', value: 'Opal', hexValue: 'C251' },
			{ _id: '5fc0e9d90711927cc5923e40', value: 'Mid Heather Grey', hexValue: 'C650' },
			{ _id: '5fc0e9d90711927cc5923e41', value: 'Slub Heather Steel Grey', hexValue: 'C673' },
			{ _id: '5fc0e9d90711927cc5923e42', value: 'Anthracite', hexValue: 'C253' },
			{ _id: '5fc0e9d90711927cc5923e43', value: 'Dark Heather Grey', hexValue: 'C651' },
			{ _id: '5fc0e9d90711927cc5923e44', value: 'Heather Black Denim', hexValue: 'C697' },
			{ _id: '5fc0e9d90711927cc5923e45', value: 'Black', hexValue: 'C002' },
			{ _id: '5fc0e9d90711927cc5923e46', value: 'Geyser Green', hexValue: 'C037' },
			{ _id: '5fc0e9d90711927cc5923e47', value: 'Purple LED', hexValue: 'C022' },
			{ _id: '5fc0e9d90711927cc5923e48', value: 'Hay Yellow', hexValue: 'C040' },
			{ _id: '5fc0e9d90711927cc5923e49', value: 'Dark Heather Burgundy', hexValue: 'C660' },
			{ _id: '5fc0e9d90711927cc5923e4a', value: 'Marble Slub Heather Black', hexValue: 'C678' },
			{ _id: '5fc0e9d90711927cc5923e4b', value: 'Heather Scarab Green', hexValue: 'C662' },
			{ _id: '5fc0e9d90711927cc5923e4c', value: 'Sunset Orange', hexValue: 'C025' },
			{ _id: '5fc0e9d90711927cc5923e4d', value: 'Black Heather Orange', hexValue: 'C594' },
			{ _id: '5fc0e9d90711927cc5923e4e', value: 'Heather Neppy Lemon Grass', hexValue: 'C699' },
			{ _id: '5fc0e9d90711927cc5923e4f', value: 'Roasted Orange', hexValue: 'C031' },
			{ _id: '5fc0e9d90711927cc5923e50', value: 'Moss Green', hexValue: 'C033' },
			{ _id: '5fc0e9d90711927cc5923e51', value: 'Yellow Mist', hexValue: 'C035' },
			{ _id: '5fc0e9d90711927cc5923e52', value: 'Scale Green', hexValue: 'C026' },
			{ _id: '5fc0e9d90711927cc5923e53', value: 'Chameleon Green', hexValue: 'C027' },
		],
		variants: {
			0: {
				hexColor: '',
				color: 'Bright Red',
				approved: false,
				finishes: { 0: { type: 'single', colors: [{ hexColor: '#000000' }] } },
				sizes: [
					{ size: 'S', amount: 4 },
					{ size: 'M', amount: 10 },
					{ size: 'L', amount: 10 },
					{ size: 'XL', amount: 5 },
					{ size: 'XXL', amount: 1 },
				],
				extractedHexColor: '#bd0f18',
			},
		},
		selectedSizes: ['S', 'M', 'L', 'XL', 'XXL'],
		finishes: {
			0: {
				technique: 'print',
				techniqueType: null,
				fileName: 'dummy_design_1_1237892374925',
				editId: null,
				size: { width: 6, height: 0 },
				sizeMeasure: 'width',
				side: 'front',
				position: 'front-right',
				coords: {},
				imageWidth: 0,
				imageHeight: 0,
				idx: '0',
			},
		},
		addons: [],
	},
	shirt2: {
		editId: 'e6a1ef3a-e790-4507-84c1-538667bd4a0a',
		ref: {
			_id: '5fc0e0070711927cc5923c9b',
			images: ['https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg'],
			colorImages: [
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C013.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C013.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C013.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C013.jpg',
					colorCode: 'C013',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C005.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C005.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C005.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C005.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C005.jpg',
					colorCode: 'C005',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C241.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C241.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C241.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C241.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C241.jpg',
					colorCode: 'C241',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C244.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C244.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C244.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C244.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C244.jpg',
					colorCode: 'C244',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C004.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C004.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C004.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C004.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C004.jpg',
					colorCode: 'C004',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C727.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C727.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C727.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C727.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C727.jpg',
					colorCode: 'C727',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C658.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C658.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C658.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C658.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C658.jpg',
					colorCode: 'C658',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C230.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C230.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C230.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C230.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C230.jpg',
					colorCode: 'C230',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C231.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C231.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C231.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C231.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C231.jpg',
					colorCode: 'C231',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C232.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C232.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C232.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C232.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C232.jpg',
					colorCode: 'C232',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C223.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C223.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C223.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C223.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C223.jpg',
					colorCode: 'C223',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C224.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C224.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C224.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C224.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C224.jpg',
					colorCode: 'C224',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C029.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C029.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C029.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C029.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C029.jpg',
					colorCode: 'C029',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C204.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C204.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C204.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C204.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C204.jpg',
					colorCode: 'C204',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C504.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C504.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C504.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C504.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C504.jpg',
					colorCode: 'C504',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C001.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C001.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C001.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C001.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C001.jpg',
					colorCode: 'C001',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C250.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C250.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C250.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C250.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C250.jpg',
					colorCode: 'C250',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C253.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C253.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C253.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C253.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C253.jpg',
					colorCode: 'C253',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C651.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C651.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C651.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C651.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C651.jpg',
					colorCode: 'C651',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
				{
					front: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C002.jpg',
					back: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PBM0_STTU758_C002.jpg',
					side: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PSM0_STTU758_C002.jpg',
					detail: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFD0_STTU758_C002.jpg',
					studio: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/SFM0_STTU758_C002.jpg',
					colorCode: 'C002',
				},
			],
			externalArticleNumber: 'STTU758',
			shopName: 'stanley-stella',
			name: 'Rocker',
			description: 'Eingesetzte Ärmel\n1x1 Rippstrick am Ausschnitt\nAbgesetztes Nackenband\nSchlauchware',
			grammar: '150',
			certificates: 'Global Organic Textile Standard | Peta - Approved Vegan |  |  |  | OEKO-TEX Standard 100',
			style: 'modern',
			materials: '100% gekämmte ringgesponnene Bio-Baumwolle',
			label: '',
			category: 'shirt',
			sizes: [
				{ _id: '5fc0e0070711927cc5923c9c', value: 'XS' },
				{ _id: '5fc0e0070711927cc5923c9d', value: 'S', amount: 4 },
				{ _id: '5fc0e0070711927cc5923c9e', value: 'M', amount: 10 },
				{ _id: '5fc0e0070711927cc5923c9f', value: 'L', amount: 10 },
				{ _id: '5fc0e0070711927cc5923ca0', value: 'XL', amount: 5 },
				{ _id: '5fc0e0070711927cc5923ca1', value: 'XXL', amount: 1 },
				{ _id: '5fc0e0070711927cc5923ca2', value: '3XL' },
				{ _id: '5fc0e0070711927cc5923ca3', value: '4XL' },
				{ _id: '5fc0e0070711927cc5923ca4', value: 'XXS' },
				{ _id: '5fc0e0070711927cc5923ca5', value: '5XL' },
			],
			colors: [
				{ _id: '5fc0e0070711927cc5923ca6', value: 'Bright Orange', hexValue: 'C013' },
				{ _id: '5fc0e0070711927cc5923ca7', value: 'Cotton Pink', hexValue: 'C005' },
				{ _id: '5fc0e0070711927cc5923ca8', value: 'Plum', hexValue: 'C241' },
				{ _id: '5fc0e0070711927cc5923ca9', value: 'Burgundy', hexValue: 'C244' },
				{ _id: '5fc0e0070711927cc5923caa', value: 'Red', hexValue: 'C004' },
				{ _id: '5fc0e0070711927cc5923cab', value: 'French Navy', hexValue: 'C727' },
				{ _id: '5fc0e0070711927cc5923cac', value: 'Dark Heather Indigo', hexValue: 'C658' },
				{ _id: '5fc0e0070711927cc5923cad', value: 'Royal Blue', hexValue: 'C230' },
				{ _id: '5fc0e0070711927cc5923cae', value: 'Azur', hexValue: 'C231' },
				{ _id: '5fc0e0070711927cc5923caf', value: 'Sky blue', hexValue: 'C232' },
				{ _id: '5fc0e0070711927cc5923cb0', value: 'Khaki', hexValue: 'C223' },
				{ _id: '5fc0e0070711927cc5923cb1', value: 'Bottle Green', hexValue: 'C224' },
				{ _id: '5fc0e0070711927cc5923cb2', value: 'Varsity Green', hexValue: 'C029' },
				{ _id: '5fc0e0070711927cc5923cb3', value: 'Spectra Yellow', hexValue: 'C204' },
				{ _id: '5fc0e0070711927cc5923cb4', value: 'Vintage White', hexValue: 'C504' },
				{ _id: '5fc0e0070711927cc5923cb5', value: 'White', hexValue: 'C001' },
				{ _id: '5fc0e0070711927cc5923cb6', value: 'Heather Grey', hexValue: 'C250' },
				{ _id: '5fc0e0070711927cc5923cb7', value: 'Anthracite', hexValue: 'C253' },
				{ _id: '5fc0e0070711927cc5923cb8', value: 'Dark Heather Grey', hexValue: 'C651' },
				{ _id: '5fc0e0070711927cc5923cb9', value: 'Black', hexValue: 'C002' },
			],
			variants: [
				{
					_id: '5fc0e0070711927cc5923cba',
					size: 'XS',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C013XS',
				},
				{
					_id: '5fc0e0070711927cc5923cbb',
					size: 'S',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0131S',
				},
				{
					_id: '5fc0e0070711927cc5923cbc',
					size: 'M',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0131M',
				},
				{
					_id: '5fc0e0070711927cc5923cbd',
					size: 'L',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0131L',
				},
				{
					_id: '5fc0e0070711927cc5923cbe',
					size: 'XL',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0131X',
				},
				{
					_id: '5fc0e0070711927cc5923cbf',
					size: 'XXL',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0132X',
				},
				{
					_id: '5fc0e0070711927cc5923cc0',
					size: '3XL',
					color: 'Bright Orange',
					hexColor: 'C013',
					articleNumber: 'STTU758C0133X',
				},
				{
					_id: '5fc0e0070711927cc5923cc1',
					size: 'XS',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C005XS',
				},
				{
					_id: '5fc0e0070711927cc5923cc2',
					size: 'S',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0051S',
				},
				{
					_id: '5fc0e0070711927cc5923cc3',
					size: 'M',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0051M',
				},
				{
					_id: '5fc0e0070711927cc5923cc4',
					size: 'L',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0051L',
				},
				{
					_id: '5fc0e0070711927cc5923cc5',
					size: 'XL',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0051X',
				},
				{
					_id: '5fc0e0070711927cc5923cc6',
					size: 'XXL',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0052X',
				},
				{
					_id: '5fc0e0070711927cc5923cc7',
					size: '3XL',
					color: 'Cotton Pink',
					hexColor: 'C005',
					articleNumber: 'STTU758C0053X',
				},
				{ _id: '5fc0e0070711927cc5923cc8', size: 'XS', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C241XS' },
				{ _id: '5fc0e0070711927cc5923cc9', size: 'S', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2411S' },
				{ _id: '5fc0e0070711927cc5923cca', size: 'M', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2411M' },
				{ _id: '5fc0e0070711927cc5923ccb', size: 'L', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2411L' },
				{ _id: '5fc0e0070711927cc5923ccc', size: 'XL', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2411X' },
				{ _id: '5fc0e0070711927cc5923ccd', size: 'XXL', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2412X' },
				{ _id: '5fc0e0070711927cc5923cce', size: '3XL', color: 'Plum', hexColor: 'C241', articleNumber: 'STTU758C2413X' },
				{
					_id: '5fc0e0070711927cc5923ccf',
					size: 'XS',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C244XS',
				},
				{
					_id: '5fc0e0070711927cc5923cd0',
					size: 'S',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2441S',
				},
				{
					_id: '5fc0e0070711927cc5923cd1',
					size: 'M',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2441M',
				},
				{
					_id: '5fc0e0070711927cc5923cd2',
					size: 'L',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2441L',
				},
				{
					_id: '5fc0e0070711927cc5923cd3',
					size: 'XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2441X',
				},
				{
					_id: '5fc0e0070711927cc5923cd4',
					size: 'XXL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2442X',
				},
				{
					_id: '5fc0e0070711927cc5923cd5',
					size: '3XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2443X',
				},
				{
					_id: '5fc0e0070711927cc5923cd6',
					size: '4XL',
					color: 'Burgundy',
					hexColor: 'C244',
					articleNumber: 'STTU758C2444X',
				},
				{ _id: '5fc0e0070711927cc5923cd7', size: 'XS', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C004XS' },
				{ _id: '5fc0e0070711927cc5923cd8', size: 'S', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0041S' },
				{ _id: '5fc0e0070711927cc5923cd9', size: 'M', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0041M' },
				{ _id: '5fc0e0070711927cc5923cda', size: 'L', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0041L' },
				{ _id: '5fc0e0070711927cc5923cdb', size: 'XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0041X' },
				{ _id: '5fc0e0070711927cc5923cdc', size: 'XXL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0042X' },
				{ _id: '5fc0e0070711927cc5923cdd', size: '3XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0043X' },
				{ _id: '5fc0e0070711927cc5923cde', size: '4XL', color: 'Red', hexColor: 'C004', articleNumber: 'STTU758C0044X' },
				{
					_id: '5fc0e0070711927cc5923cdf',
					size: 'XXS',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7272S',
				},
				{
					_id: '5fc0e0070711927cc5923ce0',
					size: 'XS',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C727XS',
				},
				{
					_id: '5fc0e0070711927cc5923ce1',
					size: 'S',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7271S',
				},
				{
					_id: '5fc0e0070711927cc5923ce2',
					size: 'M',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7271M',
				},
				{
					_id: '5fc0e0070711927cc5923ce3',
					size: 'L',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7271L',
				},
				{
					_id: '5fc0e0070711927cc5923ce4',
					size: 'XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7271X',
				},
				{
					_id: '5fc0e0070711927cc5923ce5',
					size: 'XXL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7272X',
				},
				{
					_id: '5fc0e0070711927cc5923ce6',
					size: '3XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7273X',
				},
				{
					_id: '5fc0e0070711927cc5923ce7',
					size: '4XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7274X',
				},
				{
					_id: '5fc0e0070711927cc5923ce8',
					size: '5XL',
					color: 'French Navy',
					hexColor: 'C727',
					articleNumber: 'STTU758C7275X',
				},
				{
					_id: '5fc0e0070711927cc5923ce9',
					size: 'XS',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C658XS',
				},
				{
					_id: '5fc0e0070711927cc5923cea',
					size: 'S',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6581S',
				},
				{
					_id: '5fc0e0070711927cc5923ceb',
					size: 'M',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6581M',
				},
				{
					_id: '5fc0e0070711927cc5923cec',
					size: 'L',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6581L',
				},
				{
					_id: '5fc0e0070711927cc5923ced',
					size: 'XL',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6581X',
				},
				{
					_id: '5fc0e0070711927cc5923cee',
					size: 'XXL',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6582X',
				},
				{
					_id: '5fc0e0070711927cc5923cef',
					size: '3XL',
					color: 'Dark Heather Indigo',
					hexColor: 'C658',
					articleNumber: 'STTU758C6583X',
				},
				{
					_id: '5fc0e0070711927cc5923cf0',
					size: 'XS',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C230XS',
				},
				{
					_id: '5fc0e0070711927cc5923cf1',
					size: 'S',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2301S',
				},
				{
					_id: '5fc0e0070711927cc5923cf2',
					size: 'M',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2301M',
				},
				{
					_id: '5fc0e0070711927cc5923cf3',
					size: 'L',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2301L',
				},
				{
					_id: '5fc0e0070711927cc5923cf4',
					size: 'XL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2301X',
				},
				{
					_id: '5fc0e0070711927cc5923cf5',
					size: 'XXL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2302X',
				},
				{
					_id: '5fc0e0070711927cc5923cf6',
					size: '3XL',
					color: 'Royal Blue',
					hexColor: 'C230',
					articleNumber: 'STTU758C2303X',
				},
				{ _id: '5fc0e0070711927cc5923cf7', size: 'XS', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C231XS' },
				{ _id: '5fc0e0070711927cc5923cf8', size: 'S', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2311S' },
				{ _id: '5fc0e0070711927cc5923cf9', size: 'M', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2311M' },
				{ _id: '5fc0e0070711927cc5923cfa', size: 'L', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2311L' },
				{ _id: '5fc0e0070711927cc5923cfb', size: 'XL', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2311X' },
				{ _id: '5fc0e0070711927cc5923cfc', size: 'XXL', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2312X' },
				{ _id: '5fc0e0070711927cc5923cfd', size: '3XL', color: 'Azur', hexColor: 'C231', articleNumber: 'STTU758C2313X' },
				{
					_id: '5fc0e0070711927cc5923cfe',
					size: 'XS',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C232XS',
				},
				{
					_id: '5fc0e0070711927cc5923cff',
					size: 'S',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2321S',
				},
				{
					_id: '5fc0e0070711927cc5923d00',
					size: 'M',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2321M',
				},
				{
					_id: '5fc0e0070711927cc5923d01',
					size: 'L',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2321L',
				},
				{
					_id: '5fc0e0070711927cc5923d02',
					size: 'XL',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2321X',
				},
				{
					_id: '5fc0e0070711927cc5923d03',
					size: 'XXL',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2322X',
				},
				{
					_id: '5fc0e0070711927cc5923d04',
					size: '3XL',
					color: 'Sky blue',
					hexColor: 'C232',
					articleNumber: 'STTU758C2323X',
				},
				{ _id: '5fc0e0070711927cc5923d05', size: 'XS', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU758C223XS' },
				{ _id: '5fc0e0070711927cc5923d06', size: 'S', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU758C2231S' },
				{ _id: '5fc0e0070711927cc5923d07', size: 'M', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU758C2231M' },
				{ _id: '5fc0e0070711927cc5923d08', size: 'L', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU758C2231L' },
				{ _id: '5fc0e0070711927cc5923d09', size: 'XL', color: 'Khaki', hexColor: 'C223', articleNumber: 'STTU758C2231X' },
				{
					_id: '5fc0e0070711927cc5923d0a',
					size: 'XXL',
					color: 'Khaki',
					hexColor: 'C223',
					articleNumber: 'STTU758C2232X',
				},
				{
					_id: '5fc0e0070711927cc5923d0b',
					size: '3XL',
					color: 'Khaki',
					hexColor: 'C223',
					articleNumber: 'STTU758C2233X',
				},
				{
					_id: '5fc0e0070711927cc5923d0c',
					size: 'XS',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C224XS',
				},
				{
					_id: '5fc0e0070711927cc5923d0d',
					size: 'S',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2241S',
				},
				{
					_id: '5fc0e0070711927cc5923d0e',
					size: 'M',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2241M',
				},
				{
					_id: '5fc0e0070711927cc5923d0f',
					size: 'L',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2241L',
				},
				{
					_id: '5fc0e0070711927cc5923d10',
					size: 'XL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2241X',
				},
				{
					_id: '5fc0e0070711927cc5923d11',
					size: 'XXL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2242X',
				},
				{
					_id: '5fc0e0070711927cc5923d12',
					size: '3XL',
					color: 'Bottle Green',
					hexColor: 'C224',
					articleNumber: 'STTU758C2243X',
				},
				{
					_id: '5fc0e0070711927cc5923d13',
					size: 'XS',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C029XS',
				},
				{
					_id: '5fc0e0070711927cc5923d14',
					size: 'S',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0291S',
				},
				{
					_id: '5fc0e0070711927cc5923d15',
					size: 'M',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0291M',
				},
				{
					_id: '5fc0e0070711927cc5923d16',
					size: 'L',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0291L',
				},
				{
					_id: '5fc0e0070711927cc5923d17',
					size: 'XL',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0291X',
				},
				{
					_id: '5fc0e0070711927cc5923d18',
					size: 'XXL',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0292X',
				},
				{
					_id: '5fc0e0070711927cc5923d19',
					size: '3XL',
					color: 'Varsity Green',
					hexColor: 'C029',
					articleNumber: 'STTU758C0293X',
				},
				{
					_id: '5fc0e0070711927cc5923d1a',
					size: 'XS',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C204XS',
				},
				{
					_id: '5fc0e0070711927cc5923d1b',
					size: 'S',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2041S',
				},
				{
					_id: '5fc0e0070711927cc5923d1c',
					size: 'M',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2041M',
				},
				{
					_id: '5fc0e0070711927cc5923d1d',
					size: 'L',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2041L',
				},
				{
					_id: '5fc0e0070711927cc5923d1e',
					size: 'XL',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2041X',
				},
				{
					_id: '5fc0e0070711927cc5923d1f',
					size: 'XXL',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2042X',
				},
				{
					_id: '5fc0e0070711927cc5923d20',
					size: '3XL',
					color: 'Spectra Yellow',
					hexColor: 'C204',
					articleNumber: 'STTU758C2043X',
				},
				{
					_id: '5fc0e0070711927cc5923d21',
					size: 'XS',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C504XS',
				},
				{
					_id: '5fc0e0070711927cc5923d22',
					size: 'S',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5041S',
				},
				{
					_id: '5fc0e0070711927cc5923d23',
					size: 'M',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5041M',
				},
				{
					_id: '5fc0e0070711927cc5923d24',
					size: 'L',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5041L',
				},
				{
					_id: '5fc0e0070711927cc5923d25',
					size: 'XL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5041X',
				},
				{
					_id: '5fc0e0070711927cc5923d26',
					size: 'XXL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5042X',
				},
				{
					_id: '5fc0e0070711927cc5923d27',
					size: '3XL',
					color: 'Vintage White',
					hexColor: 'C504',
					articleNumber: 'STTU758C5043X',
				},
				{
					_id: '5fc0e0070711927cc5923d28',
					size: 'XXS',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU758C0012S',
				},
				{ _id: '5fc0e0070711927cc5923d29', size: 'XS', color: 'White', hexColor: 'C001', articleNumber: 'STTU758C001XS' },
				{ _id: '5fc0e0070711927cc5923d2a', size: 'S', color: 'White', hexColor: 'C001', articleNumber: 'STTU758C0011S' },
				{ _id: '5fc0e0070711927cc5923d2b', size: 'M', color: 'White', hexColor: 'C001', articleNumber: 'STTU758C0011M' },
				{ _id: '5fc0e0070711927cc5923d2c', size: 'L', color: 'White', hexColor: 'C001', articleNumber: 'STTU758C0011L' },
				{ _id: '5fc0e0070711927cc5923d2d', size: 'XL', color: 'White', hexColor: 'C001', articleNumber: 'STTU758C0011X' },
				{
					_id: '5fc0e0070711927cc5923d2e',
					size: 'XXL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU758C0012X',
				},
				{
					_id: '5fc0e0070711927cc5923d2f',
					size: '3XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU758C0013X',
				},
				{
					_id: '5fc0e0070711927cc5923d30',
					size: '4XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU758C0014X',
				},
				{
					_id: '5fc0e0070711927cc5923d31',
					size: '5XL',
					color: 'White',
					hexColor: 'C001',
					articleNumber: 'STTU758C0015X',
				},
				{
					_id: '5fc0e0070711927cc5923d32',
					size: 'XXS',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2502S',
				},
				{
					_id: '5fc0e0070711927cc5923d33',
					size: 'XS',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C250XS',
				},
				{
					_id: '5fc0e0070711927cc5923d34',
					size: 'S',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2501S',
				},
				{
					_id: '5fc0e0070711927cc5923d35',
					size: 'M',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2501M',
				},
				{
					_id: '5fc0e0070711927cc5923d36',
					size: 'L',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2501L',
				},
				{
					_id: '5fc0e0070711927cc5923d37',
					size: 'XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2501X',
				},
				{
					_id: '5fc0e0070711927cc5923d38',
					size: 'XXL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2502X',
				},
				{
					_id: '5fc0e0070711927cc5923d39',
					size: '3XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2503X',
				},
				{
					_id: '5fc0e0070711927cc5923d3a',
					size: '4XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2504X',
				},
				{
					_id: '5fc0e0070711927cc5923d3b',
					size: '5XL',
					color: 'Heather Grey',
					hexColor: 'C250',
					articleNumber: 'STTU758C2505X',
				},
				{
					_id: '5fc0e0070711927cc5923d3c',
					size: 'XXS',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2532S',
				},
				{
					_id: '5fc0e0070711927cc5923d3d',
					size: 'XS',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C253XS',
				},
				{
					_id: '5fc0e0070711927cc5923d3e',
					size: 'S',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2531S',
				},
				{
					_id: '5fc0e0070711927cc5923d3f',
					size: 'M',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2531M',
				},
				{
					_id: '5fc0e0070711927cc5923d40',
					size: 'L',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2531L',
				},
				{
					_id: '5fc0e0070711927cc5923d41',
					size: 'XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2531X',
				},
				{
					_id: '5fc0e0070711927cc5923d42',
					size: 'XXL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2532X',
				},
				{
					_id: '5fc0e0070711927cc5923d43',
					size: '3XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2533X',
				},
				{
					_id: '5fc0e0070711927cc5923d44',
					size: '4XL',
					color: 'Anthracite',
					hexColor: 'C253',
					articleNumber: 'STTU758C2534X',
				},
				{
					_id: '5fc0e0070711927cc5923d45',
					size: 'XXS',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6512S',
				},
				{
					_id: '5fc0e0070711927cc5923d46',
					size: 'XS',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C651XS',
				},
				{
					_id: '5fc0e0070711927cc5923d47',
					size: 'S',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6511S',
				},
				{
					_id: '5fc0e0070711927cc5923d48',
					size: 'M',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6511M',
				},
				{
					_id: '5fc0e0070711927cc5923d49',
					size: 'L',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6511L',
				},
				{
					_id: '5fc0e0070711927cc5923d4a',
					size: 'XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6511X',
				},
				{
					_id: '5fc0e0070711927cc5923d4b',
					size: 'XXL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6512X',
				},
				{
					_id: '5fc0e0070711927cc5923d4c',
					size: '3XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6513X',
				},
				{
					_id: '5fc0e0070711927cc5923d4d',
					size: '4XL',
					color: 'Dark Heather Grey',
					hexColor: 'C651',
					articleNumber: 'STTU758C6514X',
				},
				{
					_id: '5fc0e0070711927cc5923d4e',
					size: 'XXS',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU758C0022S',
				},
				{ _id: '5fc0e0070711927cc5923d4f', size: 'XS', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C002XS' },
				{ _id: '5fc0e0070711927cc5923d50', size: 'S', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C0021S' },
				{ _id: '5fc0e0070711927cc5923d51', size: 'M', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C0021M' },
				{ _id: '5fc0e0070711927cc5923d52', size: 'L', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C0021L' },
				{ _id: '5fc0e0070711927cc5923d53', size: 'XL', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C0021X' },
				{
					_id: '5fc0e0070711927cc5923d54',
					size: 'XXL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU758C0022X',
				},
				{
					_id: '5fc0e0070711927cc5923d55',
					size: '3XL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU758C0023X',
				},
				{
					_id: '5fc0e0070711927cc5923d56',
					size: '4XL',
					color: 'Black',
					hexColor: 'C002',
					articleNumber: 'STTU758C0024X',
				},
				{ _id: '5fc0e0070711927cc5923d57', size: '5XL', color: 'Black', hexColor: 'C002', articleNumber: 'STTU758C0025X' },
			],
			'purchasingPrice<10': 3,
			'purchasingPrice>10': 3,
			'purchasingPrice>50': 3,
			'purchasingPrice>100': 3,
			'purchasingPrice>250': 3,
			'purchasingPrice>500': 3,
			'purchasingPrice>1000': 2,
			piecesPerBox: 100,
			productionCountry: 'BD',
			hsCode: '61091000',
			weight: 0,
			mainImage: 'https://res.cloudinary.com/www-stanleystella-com/t_pim/TechnicalNames/PFM0_STTU758_C013.jpg',
			createdAt: '2020-11-27T11:16:23.723Z',
			updates: [],
			__v: 0,
			productType: 'shirt',
			visible: true,
			sortIndex: 9,
		},
		internalId: '5fc0e0070711927cc5923c9b',
		externalId: 'STTU758',
		shop: 'stanley-stella',
		name: 'Rocker',
		type: 'shirt',
		colors: [
			{ _id: '5fc0e0070711927cc5923ca6', value: 'Bright Orange', hexValue: 'C013' },
			{ _id: '5fc0e0070711927cc5923ca7', value: 'Cotton Pink', hexValue: 'C005' },
			{ _id: '5fc0e0070711927cc5923ca8', value: 'Plum', hexValue: 'C241' },
			{ _id: '5fc0e0070711927cc5923ca9', value: 'Burgundy', hexValue: 'C244' },
			{ _id: '5fc0e0070711927cc5923caa', value: 'Red', hexValue: 'C004' },
			{ _id: '5fc0e0070711927cc5923cab', value: 'French Navy', hexValue: 'C727' },
			{ _id: '5fc0e0070711927cc5923cac', value: 'Dark Heather Indigo', hexValue: 'C658' },
			{ _id: '5fc0e0070711927cc5923cad', value: 'Royal Blue', hexValue: 'C230' },
			{ _id: '5fc0e0070711927cc5923cae', value: 'Azur', hexValue: 'C231' },
			{ _id: '5fc0e0070711927cc5923caf', value: 'Sky blue', hexValue: 'C232' },
			{ _id: '5fc0e0070711927cc5923cb0', value: 'Khaki', hexValue: 'C223' },
			{ _id: '5fc0e0070711927cc5923cb1', value: 'Bottle Green', hexValue: 'C224' },
			{ _id: '5fc0e0070711927cc5923cb2', value: 'Varsity Green', hexValue: 'C029' },
			{ _id: '5fc0e0070711927cc5923cb3', value: 'Spectra Yellow', hexValue: 'C204' },
			{ _id: '5fc0e0070711927cc5923cb4', value: 'Vintage White', hexValue: 'C504' },
			{ _id: '5fc0e0070711927cc5923cb5', value: 'White', hexValue: 'C001' },
			{ _id: '5fc0e0070711927cc5923cb6', value: 'Heather Grey', hexValue: 'C250' },
			{ _id: '5fc0e0070711927cc5923cb7', value: 'Anthracite', hexValue: 'C253' },
			{ _id: '5fc0e0070711927cc5923cb8', value: 'Dark Heather Grey', hexValue: 'C651' },
			{ _id: '5fc0e0070711927cc5923cb9', value: 'Black', hexValue: 'C002' },
		],
		variants: {
			0: {
				hexColor: '',
				color: 'Azur',
				approved: false,
				finishes: { 0: { type: 'single', colors: [{ hexColor: '#000000' }] } },
				sizes: [
					{ size: 'S', amount: 4 },
					{ size: 'M', amount: 10 },
					{ size: 'L', amount: 10 },
					{ size: 'XL', amount: 5 },
					{ size: 'XXL', amount: 1 },
				],
				extractedHexColor: '#5ba0c9',
			},
		},
		selectedSizes: ['S', 'M', 'L', 'XL', 'XXL'],
		finishes: {
			0: {
				technique: 'print',
				techniqueType: '',
				fileName: 'dummy_design_1_1237892374925',
				editId: null,
				size: { width: 18.1, height: 0 },
				sizeMeasure: 'width',
				side: 'front',
				position: 'custom-front',
				coords: { x: 17.8, y: 25.4 },
				imageWidth: 0,
				imageHeight: 0,
				idx: '0',
			},
		},
		addons: [],
	},
}
