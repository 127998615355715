export default {
	type: 'object',
	properties: {
		// hexColor: { type: 'string', isNotEmpty: true }, // do we still need this
		color: { type: 'string', isNotEmpty: true },
		extractedHexColor: { type: 'string', isNotEmpty: true },
		approved: { type: 'boolean' },
		finishes: {
			type: 'object',
			patternProperties: {
				'^[0-9]+$': {
					// allow dynamic keys only `integer`
					type: 'object',
					properties: {
						type: {
							type: 'string',
							isNotEmpty: true,
							oneOf: [{ const: 'single' }, { const: 'multiple' }, { const: 'recommendation' }],
						},
						colors: {
							type: 'array',
							items: {
								hexColor: { type: 'string', isNotEmpty: true },
								required: ['hexColor'],
							},
						},
					},
					required: ['type', 'colors'],
				},
			},
			additionalProperties: false,
		},
		selectedSizes: {
			type: 'array',
		},
		sizes: {
			type: 'array',
			items: {
				size: { type: 'string', isNotEmpty: true },
				amount: { type: 'integer', minimum: 1 },
				required: ['size', 'amount'],
			},
		},
	},
	required: ['color', 'extractedHexColor', 'finishes', 'sizes', 'selectedSizes'],
	errorMessage: {
		type: 'Deine Variante ist leider nicht valide.',
		properties: {
			color: 'Farbe muss ausgewählt sein.',
			extractedHexColor: 'Farbe muss ausgewählt sein.',
			finishes: 'Motivfarbe wird benötigt.',
			sizes: 'Größen werden benötigt.',
			selectedSizes: 'Ausgewählte Größen werden benötigt.',
		},
	},
}
