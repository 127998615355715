<template>
	<v-app>
		<v-main>
			<v-sheet color="transparent" height="calc(100vh -)" id="canvas-wrapper">
				<MobileBlocker />
				<Cookie />
				<router-view></router-view>
			</v-sheet>
		</v-main>
	</v-app>
</template>

<script>
import Cookie from './partials/Cookie'
import MobileBlocker from './partials/MobileBlocker'

export default {
	name: 'CanvasLayout',
	components: { Cookie, MobileBlocker },
}
</script>

<style scoped lang="sass">
#canvas-wrapper
	// background-image: radial-gradient(rgba(0,0,0,0.15) 10%, #fff 9%)
	background-position: 0 0
	background-size: 20px 20px
	background-repeat: repeat
</style>
