<template>
	<div>
		<Cookie />
		<MobileBlocker />
		<router-view></router-view>
		<SimpleFooter />
	</div>
</template>

<script>
import Cookie from './partials/Cookie'
import MobileBlocker from './partials/MobileBlocker'
import SimpleFooter from './partials/SimpleFooter'

export default {
	name: 'BlankLayout',
	components: { Cookie, MobileBlocker, SimpleFooter },
}
</script>
<style scoped lang="sass">
</style>