export const createUuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

export const sortSizes = (sizes) => {
	const SIZE_ORDER = [
		'ONESIZE',
		'9XS',
		'8XS',
		'7XS',
		'6XS',
		'5XS',
		'4XS',
		'3XS',
		'XXS',
		'XS',
		'S',
		'M',
		'L',
		'XL',
		'XXL',
		'3XL',
		'4XL',
		'5XL',
		'6XL',
		'7XL',
		'8XL',
		'9XL',
	]
	return sizes.sort(function (a, b) {
		const aSize = a.size
		const bSize = b.size
		return SIZE_ORDER.indexOf(aSize) - SIZE_ORDER.indexOf(bSize)
	})
}

import { textileValidate } from '@/assets/data/validationSchemes'
import { finishValidate } from '@/assets/data/validationSchemes'
import { variantValidate } from '@/assets/data/validationSchemes'

export const validateStep = (step, data) => {
	console.log(data)
	console.log(data.selectedSizes)

	let stepValid = true

	if (step === 'textile') {
		console.log('Validating textile!')
		const textileData = {
			productId: data.productId,
			product: { ...data.product },
			colors: [...data.colors],
			variants: { ...data.variants },
			// selectedSizes: [...data.selectedSizes],
		}

		if (Object.keys(textileData).length === 0) {
			stepValid = false
			console.log('--- FEHLER ---')
			console.log('kein Textil vorhanden')
			console.log('--- ------ ---')
		}

		// console.log(textileData)

		const validate = textileValidate()
		const valid = validate(textileData)
		if (!valid) {
			// console.log(validate.errors)
			const printErrors = validate.errors.filter((e) => e.instancePath !== '')
			// const printErrors = validate.errors
			let errorString = ''

			console.log('--- FEHLER ---')
			printErrors.forEach((e) => {
				console.log(e.instancePath + ' ' + e.message)
				errorString += e.message + '\n'
			})
			console.log(errorString)
			console.log('--- ------ ---')

			stepValid = false
		}
	} else if (step === 'motive') {
		console.log('Validating motive!')
		const finishes = { ...data.finishes }

		if (Object.keys(finishes).length === 0) {
			stepValid = false
			console.log('--- FEHLER ---')
			console.log('keine Motive vorhanden')
			console.log('--- ------ ---')
		}

		for (let key in finishes) {
			const finish = finishes[key]
			// console.log(finish)

			const validate = finishValidate()
			const valid = validate(finish)
			if (!valid) {
				// console.log(validate.errors)
				const printErrors = validate.errors.filter((e) => e.instancePath !== '')
				// const printErrors = validate.errors
				let errorString = ''

				console.log('--- FEHLER ---')
				printErrors.forEach((e) => {
					console.log(e.instancePath + ' ' + e.message)
					errorString += e.message + '\n'
				})
				console.log(errorString)
				console.log('--- ------ ---')

				stepValid = false
			}
		}
	} else if (step === 'variants') {
		console.log('Validating variants!')
		const variants = { ...data.variants }

		if (Object.keys(variants).length === 0) {
			stepValid = false
			console.log('--- FEHLER ---')
			console.log('keine Varianten vorhanden')
			console.log('--- ------ ---')
		}

		for (let key in variants) {
			const variant = variants[key]

			const validate = variantValidate()
			const valid = validate(variant)

			if (!valid) {
				// console.log(validate.errors)
				const printErrors = validate.errors.filter((e) => e.instancePath !== '')
				// const printErrors = validate.errors
				let errorString = ''

				console.log('--- FEHLER ---')
				printErrors.forEach((e) => {
					console.log(e.instancePath + ' ' + e.message)
					errorString += e.message + '\n'
				})
				console.log(errorString)
				console.log('--- ------ ---')

				stepValid = false
			}
		}
	} else if (step === 'sizes') {
		console.log('Validating sizes!')
	}

	return stepValid
}
