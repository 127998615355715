import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/dictionary'

const getAll = () => {
	return httpClient.get(END_POINT)
}

const getById = id => {
	return httpClient.get(END_POINT + '/' + id)
}

const getByModule = module => {
	return httpClient.get(END_POINT + '/module/' + module)
}

const create = data => {
	return httpClient.post(END_POINT, { data })
}

const update = (id, data) => {
	return httpClient.post(END_POINT + '/' + id, { data })
}

const deleteById = id => {
	return httpClient.get(END_POINT + '/' + id + '/delete')
}

export { getAll, getById, getByModule, create, update, deleteById }
