import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/customeraddresses'

const getCustomerAddresses = (customerId) => {
	return httpClient.get(`${END_POINT}/${customerId}`)
}

const newAddress = (data) => {
	return httpClient.post(END_POINT + '/new', { data })
}

const updateUserAddress = (data) => {
	return httpClient.put(END_POINT + '/user-update/' + data._id, { data })
}

const deleteUserAddress = (data) => {
	return httpClient.put(END_POINT + '/user-delete/' + data._id, { data })
}

export { getCustomerAddresses, newAddress, deleteUserAddress, updateUserAddress }
