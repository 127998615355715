import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import Notifications from 'vue-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueFormulate from '@braid/vue-formulate'
import { de } from '@braid/vue-formulate-i18n'
import VueHead from 'vue-head'
import AsyncComputed from 'vue-async-computed'
import VueMultiRef from 'vue-multi-ref'
import { VuePlausible } from 'vue-plausible'
import VueCookie from 'vue-cookie'

import './styles/index.css'
import './styles/configurator/configElements.sass'
import './styles/basic.sass'
import './styles/configurator/previewController.sass'

// plugins
import DictionaryPlugin from './plugins/dictionary'
import FilesPlugin from './plugins/files'

// filters
import './filters/truncateString.js'

import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

const socket = io(process.env.VUE_APP_BASE_WEBSOCKET_URL)
socket.on('connect_error', (err) => {
	console.log(err.message)
})
// console.log(socket)
Vue.config.productionTip = false

Vue.use(DictionaryPlugin)
Vue.use(FilesPlugin, { store, router })

Vue.use(VueSocketIOExt, socket, { store })
Vue.use(Notifications)
Vue.use(VueHead)

Vue.use(VueFormulate, {
	plugins: [de],
	locale: 'de',
})
Vue.use(AsyncComputed)

Vue.use(VuePlausible, {
	plausible: {
		domain: 'wercster.de',
		trackLocalhost: true,
	},
})

Vue.$plausible.enableAutoPageviews() // optional

Vue.use(VueCookie)

Vue.component('werc-tr', createSimpleTransition('werc-tr'))

Vue.config.ignoredElements = [
	'a-scene',
	'a-entity',
	'a-camera',
	'a-box',
	'a-sky',
	'a-sphere',
	'a-cylinder',
	'a-plane',
	'a-assets',
	'a-asset-item',
	'a-gltf-model',
]

Vue.use(VueMultiRef)

// fetch dictionary before mounting app
// store.dispatch('Dictionary/fetchReadableByModule', 'configurator').then(() => {
window.eventBus = new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
// })
