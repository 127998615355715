import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/product-categories'

const getAll = () => {
	return httpClient.get(END_POINT, {})
}

const getByCode = (code) => {
	return httpClient.get(END_POINT + code)
}

export { getAll, getByCode }
