<template>
	<v-app v-if="dictFetched">
		<notifications group="main" />
		<router-view />
	</v-app>
</template>

<script>
// import { getSession } from '@/api/payments.api'

export default {
	name: 'App',
	data() {
		return {
			dictFetched: false,
		}
	},
	created() {
		this.$store.dispatch('Dictionary/fetchReadableByModule', 'configurator').then(() => {
			this.dictFetched = true
		})

		this.$store.dispatch('Auth/checkForLoggedInUser')
	},
	mounted() {
		// import vibrantjs script
		let vibrantScript = document.createElement('script')
		vibrantScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/node-vibrant@3.2.1-alpha.1/dist/vibrant.min.js')
		document.head.appendChild(vibrantScript)

		// import vibrantjs script
		let colorThiefScript = document.createElement('script')
		colorThiefScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/color-thief/2.3.0/color-thief.umd.js')
		document.head.appendChild(colorThiefScript)
	},
}
</script>
