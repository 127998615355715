import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/utils'

const extractColors = (imageUrl) => {
	return httpClient.post(END_POINT + '/extract-colors', { imageUrl })
}

const urlExists = (url) => {
	return httpClient.post(END_POINT + '/url-exists', { url })
}

const uploadFile = (file) => {
	var formData = new FormData()
	formData.append('file', file)
	return httpClient.post(process.env.VUE_APP_BASE_URL + '/files/upload', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

const uploadAccountFile = (file) => {
	var formData = new FormData()
	formData.append('file', file)
	return httpClient.post(process.env.VUE_APP_BASE_URL + '/files/account/upload', formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

const deleteFile = (fileId) => {
	return httpClient.delete(process.env.VUE_APP_BASE_URL + '/files/' + fileId)
}

const fetchFiles = (fileIdArray) => {
	let query = ''
	if (fileIdArray) fileIdArray.forEach((id) => (query.length > 0 ? `&fileId=${id}` : `?fileId=${id}`))
	return httpClient.get(process.env.VUE_APP_BASE_URL + '/files' + query)
}

const fetchFilesByCustomer = (customerId) => {
	return httpClient.get(process.env.VUE_APP_BASE_URL + '/files?customerId=' + customerId)
}

const fetchFilesByRequest = (requestId) => {
	return httpClient.get(process.env.VUE_APP_BASE_URL + '/files?requestId=' + requestId)
}

const convertImage = () => {
	// @TODO: https://cloudconvert.com/pricing
}

export {
	extractColors,
	convertImage,
	urlExists,
	uploadFile,
	uploadAccountFile,
	deleteFile,
	fetchFiles,
	fetchFilesByCustomer,
	fetchFilesByRequest,
}
