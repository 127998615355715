import { render, staticRenderFns } from "./Cookie.vue?vue&type=template&id=5e4089a8&scoped=true&"
import script from "./Cookie.vue?vue&type=script&lang=js&"
export * from "./Cookie.vue?vue&type=script&lang=js&"
import style0 from "./Cookie.vue?vue&type=style&index=0&id=5e4089a8&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4089a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VSheet})
