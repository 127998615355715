export default {
	type: 'object',
	properties: {
		productId: { type: 'string', isNotEmpty: true },
		product: { type: 'object' },
		colors: { type: 'array' },
		variants: { type: 'object' },
		// selectedSizes: { type: 'array' },
	},

	required: ['productId', 'product', 'colors', 'variants'],
	errorMessage: {
		type: 'Dein Textil ist leider nicht valide.',
		properties: {
			productId: 'Produkt muss ausgewählt sein.',
			product: 'Produkt muss ausgewählt sein.',
			colors: 'Produkt muss ausgewählt sein.',
			variants: 'Produkt muss ausgewählt sein.',
			selectedSizes: 'Produkt muss ausgewählt sein.',
		},
	},
	additionalProperties: true,
}
