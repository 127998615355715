<template>
	<div class="mobile-blocker justify-center items-center">
		<div class="outer"></div>
		<div class="inner">
			<h1>🥺</h1>
			Currently not supported on mobile
		</div>
	</div>
</template>



<script>
export default {
	name: 'mobileBlocker',
}
</script>

<style lang="sass" scoped>
.mobile-blocker
	position: fixed
	height: 100vh
	width: 100vw
	top: 0
	left: 0
	display: inline-flex
	background: white
	z-index: 111111111111111
	opacity: 0
	pointer-events: none
	@media screen and (max-width: 768px)
		opacity: 1
		pointer-events: initial
		.outer
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			background-image: radial-gradient(rgba(0, 0, 0, 0.15) 10%, #fff 9%)
			background-position: 0 0
			background-size: 20px 20px
			background-repeat: repeat

			z-index: 1
		.inner
			font-size: 1.4em
			font-weight: 700
			letter-spacing: -0.05em
			z-index: 3
			text-align: center
			h1
				font-size: 2em
</style>