import { getField, updateField } from 'vuex-map-fields'
import { sortSizes, createUuid } from '../../utils/helpers'

import PositionSettings from '@/assets/data/finishSettings.js'
import Templates from '@/assets/data/templates.js'

const MAX_ALLOWED_FINISHES = 6

const state = {
	// PRODUCT
	category: 'shirt',
	product: null, // full object of selected product
	productId: null, // only id of selected product, @TODO: do we need this?
	colors: [], // array of colors?
	editId: null, // indicates if product is already in customerRequest

	// FINISHES
	selectedFinishId: -1,
	finishes: {},
	finishTooBig: false,

	// VARIANTS
	selectedVariantId: -1,
	variants: {},

	// SIZES
	// selectedSizes: [], // array of size strings

	// CUSTOM PRODUCT
	customProductDescription: null,

	// REQUEST
	customerRequest: {
		configurations: [],
	},
}

const getters = {
	getField,
	getSelectedProductId: (state) => {
		return state.productId
	},
	// @TODO: remove this and replace it with currentProduct field in components
	getCurrentProduct: (state) => {
		return state.currentProduct
	},
}

const updateCustomerRequest = (state, commit) => {
	if (state.editId) {
		// console.log('UPDATE CUSTOMER REQUEST -- ', state.editId)
		commit('UPDATE_CONFIGURATION_TO_CUSTOMER_REQUEST')
	}
}

const actions = {
	resetEverything: ({ commit, dispatch }) => {
		dispatch('ConfiguratorDisplay/setStep', 'textile', { root: true })
		dispatch('ConfiguratorFiles/resetFiles', null, { root: true })
		dispatch('ConfiguratorRequest/resetCustomer', null, { root: true })
		commit('RESET_CUSTOMER_REQUEST')
		commit('RESET_CONFIGURATION')
	},
	/**
	 * Configuration actions
	 */
	resetConfiguration: ({ commit, dispatch }) => {
		dispatch('ConfiguratorDisplay/setStep', 'textile', { root: true })
		commit('RESET_CONFIGURATION')
	},
	setConfigurationFromCustomerRequest: ({ commit }, configurationEditId) => {
		commit('SET_CONFIGURATION_FROM_CUSTOMER_REQUEST_TO_STATE', configurationEditId)
		// // load files
		// const configuration = state.customerRequest.configurations.find((c) => c.editId === configurationEditId)
		// dispatch('ConfiguratorFiles/fetchFiles', configuration.files, { root: true })
	},
	removeConfigurationFromCustomerRequest: ({ commit, state, dispatch }, configurationEditId) => {
		commit('REMOVE_CONFIGURATION_FROM_CUSTOMER_REQUEST', configurationEditId)
		if (state.editId === configurationEditId) {
			dispatch('ConfiguratorDisplay/setStep', 'textile', { root: true })
			commit('RESET_CONFIGURATION')
		}
	},
	setConfigurationFromTemplateToCustomerRequest: ({ commit, dispatch }, templateName) => {
		if (Templates[templateName]) {
			commit('SET_CONFIGURATION_FROM_TEMPLATE_TO_CUSTOMER_REQUESTS', Templates[templateName])
			// load files
			dispatch('ConfiguratorFiles/fetchFiles', Templates[templateName].files, { root: true })
		}
	},
	completeConfiguration: ({ commit, state }) => {
		if (!state.editId) {
			commit('ADD_CONFIGURATION_TO_CUSTOMER_REQUEST')
		}
	},
	/**
	 * Sets the product based on the product id
	 * @param {String} productId ID of selected product
	 */
	setProduct: ({ commit, rootState, state, dispatch }, productId) => {
		const product = rootState.InternalProducts.internalProducts.find((p) => p._id === productId)

		if (product) {
			commit('SET_PRODUCT', product)
			commit('CHECK_FINISH_COMPATIBILITY')
			updateCustomerRequest(state, commit)
		} else {
			dispatch('ConfiguratorDisplay/setError', { message: 'Produkt nicht gefunden.' }, { root: true })
		}

		return product ? product._id : null
	},
	/**
	 * Finish actions
	 */
	selectFinish: ({ commit }, finishId) => {
		commit('SELECT_FINISH', finishId)
	},
	addFinish: ({ commit, state, dispatch }) => {
		if (Object.keys(state.finishes).length < MAX_ALLOWED_FINISHES) {
			commit('ADD_FINISH')
			updateCustomerRequest(state, commit)
		} else {
			dispatch('ConfiguratorDisplay/setError', { message: 'Maximal Anzahl an Veredelungen erreicht.' }, { root: true })
		}
	},
	updateFinish: ({ commit, state, rootState }, { key, value }) => {
		if (key === 'fileName') {
			commit('UPDATE_FINISH', { key, value, files: rootState['ConfiguratorFiles'].files })
		} else {
			commit('UPDATE_FINISH', { key, value })
		}
		updateCustomerRequest(state, commit)
	},
	deleteFinish: ({ commit, state }, finishId) => {
		commit('DELETE_FINISH', finishId)
		updateCustomerRequest(state, commit)
	},
	checkFinishCompatibility: () => {
		// commit('CHECK_FINISH_COMPATIBILITY')
	},
	/**
	 * Variant actions
	 */
	selectVariant: ({ commit }, variantId) => {
		commit('SELECT_VARIANT', variantId)
	},
	addVariant: ({ commit, state }, type) => {
		commit('ADD_VARIANT', type)
		// commit('UPDATE_SELECTED_SIZES', state.selectedSizes) // add selected sizes to new variant
		updateCustomerRequest(state, commit)
	},
	updateVariant: async ({ commit, state, dispatch }, { key, value }) => {
		if (key === 'color') {
			// if color is added or changed, extract the hex code and save it in the variant object
			if (state.product.shopName === 'stanley-stella') {
				let color
				if (state.product.colors) color = state.product.colors.find((c) => c.value === value)
				else color = state.product.colorVariants.find((c) => c.color === value)
				const blob = color ? color.hexValue : ''
				const imgUrl = `${process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors'}/${blob}.png`

				await dispatch('ConfiguratorFiles/extractColors', imgUrl, { root: true })
					.then((res) => {
						let hexColor = '#c1c1c1'
						if (res.colors && res.colors[0]) hexColor = res.colors[0].hex
						commit('UPDATE_VARIANT', { key: 'extractedHexColor', value: hexColor })
					})
					.catch(() => {
						let hexColor = '#c1c1c1'
						commit('UPDATE_VARIANT', { key: 'extractedHexColor', value: hexColor })
					})
			} else if (state.product.isManualProduct || state.product.shopName === 'l-shop' || state.product.shopName === 'custom') {
				let hexColor = '#c1c1c1'
				let color
				if (state.product.colors) color = state.product.colors.find((c) => c.value === value)
				else color = state.product.colorVariants.find((c) => c.color === value)
				// const color = state.product.colors.find((c) => c.value === value)
				if (color) hexColor = color.hexValue
				commit('UPDATE_VARIANT', { key: 'extractedHexColor', value: '#' + hexColor })
			}
		}
		commit('UPDATE_VARIANT', { key, value })
		updateCustomerRequest(state, commit)
	},
	updateVariantFinishes: ({ commit, state }, { type, comment, variantIdx, finishIdx }) => {
		commit('UPDATE_VARIANT_FINISHES', { type, comment, variantIdx, finishIdx })
		updateCustomerRequest(state, commit)
	},
	addVariantFinishColors: ({ commit, state }, { variantIdx, finishIdx, colors }) => {
		commit('ADD_VARIANT_FINISH_COLORS', { variantIdx, finishIdx, colors })
		updateCustomerRequest(state, commit)
	},
	deleteVariant: ({ commit, state }, variantId) => {
		commit('DELETE_VARIANT', variantId)
		updateCustomerRequest(state, commit)
	},
	/**
	 * Size / Amount actions
	 */
	updateSelectedSizes: ({ commit, state }, { sizes, variant }) => {
		// commit('UPDATE_SELECTED_SIZES', selectedSizes)
		commit('UPDATE_SELECTED_SIZES', { sizes, variant })
		updateCustomerRequest(state, commit)
	},
	updateSizeAmount: ({ commit, state }, { variantIdx, size, newAmount }) => {
		commit('UPDATE_SIZE_AMOUNT', { variantIdx, size, newAmount })
		updateCustomerRequest(state, commit)
	},
}

const mutations = {
	updateField,

	// REQUEST
	RESET_CUSTOMER_REQUEST: function (state) {
		// REQUEST
		state.customerRequest = {
			configurations: [],
			address: {
				firstName: '',
				lastName: '',
				email: '',
			},
		}
	},

	// CONFIGURATION
	RESET_CONFIGURATION: function (state) {
		// PRODUCT
		state.category = 'shirt'
		state.product = null
		state.productId = null
		state.colors = []
		state.editId = null

		// FINISHES
		state.selectedFinishId = -1
		state.finishes = {}

		// VARIANTS
		state.selectedVariantId = -1
		state.variants = {}

		// SIZES
		state.variantSizes = {}
		// state.selectedSizes = []

		// CUSTOM PRODUCT
		state.customProductDescription = null
	},

	// PRODUCT
	SET_PRODUCT: function (state, product) {
		// colors
		// state.colors = product.colors
		state.colors = product.colors.map((c) => c.value)

		// reset variants
		state.variants = {}

		// completely reset the variants and add the first one
		// state.variants = {
		// 0: {
		// 	hexColor: product.colors[0].hexValue,
		// 	color: product.colors[0].value,
		// 	approved: false,
		// 	finishes: {},
		// 	sizes: [],
		// },
		// }

		// check if there are finishes, if so add those to the variants
		// if (Object.keys(state.finishes).length > 0) {
		// 	for (let key in state.finishes) {
		// 		state.variants[0].finishes[key] = {
		// 			type: 'single',
		// 			colors: [
		// 				{
		// 					hexColor: '#000000',
		// 				},
		// 			],
		// 		}
		// 	}
		// }

		// reset selected sizes
		// state.selectedSizes = []

		// reset selected finish/variant
		state.selectedFinishId = -1
		state.selectedVariantId = -1

		// set full project
		state.productId = product._id
		state.product = product
	},

	// FINISH
	SELECT_FINISH: function (state, finishId) {
		state.selectedFinishId = parseInt(finishId)
	},
	ADD_FINISH: function (state) {
		let newFinishId = Object.keys(state.finishes).length - 1 + 1

		// get allowed position based on other finishes
		const allowedPositions = (function () {
			const options = { ...PositionSettings.positionOptions[state.product.productType] }
			for (let key in state.finishes) {
				const finish = state.finishes[key]
				options[finish.side] = options[finish.side].filter((po) => po.value !== finish.position)
			}
			return options
		})()

		console.log(allowedPositions)
		console.log(PositionSettings.sideOptions)
		console.log(PositionSettings.sideOptions[state.product.category])
		const firstKey = Object.keys(PositionSettings.sideOptions[state.product.category])[0]
		console.log(firstKey)
		console.log(PositionSettings.sideOptions[state.product.category][firstKey])

		const allowedSides = (function () {
			let allowedSides = []
			if (!state.finishes || (state.finishes && Object.keys(state.finishes).length === 0)) {
				const firstKey = Object.keys(PositionSettings.sideOptions[state.product.category])[0]
				allowedSides = [...PositionSettings.sideOptions[state.product.category][firstKey]].map((s) => s.value)
			}

			for (let key in state.finishes) {
				const finish = state.finishes[key]
				const techniqueKey = finish.technique + (finish.techniqueType ? '--' + finish.techniqueType : '')
				const availableSides = [...PositionSettings.sideOptions[state.product.category][techniqueKey]]
				console.log(availableSides)
				availableSides.forEach((side) => {
					if (allowedPositions[side.value] && allowedPositions[side.value].length > 0 && !allowedSides.includes(side.value))
						allowedSides.push(side.value)
				})
			}
			return allowedSides
		})()

		console.log(allowedSides)

		/**
		 * use tmp array to update finishes object otherwise the getters will NOT update
		 */
		const tmp = { ...state.finishes }

		// set side
		// find available side from allowed positions array
		let side = null
		if (allowedSides.length > 0) {
			side = allowedSides[0]
		}

		// set position
		// use custom position if its still available, otherwise the first entry in the allowed positions array
		let position = null
		if (allowedPositions[side] && allowedPositions[side].length > 0) {
			const customPos = allowedPositions[side].find((p) => p.value.match(/custom/gi))
			if (customPos) {
				position = customPos.value
			} else if (allowedPositions[side].length > 0) {
				position = allowedPositions[side][0].value
			}
		}

		if (position && side) {
			tmp[newFinishId] = {
				technique: 'print',
				techniqueType: null,
				// color: '',
				fileName: '61c3228b533e2e60e5684116',
				editId: null,
				size: { width: 6, height: 0 },
				sizeMeasure: 'width',
				side,
				position,
				coords: { x: 1, y: 1 },
				imageWidth: 44,
				imageHeight: 41,
				comment: null,
			}

			// if (state.product.productType === 'cap' || state.product.productType === 'cap-strap') {
			if (state.product.productType.match('cap')) {
				tmp[newFinishId].technique = 'stick'
				// tmp[newFinishId].size.width = 5
				tmp[newFinishId].sizeMeasure = 'height'
				tmp[newFinishId].size.height = 3
			}

			state.finishes = tmp
			state.selectedFinishId = newFinishId

			// Add new finish to variants
			const variants = { ...state.variants }
			for (let key in variants) {
				// if (state.product.productType === 'cap' || state.product.productType === 'cap-strap') {
				if (state.product.productType.match('cap')) {
					variants[key].finishes[newFinishId] = {
						// type: 'recommendation',
						type: 'single',
						colors: [
							{
								hexColor: '#000000',
							},
						],
					}
				} else {
					variants[key].finishes[newFinishId] = {
						type: 'single',
						colors: [
							{
								hexColor: '#000000',
							},
						],
					}
				}
			}
			state.variants = variants
		} else {
			// this should not happen
			console.error('IMPOSSIBLE ERROR: No more available Sides or Positions.')
		}
	},
	UPDATE_FINISH: function (state, { key, value, files }) {
		const finishes = { ...state.finishes }
		finishes[state.selectedFinishId][key] = value

		if (key === 'technique') {
			// remove colors, because the pantone colors for stick and print are different
			// udpate variant
			const variants = { ...state.variants }
			for (let key in variants) {
				// variants[key].finishes[state.selectedFinishId].type =
				// 	state.finishes[state.selectedFinishId].technique === 'stick' ? 'recommendation' : 'single'
				variants[key].finishes[state.selectedFinishId].colors = [{ hexColor: '#000000' }]
				// variants[key].finishes[state.selectedFinishId].comment = undefined
			}
			state.variants = variants
		}

		if (key === 'fileName') {
			// update image dimensions in finish
			let fileObj = null
			files.forEach((file) => {
				if (file._id === value) fileObj = file
			})
			if (fileObj) {
				finishes[state.selectedFinishId].imageWidth = fileObj.width
				finishes[state.selectedFinishId].imageHeight = fileObj.height
			}

			// udpate variant
			const variants = { ...state.variants }
			for (let key in variants) {
				variants[key].finishes[state.selectedFinishId].type = 'single'
				// variants[key].finishes[state.selectedFinishId].type = state.finishes[state.selectedFinishId].technique === 'stick' ? 'recommendation' : 'single'
				variants[key].finishes[state.selectedFinishId].colors = [{ hexColor: '#000000' }]
				variants[key].finishes[state.selectedFinishId].comment = undefined
			}
			state.variants = variants
		}

		state.finishes = finishes
	},
	DELETE_FINISH: function (state, finishId) {
		/**
		 * use tmp array to update finishes object otherwise the getters will NOT update
		 */
		const tmp = { ...state.finishes }
		tmp[finishId] = undefined
		let count = 0
		const newFinishes = {}

		/**
		 * Reinit the keys of the object
		 */
		for (let key in tmp) {
			if (tmp[key]) newFinishes[count] = tmp[key] // remove undefined values
			count++
		}
		state.finishes = newFinishes

		// remove finish from variants.finishes
		const tmpVariants = { ...state.variants }
		for (let key in tmpVariants) {
			const variant = tmpVariants[key]
			variant.finishes[finishId] = undefined
			let count = 0
			const newFinishes = {}

			/**
			 * Reinit the keys of the object
			 */
			for (let key in variant.finishes) {
				if (variant.finishes[key]) newFinishes[count] = variant.finishes[key] // remove undefined values
				count++
			}
			variant.finishes = newFinishes
		}
		state.variants = tmpVariants

		// if (state.selectedFinishId === finishId) state.selectedFinishId = Math.max(finishId - 1, 0)
		state.selectedFinishId = -1
	},
	CHECK_FINISH_COMPATIBILITY: function (state) {
		const finishes = { ...state.finishes }

		// get allowed position based on other finishes
		const availablePositions = { ...PositionSettings.positionOptions[state.product.productType] }
		const allowedPositions = (function () {
			const options = { ...PositionSettings.positionOptions[state.product.productType] }
			for (let key in state.finishes) {
				const finish = state.finishes[key]
				if (options[finish.side]) {
					options[finish.side] = options[finish.side].filter((po) => po.value !== finish.position)
				}
			}
			return options
		})()

		for (let key in finishes) {
			const finish = finishes[key]

			if (availablePositions[finish.side]) {
				// if selected position is not available on new product, change to first allowed position
				if (!availablePositions[finish.side].find((p) => p.value === finish.position)) {
					finish.position = allowedPositions[finish.side][0].value
				}

				// always change finish size (to width = 6), otherwise left/right positioned finished could be out of print area
				finish.size = { width: 6 }
				finish.sizeMeasure = 'width'

				// if is cap, change technique to 'stick' and size (width = 3)
				if (state.product.productType.match('cap')) {
					finish.technique = 'stick'
					finish.size[finish.sizeMeasure] = 3
				}

				// if custom, change position and size, because it could be that the selected position is blocked in the new product
				if (finish.position.match('custom')) {
					finish.coords = { x: 0, y: 0 }
				}
			} else {
				// delete finish if the position is not available on the new product
				delete finishes[key]
			}
		}

		state.finishes = { ...finishes }
	},

	// VARIANT
	SELECT_VARIANT: function (state, variantId) {
		state.selectedVariantId = parseInt(variantId)
	},
	ADD_VARIANT: function (state, addVariantType) {
		let newVariant = {
			hexColor: '',
			color: '',
			approved: false,
			finishes: {},
			selectedSizes: [],
			sizes: [],
		}

		// if there are already variants, copy the finish settings (only type and hexColors, not the substitute colors) of those when creating a new variant
		if (Object.keys(state.variants).length === 0) {
			for (let idx = 0; idx < Object.keys(state.finishes).length; idx++) {
				newVariant.finishes[idx] = {
					type: 'single',
					colors: [
						{
							hexColor: '#000000',
						},
					],
				}
			}
		} else {
			// variant is added by user input in the variants step
			if (addVariantType === 'textile-print') {
				const copyIdx = Object.keys(state.variants).length - 1
				// copy variant
				newVariant = { ...state.variants[copyIdx] }
				newVariant.approved = false

				// remove sizes
				// delete newVariant.sizes
				newVariant.selectedSizes = []
				newVariant.sizes = []

				// replace finishes with deep copy of finishes
				newVariant.finishes = {}

				for (let key in state.variants[copyIdx].finishes) {
					const finish = state.variants[copyIdx].finishes[key]

					newVariant.finishes[key] = {
						type: finish.type,
						colors: [],
					}
					for (let color of state.variants[copyIdx].finishes[key].colors) {
						const newColor = { hexColor: color.hexColor }
						if (color.substituteColor) newColor.substituteColor = color.substituteColor
						if (color.substituteRgbColor) newColor.substituteRgbColor = color.substituteRgbColor
						newVariant.finishes[key].colors.push(newColor)
					}
				}
				// variant is added automatically after textile selection
			} else {
				for (let key in state.variants[0].finishes) {
					const finish = state.variants[0].finishes[key]

					newVariant.finishes[key] = {
						type: finish.type,
						colors: [],
					}
					for (let color of state.variants[0].finishes[key].colors) {
						const newColor = { hexColor: color.hexColor }
						newVariant.finishes[key].colors.push(newColor)
					}
				}
			}
		}

		const newVariantId = Object.keys(state.variants).length
		const variants = { ...state.variants }
		variants[newVariantId] = newVariant
		state.variants = variants

		// variant is added automatically after textile selection
		if (!addVariantType) {
			state.selectedVariantId = newVariantId
		}
	},
	UPDATE_VARIANT: function (state, { key, value }) {
		const variants = { ...state.variants }
		if (key === 'finishColor') {
			// update all variants
			// for (let key in variants) {
			// 	variants[key].finishes[value.finishIdx].colors.forEach(c => {
			// 		if (c.hexColor === value.hexColor) c.substituteColor = value.substituteColor
			// 	})
			// }
			// if-statement prevents error by uncancelled proomises from Textile.vue->selectedProductId
			if (state.selectedVariantId > -1) {
				variants[state.selectedVariantId].finishes[value.finishIdx].colors.forEach((c) => {
					if (c.hexColor === value.hexColor) {
						c.substituteColor = value.substituteColor
						c.substituteRgbColor = value.substituteRgbColor
						c.yarnNumber = value.yarnNumber
					}
				})
			}
		} else {
			// if-statement prevents error by uncancelled proomises from Textile.vue->selectedProductId
			if (state.selectedVariantId > -1) {
				variants[state.selectedVariantId][key] = value
			}
		}
		state.variants = variants
	},
	UPDATE_VARIANT_FINISHES: function (state, { type, comment, variantIdx, finishIdx }) {
		if (!variantIdx && variantIdx !== 0) console.log(variantIdx) // hacky

		// console.log(type)

		const variants = { ...state.variants }
		if (type) {
			// update all variants
			for (let key in variants) {
				// console.log(key)
				// console.log(finishIdx)
				variants[key].finishes[finishIdx].type = type
			}
			// variants[variantIdx].finishes[finishIdx].type = type
		}
		if (comment) {
			variants[variantIdx].finishes[finishIdx].comment = comment
			// update all variants
			// for (let key in variants) {
			// 	variants[key].finishes[finishIdx].comment = comment
			// }
		}
		state.variants = { ...variants }
		// console.log(state.variants)
	},
	ADD_VARIANT_FINISH_COLORS: function (state, { variantIdx, finishIdx, colors }) {
		if (!variantIdx && variantIdx) console.log(variantIdx)
		// this mutation is called after the variant color type is changed, to set the extracted colors of the image or black
		const variants = { ...state.variants }
		// update all variants
		for (let key in variants) {
			variants[key].finishes[finishIdx].colors = colors.map((c) => ({
				hexColor: c.hex,
			}))
		}
		// variants[variantIdx].finishes[finishIdx].colors = colors.map(c => ({
		// 	hexColor: c.hex,
		// }))
		state.variants = variants
	},
	DELETE_VARIANT: function (state, variantId) {
		const variants = { ...state.variants }

		delete variants[variantId]
		let count = 0
		const newVariants = {}
		/**
		 * Reinit the keys of the object
		 */
		for (let key in variants) {
			newVariants[count] = variants[key]
			count++
		}
		state.variants = newVariants
		state.selectedVariantId = -1
	},

	// COLORS
	SET_COLORS: function (state, payload) {
		state.colors = payload
	},

	// SIZES
	UPDATE_SELECTED_SIZES: function (state, { sizes, variant }) {
		for (let key in state.variants) {
			const stateVariant = state.variants[key]

			if (stateVariant.hexColor === variant.hexColor) {
				stateVariant.selectedSizes = sortSizes(sizes)
				if (!variant.sizes) variant.sizes = []

				// add sizes
				stateVariant.selectedSizes.forEach((size) => {
					if (!variant.sizes.find((vs) => vs.size === size)) {
						variant.sizes.push({
							size,
							amount: 0,
						})
					}
				})

				// remove sizes
				variant.sizes.forEach((variantSize, idx) => {
					if (stateVariant.selectedSizes.indexOf(variantSize.size) === -1) {
						variant.sizes.splice(idx, 1)
					}
				})

				variant.sizes = sortSizes(variant.sizes)
			}
		}
	},
	// UPDATE_SELECTED_SIZES_OLD: function (state, selectedSizes) {
	// 	state.selectedSizes = selectedSizes

	// 	for (let key in state.variants) {
	// 		const variant = state.variants[key]
	// 		if (!variant.sizes) variant.sizes = []

	// 		// add sizes
	// 		state.selectedSizes.forEach((size) => {
	// 			if (!variant.sizes.find((vs) => vs.size === size)) {
	// 				variant.sizes.push({
	// 					size,
	// 					amount: 0,
	// 				})
	// 			}
	// 		})

	// 		// remove sizes
	// 		variant.sizes.forEach((variantSize, idx) => {
	// 			if (state.selectedSizes.indexOf(variantSize.size) === -1) {
	// 				variant.sizes.splice(idx, 1)
	// 			}
	// 		})

	// 		variant.sizes = sortSizes(variant.sizes)
	// 	}
	// },
	UPDATE_SIZE_AMOUNT: function (state, { variantIdx, size, newAmount }) {
		const variants = { ...state.variants }
		variants[variantIdx].sizes.forEach((vs) => {
			if (vs.size === size) {
				vs.amount = newAmount
			}
		})
		state.variants = variants
	},

	// CUSTOMER REQUEST
	ADD_CONFIGURATION_TO_CUSTOMER_REQUEST: function (state) {
		const createConfiguration = () => {
			const internalProduct = { ...state.product }

			const configuration = {
				editId: createUuid(),
				ref: internalProduct,
				internalId: internalProduct._id,
				externalId: internalProduct.externalArticleNumber,
				shop: internalProduct.shopName,
				name: internalProduct.name,
				type: internalProduct.category,
				colors: [...state.colors],
				variants: { ...state.variants },
				// selectedSizes: [...state.selectedSizes],
				finishes: { ...state.finishes },
				addons: [],
				customProductDescription: state.customProductDescription,
			}

			return configuration
		}

		console.log('ADDING NEW CONFIGURATION')
		const configuration = createConfiguration()

		// add editId to current state. This fixes when the user goes back via the browser back button.
		state.editId = configuration.editId

		state.customerRequest.configurations.push(configuration)
	},
	UPDATE_CONFIGURATION_TO_CUSTOMER_REQUEST: function (state) {
		state.customerRequest.configurations.forEach((conf) => {
			if (conf.editId === state.editId) {
				const internalProduct = { ...state.product }
				conf.ref = internalProduct
				conf.internalId = internalProduct._id
				conf.externalId = internalProduct.externalArticleNumber
				conf.shop = internalProduct.shopName
				conf.name = internalProduct.name
				conf.type = internalProduct.category
				conf.colors = [...state.colors]
				conf.variants = { ...state.variants }
				// conf.selectedSizes = [...state.selectedSizes]
				conf.finishes = { ...state.finishes }
				conf.addons = []
				conf.customProductDescription = state.customProductDescription
			}
		})
	},
	SET_CONFIGURATION_FROM_CUSTOMER_REQUEST_TO_STATE: function (state, configurationEditId) {
		const configuration = state.customerRequest.configurations.find((c) => c.editId === configurationEditId)

		if (configuration) {
			// PRODUCT
			state.category = configuration.ref.category
			state.product = { ...configuration.ref }
			state.productId = configuration.ref._id
			state.colors = [...configuration.colors]
			state.editId = configuration.editId

			// FINISHES
			state.selectedFinishId = -1
			state.finishes = { ...configuration.finishes }

			// VARIANTS
			state.selectedVariantId = -1
			state.variants = { ...configuration.variants }

			// SIZES
			// state.selectedSizes = [...configuration.selectedSizes]

			// CUSTOM PRODUCT
			state.customProductDescription = configuration.customProductDescription
		}
	},
	REMOVE_CONFIGURATION_FROM_CUSTOMER_REQUEST: function (state, configurationEditId) {
		state.customerRequest.configurations.forEach((c, idx) => {
			if (c.editId === configurationEditId) {
				console.log(idx)
				state.customerRequest.configurations.splice(idx, 1)
			}
		})
	},

	// Configuration Templates
	SET_CONFIGURATION_FROM_TEMPLATE_TO_CUSTOMER_REQUESTS: function (state, template) {
		state.customerRequest.configurations.push(template)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
