<template>
	<div id="account-header">
		<v-row class="header d-flex space-between items-center px-5 py-5 no-gutters">
			<v-col cols="3" class="d-flex items-start">
				<a href="/" class="logo">
					<Logo />
				</a>
			</v-col>
			<v-col cols="6" class="text-center subheader py-0 fill-height">
				<button
					@click="routeName !== 'account-dashboard' && $router.push({ name: 'account-dashboard' })"
					:class="{ active: routeName === 'account-dashboard' }"
				>
					Projekte
				</button>
				<button
					@click="routeName !== 'account-settings' && $router.push({ name: 'account-settings' })"
					:class="{ active: routeName === 'account-settings' }"
				>
					Einstellungen
				</button>
				<button
					@click="routeName !== 'account-emails' && $router.push({ name: 'account-emails' })"
					:class="{ active: routeName === 'account-emails' }"
				>
					Email-Zugänge
				</button>
				<button
					@click="routeName !== 'account-files' && $router.push({ name: 'account-files' })"
					:class="{ active: routeName === 'account-files' }"
				>
					Uploads
				</button>
			</v-col>
			<v-col cols="3" class="text-right">
				<v-btn class="mr-4" small elevation="0" @click="logout">Logout</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/views/configurator/partials/Logo'
import dayjs from 'dayjs'

export default {
	name: 'Dashboard',
	components: { Logo },
	data() {
		return {
			tab: null,
			loading: true,
		}
	},
	computed: {
		...mapGetters({
			customer: 'Auth/getCustomer',
			requests: 'Requests/getLoggedInCustomerRequests',
		}),
		routeName() {
			return this.$route.name
		},
	},
	mounted() {
		this.loading = true
		this.$store
			.dispatch('Requests/fetchForLoggedInCustomer')
			.then(this.$store.dispatch('Account/fetchCustomerFiles', this.customer._id))
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		logout() {
			this.$store.dispatch('Auth/logout')
			this.$router.push({ name: 'configurator-start' })
		},
		format(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
	},
}
</script>

<style scoped lang="sass">
#account-header
	position: fixed
	top: 0
	right: 0
	left: 0
	z-index: 100
	background: white

	.header
		border-bottom: solid 2px black
		overflow: hidden
	.subheader
		background: white
		// border-bottom: solid 2px black
		button
			opacity: 0.3
			font-weight: 700
			height: 30px
			width: 100px
			margin: 0 5px
			color: black
			border-radius: 4px
			position: relative
			// &:after 
			// 	content: ""
			// 	height: 20px
			// 	width: 2px
			// 	display: block
			// 	position: absolute
			// 	left: calc(50% - 1px)
			// 	top: 30px
			// 	background: transparent

			&.active
				opacity: 1
				color: white
				background: black
				&:after
					background: black

	.logo
		cursor: pointer
		svg
			height: 30px
	h3
		font-weight: 700
		font-size: 16px
		letter-spacing: -0.05em
		line-height: 1
		padding: 0 0 0 3px
	.table-top
		b
			opacity: 0.1
			font-size: 40px
			letter-spacing: -0.05em
	.table-row-entry
		h2
			font-weight: 700
			font-size: 24px
			letter-spacing: -0.05em
		h5
			font-weight: 300
			font-size: 20px
		h6
			letter-spacing: 0.1em
			font-weight: 700
			background: black
			color: white
			font-size: 10px
			border-radius: 8px
			padding: 3px 10px
</style>