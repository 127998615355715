const DEBUG = false
export default {
	install: function (Vue, options) {
		if (!options || (options && !options.store)) {
			throw new Error('Please provide vuex store to files plugin.')
		}
		if (!options || (options && !options.router)) {
			throw new Error('Please provide router to files plugin.')
		}

		const findFile = (fileId, storeContext) => {
			let fileObj

			options.store.state[storeContext].files.forEach((file) => {
				if (file._id === fileId) fileObj = file
			})

			// temp fix
			if (!fileObj) {
				options.store.state[storeContext].files.forEach((file) => {
					if (file.filename === fileId) fileObj = file
				})
			}

			if (fileObj) {
				return fileObj
			} else {
				console.error('Warning: File not found.')
				return ''
			}
		}

		Vue.prototype.$getFileById = function (fileId, storeContext = 'ConfiguratorFiles') {
			if (options.router.history.current.path.match('account')) storeContext = 'Account'
			if (DEBUG) console.log('Files plugin store context: ', storeContext)

			return findFile(fileId, storeContext)
		}

		Vue.prototype.$getFileUrlById = function (fileId, storeContext = 'ConfiguratorFiles') {
			if (options.router.history.current.path.match('account')) storeContext = 'Account'
			if (DEBUG) {
				console.log('ID: ', fileId)
				console.log('Files plugin store context: ', storeContext)
			}
			const file = findFile(fileId, storeContext)

			if (DEBUG) console.log(file)

			return file.url
		}
	},
}
