export default {
	type: 'object',
	properties: {
		// color: { type: 'string', minLength: 1 },
		coords: {
			type: 'object',
			x: { type: 'number' },
			y: { type: 'number' },
		},
		fileName: { type: 'string', isNotEmpty: true },
		imageHeight: { type: 'number', minimum: 0 },
		imageWidth: { type: 'number', minimum: 0 },
		position: { type: 'string', isNotEmpty: true },
		side: {
			type: 'string',
			isNotEmpty: true,
			// oneOf: [{ const: 'front' }, { const: 'back' }, { const: 'shoulder-sleeve' }],
		},
		size: {
			type: 'object',
			width: { type: 'number', minimum: 0 },
			height: { type: 'number', minimum: 0 },
		},
		sizeMeasure: { type: 'string', isNotEmpty: true },
		technique: { type: 'string', isNotEmpty: true },
		techniqueType: {},
	},

	allOf: [
		{
			if: { properties: { technique: { const: 'labels' } } },
			then: { properties: { techniqueType: { type: 'string', isNotEmpty: true } } },
		},
		{
			if: { properties: { position: { const: /custom/ } } },
			then: { required: ['coords'] },
		},
		{
			if: { properties: { sizeMeasure: { const: 'width' } } },
			then: { properties: { size: { properties: { width: { minimum: 0.1 } } } } },
			// then: { properties: { size: { properties: { width: { minimum: 1 } } } } },
		},
		{
			if: { properties: { sizeMeasure: { const: 'height' } } },
			then: { properties: { size: { properties: { height: { minimum: 0.1 } } } } },
			// then: { properties: { size: { properties: { height: { minimum: 1 } } } } },
		},
	],

	required: ['fileName', 'imageHeight', 'imageWidth', 'position', 'size', 'sizeMeasure', 'technique'],
	errorMessage: {
		type: 'Dein Motiv ist leider nicht valide.',
		properties: {
			position: 'Position wird benötigt.',
			techniqueType: 'Bei Labels wird der Typ benötigt.',
			size: 'Größenangabe wird benötigt.',
			coords: 'Das Motiv muss auf dem Textil positioniert sein.',
			fileName: 'Datei wird ausgewählt werden.',
			imageHeight: 'Bildhöhe wird benötigt.',
			imageWidth: 'Bildhöhe wird benötigt.',
			side: 'Seite wird benötigt.',
			sizeMeasure: 'Höhe oder Breite muss ausgewählt sein.',
			technique: 'Veredelungsart wird benötigt',
		},
	},
	// additionalProperties: false,
}
