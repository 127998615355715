import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/internal-products'

const getAll = () => {
	return httpClient.get(END_POINT)
}

const getActive = () => {
	return httpClient.get(END_POINT + '/active', {})
}

const getById = id => {
	return httpClient.get(END_POINT + '/' + id)
}

const getByCategory = category => {
	return httpClient.get(END_POINT + '/category/' + category)
}

const create = data => {
	return httpClient.post(END_POINT + '/create', { data })
}

const update = (orderId, data) => {
	return httpClient.update(END_POINT + '/' + orderId + '/update', { data })
}

const deleteById = productId => {
	return httpClient.get(END_POINT + '/' + productId + '/delete')
}

export { getAll, getActive, getById, getByCategory, create, update, deleteById }
