import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

// Layouts
import BasicLayout from '@/layouts/BasicLayout'
// import BasicLayoutNoHeader from '@/layouts/BasicLayoutNoHeader'
import AccountLayout from '@/layouts/AccountLayout'
import AccountProjectLayout from '@/layouts/AccountProjectLayout'
import BlankLayout from '@/layouts/BlankLayout'
import BlankLayoutConfigurator from '@/layouts/BlankLayoutConfigurator'
import CanvasSimple from '@/layouts/CanvasSimple'

// Misc
import PageNotFound from '@/views/PageNotFound'

// Homepage
const Start = () => import(/* webpackChunkName: "homepage" */ '@/views/homepage/Start')
const RequestAsCustomer = () => import(/* webpackChunkName: "homepage" */ '@/views/homepage/RequestAsCustomer')

// Configurator
const ConfiguratorWrapper = () => import(/* webpackChunkName: "configurator" */ '@/views/configurator/ConfiguratorWrapper')

// Cart
const CartOverview = () => import(/* webpackChunkName: "cart" */ '@/views/cart/CartOverview')
const Success = () => import(/* webpackChunkName: "cart" */ '@/views/cart/Success')

// Legal
const PrivacyPage = () => import(/* webpackChunkName: "legal" */ '@/views/legal/PrivacyPage')
const AgbPage = () => import(/* webpackChunkName: "legal" */ '@/views/legal/AgbPage')
const ImprintPage = () => import(/* webpackChunkName: "legal" */ '@/views/legal/ImprintPage')

// User Account
const MagicLink = () => import(/* webpackChunkName: "account" */ '@/views/account/MagicLink')
const Dashboard = () => import(/* webpackChunkName: "account" */ '@/views/account/Dashboard')
const Emails = () => import(/* webpackChunkName: "account" */ '@/views/account/Emails')
const ValidateMagicToken = () => import(/* webpackChunkName: "account" */ '@/views/account/ValidateMagicToken')
const Settings = () => import(/* webpackChunkName: "account" */ '@/views/account/Settings')
const Project = () => import(/* webpackChunkName: "account" */ '@/views/account/Project')
const Files = () => import(/* webpackChunkName: "account" */ '@/views/account/Files')
const VerifyAdditionalEmail = () => import(/* webpackChunkName: "account" */ '@/views/account/VerifyAdditionalEmail')

Vue.use(VueRouter)

const beforeAction = (to, from, next) => {
	if (store.getters['Auth/hasPermission']) {
		// console.log('logged in')
		next()
	} else {
		console.log('not logged in')
		next({
			name: 'account-login',
			query: { org: to.fullPath },
		})
	}
}

const routes = [
	{
		path: '/',
		component: CanvasSimple,
		children: [
			{
				path: '',
				name: 'configurator-start',
				component: Start,
			},
			{
				path: 'privacy',
				name: 'privacy-page',
				component: PrivacyPage,
			},
			{
				path: 'agb',
				name: 'agb-page',
				component: AgbPage,
			},
			{
				path: 'imprint',
				name: 'imprint-page',
				component: ImprintPage,
			},
			{
				path: 'request-as-customer',
				name: 'request-as-customer',
				component: RequestAsCustomer,
			},
		],
	},
	{
		path: '/',
		component: BlankLayoutConfigurator,
		children: [
			{
				path: 'config',
				name: 'configurator-config',
				component: ConfiguratorWrapper,
			},
		],
	},
	{
		path: '/',
		component: BlankLayout,
		children: [
			{
				path: 'overview',
				name: 'configurator-overview',
				component: CartOverview,
			},
			{
				path: 'success',
				name: 'configurator-success',
				component: Success,
			},
		],
	},
	{
		path: '/account',
		component: AccountLayout,
		children: [
			{
				path: '', // protected
				component: Dashboard,
				name: 'account-dashboard',
				// eslint-disable-next-line
				beforeEnter: beforeAction,
			},
			// {
			// 	path: 'project/:projectId', // protected
			// 	component: Project,
			// 	name: 'account-project',
			// 	// eslint-disable-next-line
			// 	beforeEnter: beforeAction,
			// },
			{
				path: 'files', // protected
				component: Files,
				name: 'account-files',
				// eslint-disable-next-line
				beforeEnter: beforeAction,
			},
			{
				path: 'settings', // protected
				component: Settings,
				name: 'account-settings',
				// eslint-disable-next-line
				beforeEnter: beforeAction,
			},
			{
				path: 'emails', // protected
				component: Emails,
				name: 'account-emails',
				// eslint-disable-next-line
				beforeEnter: beforeAction,
			},
		],
	},
	{
		path: '/account',
		component: AccountProjectLayout,
		children: [
			{
				path: 'project/:projectId', // protected
				component: Project,
				name: 'account-project',
				// eslint-disable-next-line
				beforeEnter: beforeAction,
			},
		],
	},
	{
		path: '/account',
		component: BasicLayout,
		children: [
			{
				path: 'login',
				component: MagicLink,
				name: 'account-login',
			},
			{
				path: ':magicToken',
				component: ValidateMagicToken,
				name: 'account-validate-magic-token',
			},
			{
				path: 'verify-email/:token',
				component: VerifyAdditionalEmail,
				name: 'account-verify-additional-email',
			},
		],
	},
	{ path: '*', component: PageNotFound },
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
