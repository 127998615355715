<template>
	<v-app>
		<v-main>
			<Cookie />
			<MobileBlocker />
			<MiniHeader />
			<router-view></router-view>
			<MiniFooter />
		</v-main>
	</v-app>
</template>

<script>
import Cookie from './partials/Cookie'
import MobileBlocker from './partials/MobileBlocker'
import MiniFooter from './partials/MiniFooter'
import MiniHeader from './partials/MiniHeader'


export default {
	name: 'BasicLayout',
	components: { Cookie, MobileBlocker, MiniFooter, MiniHeader },
}
</script>