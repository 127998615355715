<template>
	<v-app>
		<v-main id="main">
			<Cookie />
			<MobileBlocker />
			<ProjectHeader />
			<div id="account-content">
				<router-view :key="$route.fullPath"></router-view>
			</div>
			<div id="account-footer">
				<MiniFooter />
			</div>
		</v-main>
	</v-app>
</template>

<script>
import Cookie from './partials/Cookie'
import MobileBlocker from './partials/MobileBlocker'
import MiniFooter from './partials/MiniFooter'
import ProjectHeader from '../views/account/partials/ProjectHeader.vue'

export default {
	name: 'AccountProjectLayout',
	components: { Cookie, MobileBlocker, MiniFooter, ProjectHeader },
}
</script>

<style scoped lang="sass">
#main
    background: rgba(248,248,248)
#account-content
    margin-top: 100px
    margin-bottom: 100px
#account-footer
    position: fixed
    bottom: 0
    right: 0
    left: 0
</style>