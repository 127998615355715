import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

import {
	getAll,
	getById,
	getByCustomer,
	update,
	create,
	addNote,
	deleteNote,
	getForLoggedInCustomer,
	addMessage,
	acceptOffer,
	declineOffer,
} from '@/api/requests.api'

const state = {
	requests: [],
	currentRequest: null,
	currentCustomerRequest: null,
	loggedinCustomerRequests: [],
}

const getters = {
	getField,
	getRequests: (state) => (status) => {
		return status ? state.requests.filter((r) => r.status === status) : state.requests
	},
	getLoggedInCustomerRequests: (state) => {
		const sorted = state.loggedinCustomerRequests.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt)
		})
		return sorted
	},
	getRequestsByStatusArray: (state) => (statusArray) => {
		return statusArray ? state.requests.filter((r) => statusArray.indexOf(r.status) > -1) : state.requests
	},
	getCurrentRequest: function (state) {
		return state.currentRequest
	},
	getCurrentCustomerRequest: function (state) {
		return state.currentCustomerRequest
	},
	getCustomerRequests:
		(state) =>
		(customerId, group = 'all') => {
			let requests = []

			const negotiationStatus = ['PENDING', 'WAITING', 'CUSTOMER-WAITING', 'ACCEPTED']
			const productionStatus = ['PRE-PRODUCTION', 'PRODUCTION', 'ON-HOLD', 'RELEASE-CUSTOMER', 'POST-PRODUCTION', 'SHIPPED']
			const completedStatus = ['COMPLETED', 'CANCELED', 'CUSTOMER-CANCELED']

			if (group === 'all') requests = state.requests.filter((r) => r.customerId === customerId)
			else if (group === 'negotiation')
				requests = state.requests.filter((r) => r.customerId === customerId && negotiationStatus.indexOf(r.status) > -1)
			else if (group === 'production')
				requests = state.requests.filter((r) => r.customerId === customerId && productionStatus.indexOf(r.status) > -1)
			else if (group === 'completed')
				requests = state.requests.filter((r) => r.customerId === customerId && completedStatus.indexOf(r.status) > -1)

			return requests
		},
}

const actions = {
	fetchForLoggedInCustomer: async function ({ commit }) {
		const result = await getForLoggedInCustomer()
		if (result.requests) commit('SET_LOGGED_IN_CUSTOMER_REQUESTS', result.requests)
	},
	unsetRequestForLoggedInCustomer: async function ({ commit }) {
		commit('UNSET_LOGGED_IN_CUSTOMER_REQUESTS')
	},
	fetchRequests: async function ({ commit }) {
		try {
			const response = await getAll()
			commit('SET_REQUESTS', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	fetchRequestById: async function ({ commit }, id) {
		try {
			const response = await getById(id)
			commit('SET_CURRENT_REQUEST', response.data)
			return response.data
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	fetchCustomerRequests: async function ({ commit }, customerId) {
		try {
			const response = await getByCustomer(customerId)
			commit('SET_REQUESTS', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	selectCustomerRequest: function ({ commit }, id) {
		try {
			const request = state.requests.find((r) => r._id === id)
			commit('SET_CURRENT_CUSTOMER_REQUEST', request)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	update: async function ({ commit }, { id, data }) {
		try {
			const response = await update(id, data)
			commit('UPDATE_REQUEST', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	addNote: async function ({ commit }, { id, data }) {
		try {
			const response = await addNote(id, data)
			commit('UPDATE_REQUEST', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	deleteNote: async function ({ commit }, { id, data }) {
		try {
			const response = await deleteNote(id, data)
			commit('UPDATE_REQUEST', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	create: async function ({ commit }, data) {
		try {
			const response = await create(data)
			commit('NEW_REQUEST', response.data)
		} catch (error) {
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
		}
	},
	addMessage: async function ({ commit }, { requestId, type, text }) {
		const result = await addMessage(requestId, { type, text })
		commit('UPDATE_REQUEST', result.updated)
	},
	acceptOffer: async function ({ commit }, { requestId }) {
		const response = await acceptOffer(requestId)
		commit('UPDATE_REQUEST', response.updated)
	},
	declineOffer: async function ({ commit }, { requestId }) {
		const response = await declineOffer(requestId)
		commit('UPDATE_REQUEST', response.updated)
	},
	resetCurrentRequest({ commit }) {
		commit('SET_CURRENT_REQUEST', null)
	},
	socket_newRequest({ commit }, data) {
		commit('NEW_REQUEST', data)
	},
	socket_updateRequest({ commit }, data) {
		commit('UPDATE_REQUEST', data)
	},
}

const mutations = {
	updateField,
	SET_REQUESTS: function (state, payload) {
		if (payload) {
			if (state.requests && state.requests.length > 0) state.requests.concat(payload)
			else state.requests = payload
		}
	},
	NEW_REQUEST(state, payload) {
		if (payload) state.requests.push(payload)
	},
	UPDATE_REQUEST(state, payload) {
		state.requests = state.requests.map((r) => {
			return r._id === payload._id ? payload : r
		})
		if (state.currentRequest && payload && state.currentRequest._id === payload._id) {
			state.currentRequest = payload
		}
	},
	SET_CURRENT_REQUEST(state, payload) {
		state.currentRequest = payload
		// udpate array
		if (payload) {
			let found = false
			state.requests = state.requests.map((r) => {
				if (r._id === payload._id) {
					found = true
					return payload
				} else {
					return r
				}
			})
			if (!found) state.requests.push(payload)
		}
	},
	SET_CURRENT_CUSTOMER_REQUEST(state, payload) {
		state.currentCustomerRequest = payload
	},
	SET_LOGGED_IN_CUSTOMER_REQUESTS(state, requests) {
		state.loggedinCustomerRequests = requests
	},
	UNSET_LOGGED_IN_CUSTOMER_REQUESTS(state) {
		state.loggedinCustomerRequests = []
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
