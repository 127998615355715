import { getField, updateField } from 'vuex-map-fields'
import { extractColors, uploadFile, deleteFile, fetchFiles, fetchFilesByCustomer } from '@/api/utils.api'
import { addFile } from '@/api/requests.api'

const DEFAULT_FILES = [
	{
		_id: '61c3228b533e2e60e5684116',
		filename: 'dummy_design_1_1237892374925',
		label: 'vorlage A',
		url: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/ele.svg',
		type: 'dummy',
		width: 44,
		height: 41,
	},
]

const state = {
	// FILES
	files: [...DEFAULT_FILES],
}

const getters = {
	getField,
}

const actions = {
	extractColors: ({ state }, imageUrl) => {
		if (!state) console.log(state)
		// if (state) imageUrl = 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/uploads/drexler-logo_1616427999045.png'
		if (imageUrl) {
			return extractColors(imageUrl).then((colors) => {
				return colors
			})
		} else {
			return false
		}
	},
	fetchFiles: async ({ commit }, fileIdArray) => {
		const res = await fetchFiles(fileIdArray)
		if (res) commit('SET_FILES', res.files)
	},
	async fetchCustomerFiles({ commit }, customerId) {
		const res = await fetchFilesByCustomer(customerId)
		if (res) commit('SET_FILES', [...res.files, ...DEFAULT_FILES])
	},
	addFile: ({ commit }, { name, label, url, type, width, height }) => {
		commit('ADD_FILE', { name, label, url, type, width, height })
	},
	resetFiles: ({ commit }) => {
		commit('RESET_FILES')
	},
	async deleteFile({ commit }, { file }) {
		console.log(file)
		await deleteFile(file._id)
		commit('DELETE_FILE', file.name)
	},
	async uploadFile({ commit }, { file }) {
		const upload = await uploadFile(file)

		console.log(upload)

		commit('ADD_FILE', {
			_id: upload._id,
			name: upload.filename,
			label: upload.label,
			url: upload.url,
			type: 'upload',
			width: upload.width,
			height: upload.height,
		})

		return upload
	},
	async addFileToRequest({ state }, { requestId, fileId }) {
		if (!state) console.log(state)
		await addFile(requestId, fileId)
	},
}

const mutations = {
	updateField,
	SET_FILES: function (state, files) {
		state.files = [...files]
	},
	ADD_FILE: function (state, { _id, name, label, url, type, width, height }) {
		state.files.push({ _id, name, label, url, type, width, height })
	},
	RESET_FILES: function (state) {
		state.files = [...DEFAULT_FILES]
	},
	DELETE_FILE: function (state, fileName) {
		console.log(fileName)
		console.log(state.files)
		const fileIdx = state.files.findIndex((f) => f.name === fileName)

		console.log(fileIdx)

		if (fileIdx > -1) {
			const files = [...state.files]
			files.splice(fileIdx, 1)
			state.files = [...files]
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
