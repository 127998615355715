import httpClient from './httpClient'
import adminUserHttpClient from './adminUserHttpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/auth'

const login = (username, password) => {
	return httpClient.post(END_POINT + '/ez-login', { username, password })
}

const requestMagicLink = (email, orgUrl) => {
	return httpClient.post(END_POINT + '/magic-link', { email, orgUrl })
}

const validateMagicToken = (token) => {
	return httpClient.post(END_POINT + '/magic-link/validate', { token })
}

const validatePostalCode = (token, postalCode) => {
	return httpClient.post(END_POINT + '/magic-link/postal-code/validate', { token, postalCode })
}

const getCustomerData = () => {
	return httpClient.get(END_POINT + '/customer')
}

// @INFO: route needs a admin user login token
const getCustomerDataById = (customerId) => {
	return adminUserHttpClient.get(`${END_POINT}/customer/${customerId}`)
}

const loginWithOneTimeToken = (token) => {
	return httpClient.post(`${END_POINT}/one-time-token-login`, { token })
}

const requestLoginCode = (email) => {
	return httpClient.post(`${END_POINT}/login-code`, { email })
}

const validateLoginCode = (code) => {
	return httpClient.post(`${END_POINT}/login-code/validate`, { code })
}
export {
	login,
	requestMagicLink,
	validateMagicToken,
	getCustomerData,
	validatePostalCode,
	getCustomerDataById,
	loginWithOneTimeToken,
	requestLoginCode,
	validateLoginCode,
}
