import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
let debug = process.env.NODE_ENV !== 'production'

debug = false

// add store data paths here to make them persistent
const persistentState = createPersistedState({
	paths: [
		'ConfiguratorDisplay.currentStep',
		'ConfiguratorFiles.files',
		'Configurator.category',
		'Configurator.product',
		'Configurator.productId',
		'Configurator.colors',
		'Configurator.editId',
		'Configurator.selectedFinishId',
		'Configurator.finishes',
		'Configurator.selectedVariantId',
		'Configurator.variants',
		// 'Configurator.selectedSizes',
		'Configurator.customerRequest',
		'ConfiguratorRequest.customer',
		'ConfiguratorRequest.agbAccepted',
		'ConfiguratorRequest.comment',
		'ConfiguratorRequest.shippingType',
		'Auth.authToken',
		'Auth.tokenTime',
		'Auth.customer',
	],
})
export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	getters: {},
	plugins: debug ? [createLogger(), persistentState] : [persistentState], // set logger only for development
	strict: debug,
	modules,
})
