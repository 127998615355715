
<template>
	<div class="nav-bar">
		<a href="/" class="logo"><Logo /></a>
		<div class="user-button d-flex">
			<a href="/account">
				{{ isLoggedIn ? 'Account' : 'Einloggen' }}
			</a>
			<a href="#" @click.prevent="openConfigurator" class="register">Neues Design</a>
		</div>
	</div>
</template>



<script>
import Logo from '../../views/configurator/partials/Logo'

export default {
	name: 'miniHeader',
	components: { Logo },
	computed: {
		isLoggedIn() {
			return this.$store.getters['Auth/hasPermission']
		},
	},
	methods: {
		openConfigurator() {
			this.$store.dispatch('Configurator/resetConfiguration')
			this.$router.push({ name: 'configurator-config' })
		},
	},
}
</script>

<style lang="sass" scoped>
.nav-bar
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 70px
	border-bottom: solid 2px transparent
	display: inline-flex
	justify-content: space-between
	align-items: center
	padding: 10px 25px
	z-index: 200
	transition: all 400ms ease-in
	&.active
		background: white
		border-color: black
.bigButton
	display: inline-block
	font-weight: 700
	background: black
	border: solid 3px black
	padding: 10px 25px
	color: white
	font-size: 1.4em
.logo
	z-index: 100
	svg
		width: 100% !important
.user-button
	top: 15px
	z-index: 20
	a
		color: black
		padding: 10px 20px
		font-weight: 700
		font-size: 1.2em
		margin: 0 5px
		&.register
			background: transparent
			border: solid 2px black
			color: black
</style>
