import { getField, updateField } from 'vuex-map-fields'
import { validateStep } from '../../utils/helpers'

const STEPS = ['textile', 'motive', 'variants', 'sizes', 'cart-overview', 'account']

const state = {
	// ERROR
	error: false,
	errorMessage: 'Ohje, da ist was schief gelaufen :(',
	errorOptions: {},

	// STEP
	currentStep: 'textile',

	// DISPLAY
	displayScenario: '',

	// SIZES
	sizeWarning: false,
	// sliderMaxHeight: 0,
	// sliderMaxWidth: 0,

	infoBlockVisibility: {
		textile: true,
		motive: true,
		variants: true,
		sizes: true,
	},

	colorSelectionModal: false,
	colorSelectionWarningAccepted: false,
	colorSelectionWarningModal: false,
	continueConfigurationModal: false,
	configOpendedByCart: false,
}

const getters = {
	getField,
}

const actions = {
	/**
	 * Error actions
	 */
	setError: ({ commit }, { message, options }) => {
		commit('SET_ERROR', { message, options })
	},
	unsetError: ({ commit }) => {
		commit('UNSET_ERROR')
	},

	setSizeWarning: ({ commit }, value) => {
		commit('SET_SIZE_WARNING', value)
	},

	displayScenario: ({ commit }, scenario) => {
		commit('DISPLAY_SCENARIO', { scenario })
	},
	/**
	 * Sets step without validation.
	 */
	setStep: ({ commit, rootState, dispatch, state }, step) => {
		let valid = true

		if (step === 'motive') {
			// validate textile
			valid = validateStep('textile', rootState.Configurator)
		}
		if (step === 'variants') {
			// validate finishes
			valid = validateStep('textile', rootState.Configurator) && validateStep('motive', rootState.Configurator)
		}
		if (step === 'sizes') {
			// validate finishes & variants
			valid =
				validateStep('textile', rootState.Configurator) &&
				validateStep('motive', rootState.Configurator) &&
				validateStep('variants', rootState.Configurator)
		}

		// non configurator steps
		if (step === 'account' || step === 'cart-overview') valid = true

		// validate size warning
		if (state.sizeWarning) valid = false

		if (valid && STEPS.indexOf(step) > -1) {
			commit('SET_CURRENT_STEP', step)
		} else if (!valid) {
			if (state.sizeWarning) {
				dispatch(
					'ConfiguratorDisplay/setError',
					{ message: 'Dein Motiv befindet sich außerhalb des bedruckbaren Bereichs.' },
					{ root: true }
				)
			} else {
				dispatch('ConfiguratorDisplay/setError', { message: 'Bitte schließe zuerst die vorherigen Schritte ab.' }, { root: true })
			}
		}
	},
	/**
	 * Step actions
	 */
	nextStep: ({ commit, state, rootState }) => {
		let currentIdx = Math.max(STEPS.indexOf(state.currentStep), 0)
		let nextIdx = Math.min(Math.max(currentIdx + 1, 0), 3)
		if (validateStep(state.currentStep, rootState.Configurator)) {
			console.log(`Next step: ${STEPS[nextIdx]}`)
			commit('SET_CURRENT_STEP', STEPS[nextIdx])
		} else {
			commit('SET_ERROR', { message: `Data for step ${state.currentStep} is not valid.` })
		}
	},
	prevStep: ({ commit, state, rootState }) => {
		let currentIdx = Math.max(STEPS.indexOf(state.currentStep), 0)
		let prevIdx = Math.min(Math.max(currentIdx - 1, 0), 3)
		if (validateStep(state.currentStep, rootState.Configurator)) {
			console.log(`Previous step: ${STEPS[prevIdx]}`)
			commit('SET_CURRENT_STEP', STEPS[prevIdx])
		} else {
			commit('SET_ERROR', { message: `Data for step ${state.currentStep} is not valid.` })
		}
	},

	toggleContinueConfigurationModal: ({ commit, state }) => {
		if (!state.configOpendedByCart) commit('TOGGLE_CONTINUE_CONFIGURATION_MODAL')
		else commit('SET_CONFIG_OPENED_BY_CART', false) // reset flag
	},

	toggleColorSelectionWarningModal: ({ commit }) => {
		commit('TOGGLE_COLOR_SELECTION_WARNING_MODAL')
	},

	toggleColorSelectionModal: ({ commit }) => {
		commit('TOGGLE_COLOR_SELECTION_MODAL')
	},

	acceptColorSelectionWarning: ({ commit }) => {
		commit('ACCEPT_COLOR_SELECTION_WARNING')
	},

	setConfigOpenedByCartFlag: ({ commit }, value) => {
		commit('SET_CONFIG_OPENED_BY_CART', value)
	},
}

const mutations = {
	updateField,

	// STEPS
	SET_CURRENT_STEP: function (state, newStep) {
		state.currentStep = newStep
	},

	// ERROR
	SET_ERROR: function (state, { message, options }) {
		state.error = true
		if (message) state.errorMessage = message
		if (options) state.errorOptions = options
	},
	UNSET_ERROR: function (state) {
		state.error = false
		state.errorMessage = 'Ohje, da ist was schief gelaufen :('
		state.errorOptions = {}
	},
	SET_SIZE_WARNING: function (state, value) {
		state.sizeWarning = value
	},

	DISPLAY_SCENARIO: function (state, scenario) {
		state.displayScenario = scenario
		console.log(state.displayScenario.scenario)
	},

	TOGGLE_CONTINUE_CONFIGURATION_MODAL: function (state) {
		state.continueConfigurationModal = !state.continueConfigurationModal
	},

	TOGGLE_COLOR_SELECTION_WARNING_MODAL: function (state) {
		state.colorSelectionWarningModal = !state.colorSelectionWarningModal
	},

	TOGGLE_COLOR_SELECTION_MODAL: function (state) {
		state.colorSelectionModal = !state.colorSelectionModal
	},

	ACCEPT_COLOR_SELECTION_WARNING: function (state) {
		state.colorSelectionWarningAccepted = true
	},

	SET_CONFIG_OPENED_BY_CART: function (state, value) {
		state.configOpendedByCart = value
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
