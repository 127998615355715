// @TODO: read up on API factories!!
// Test-API: https://httpbin.org/#/HTTP_Methods/get_get

import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '../store/index.js'
// import { throttleAdapterEnhancer } from 'axios-extensions'

// to use throttling we need to setup client caching first: https://medium.com/locale-ai/architecting-http-clients-in-vue-js-applications-for-efficient-network-communication-991cf1df1cb2
// @TODO: Do we need this?
// const throttleConfig = {
//     threshold: 2*1000 // 2 seconds
// }

const httpClient = axios.create({
	baseUrl: process.env.VUE_APP_BASE_URL,
	timeout: 120000, // indicates, 1000ms ie. 1 second
	// adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
	headers: {
		'Content-Type': 'application/json',
		// anything you want to add to the headers
	},
})

// AUTHENTICATION

const getAuthToken = () => {
	// return localStorage.getItem('token')
	return store.getters['Auth/getAuthToken']
}

const authInterceptor = config => {
	config.headers['Authorization'] = 'JWT ' + getAuthToken()
	return config
}

httpClient.interceptors.request.use(authInterceptor)

// ERRORS

// interceptor to catch errors
const errorInterceptor = error => {
	console.error(error)
	// all the error responses
	switch (error.response.status) {
		case 400:
			console.error(error.response.status, error.message)
			Vue.notify({
				group: 'main',
				title: 'Nothing to display',
				text: 'Data Not Found',
				type: 'error',
			})
			break

		case 401: // authentication error, logout the user
			Vue.notify({
				group: 'main',
				title: 'Please login again',
				text: 'Session Expired',
				type: 'warn',
			})
			localStorage.removeItem('token')
			router.push({ name: 'customer-login' })
			break

		default:
			console.error(error.response.status, error.message)
			Vue.notify({
				group: 'main',
				title: 'Server Error',
				text: error.message,
				type: 'error',
			})
	}
	return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = response => {
	switch (response.status) {
		case 200:
			// yay!
			break
		// any other cases
		default:
		// default case
	}
	return response.data
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient
