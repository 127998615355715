<template>
	<div id="account-project-header">
		<v-row class="header d-flex space-between items-center">
			<v-col cols="6" class="d-flex items-start flex-column">
				<div class="d-flex justify-start items-center">
					<div class="back d-flex items-center justify-center">
						<v-icon large color="white" @click="$router.push({ name: 'account-dashboard' })">mdi-chevron-left</v-icon>
					</div>
					<h3 class="ml-2" v-if="currentProject">Project {{ currentProject.publicId }}</h3>
				</div>
			</v-col>

			<v-col cols="6" class="d-flex items-center justify-end">
				<div class="last-change" v-if="currentProject">
					<i
						>zuletzt aktualisiert am <b>{{ lastUpdate }}Uhr</b></i
					>
				</div>

				<!-- <div class="more d-flex items-center justify-center">
					<v-icon large color="black" @click="$router.push({ name: 'account-dashboard' })">mdi-dots-vertical</v-icon>
				</div> -->
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
	name: 'ProjectHeader',
	computed: {
		...mapGetters({
			currentProject: 'Requests/getCurrentRequest',
		}),
		lastUpdate() {
			return this.formatDate(this.currentProject.updatedAt)
		},
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY, HH:mm')
		},
	},
}
</script>

<style scoped lang="sass">
#account-project-header
    position: fixed
    top: 0
    right: 0
    left: 0
    z-index: 100
    background: white

    .header
        border-bottom: solid 2px black
        overflow: hidden
        padding: 14px 30px 4px
        position: fixed
        left: 0
        right: 0
        top: 0
        background: white
        z-index: 100
        .back
            height: 40px
            width: 40px
            background: rgb(30,30,30)
        h3
            font-weight: 700
            font-size: 30px
            letter-spacing: -0.05em
            line-height: 1
            padding: 0 0 0 3px
        .more
            height: 40px
        .last-change
            font-size: 14px
            font-weight: 300
            margin-right: 10px

            span
                background: green
                font-weight: 700
                padding: 8px 16px
                letter-spacing: -0.02em
                border-radius: 8px
                margin-left: 10px
</style>