import { render, staticRenderFns } from "./MiniFooter.vue?vue&type=template&id=f836ed56&scoped=true&"
import script from "./MiniFooter.vue?vue&type=script&lang=js&"
export * from "./MiniFooter.vue?vue&type=script&lang=js&"
import style0 from "./MiniFooter.vue?vue&type=style&index=0&id=f836ed56&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f836ed56",
  null
  
)

export default component.exports