<template>
	<v-app>
		<v-main>
			<!-- <Cookie /> -->
			<MobileBlocker />
			<AccountHeader />
			<div id="account-content">
				<router-view :key="$route.fullPath"></router-view>
			</div>
			<div id="account-footer">
				<MiniFooter />
			</div>
		</v-main>
	</v-app>
</template>

<script>
// import Cookie from './partials/Cookie'
import MobileBlocker from './partials/MobileBlocker'
import MiniFooter from './partials/MiniFooter'
import AccountHeader from '../views/account/partials/AccountHeader.vue'

export default {
	name: 'AccountLayout',
	components: { MobileBlocker, MiniFooter, AccountHeader },
}
</script>

<style scoped lang="sass">
#account-content
    margin-top: 100px
    margin-bottom: 100px
#account-footer
    position: fixed
    bottom: 0
    right: 0
    left: 0
</style>
