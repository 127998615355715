<template>
	<div class="footer d-flex justify-between items-center">
		<a href="/privacy">Datenschutz</a>
		<a href="/imprint">Impressum</a>
		<a href="/agb">AGB</a>
	</div>
</template>

<script>
export default {
	name: 'miniFooter',
	methods: {},
	mounted() {},
}
</script>

<style lang="sass" scoped>
.footer
	background: white
	border-top: solid 2px black
	padding: 25px
	color: white
	width: 100%
	position: absolute
	bottom: 0
	left: 0
	li
		margin-bottom: 10px
	a
		color: black
		font-weight: 700
		letter-spacing: 0em
		font-size: 1.3em
		margin: 0 25px
</style>
