import httpClient from './httpClient'

const END_POINT = process.env.VUE_APP_BASE_URL + '/customer'

const addEmail = (email) => {
	return httpClient.post(END_POINT + '/add-email', { email })
}

const deleteEmail = (email) => {
	return httpClient.post(END_POINT + '/delete-email', { email })
}

const updateCustomerData = (data) => {
	return httpClient.put(END_POINT, { data })
}

const getCustomerAddresses = () => {
	return httpClient.get(`${END_POINT}/addresses`)
}

const verifyAdditionalEmail = (token) => {
	return httpClient.post(END_POINT + '/verify-additional-email', { token })
}

export { addEmail, deleteEmail, updateCustomerData, verifyAdditionalEmail, getCustomerAddresses }
