export default {
	install: function(Vue) {
		Vue.prototype.$getDictString = function(key, replacementVars) {
			let string = this.$store.getters['Dictionary/getReadableEntry'](key)
			if (replacementVars) {
				for (let key in replacementVars) {
					const variable = key.toUpperCase()
					string = string.replace(variable, replacementVars[key])
				}
			}
			return string
		}
	},
}
