<template>
<div class="footer-wrapper">
	<div class="footer d-flex justify-center items-center">
		<a href="/privacy">Datenschutz</a>
		<a href="/imprint">Impressum</a>
		<a href="/agb">AGB</a>
	</div>
</div>
</template>

<script>
export default {
	name: 'SimpleFooter',
	methods: {},
	mounted() {},
}
</script>

<style lang="sass" scoped>
.footer-wrapper
	background: transparent
.footer 
	position: fixed
	background: transparent
	bottom: 0px
	right: 0
	height: 40px
	width: 100%	
	background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))
	a 
		margin: 0 5px		
		color: black
		opacity: 1
		background: white
		border: solid 1px rgb(240,240,240)
		padding: 3px 5px
</style>
