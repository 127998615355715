import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

// import { getAll, getById, getByCategory, create, update } from '@/api/internalProducts.api'
import { getActive as getActiveProducts, create as createProduct, deleteById as deleteProduct } from '@/api/internalProducts.api'
import { getAll as getAllCategories } from '@/api/productCategories.api'

// const uniq = (a) => {
// 	return a.sort().filter(function (item, pos, ary) {
// 		return !pos || item != ary[pos - 1]
// 	})
// }

const showError = (error) => {
	Vue.notify({
		group: 'main',
		title: 'Server Error',
		text: error.message,
		type: 'error',
	})
}

const state = {
	internalProducts: [],
	categories: [],
	totalCount: 0,
	loading: false,
}

const getters = {
	getField,
	getProducts: (state) => {
		return state.internalProducts
	},
	getProductsByCategory:
		(state) =>
		(category, limit = 1000, offset = 0) => {
			return state.internalProducts
				.filter((p) => p.category === category && p.visible)
				.sort((a, b) => {
					if (a.sortIndex < b.sortIndex) return -1
					else if (a.sortIndex > b.sortIndex) return 1
					else 0
				})
				.splice(offset, limit)
		},
	getCategories: (state) => {
		return (
			state.categories
				// remove empty categories
				.filter((c) => {
					const product = state.internalProducts.find((p) => p.category === c.code)
					return !!product
				})
				.sort((a, b) => {
					if (a.sortIndex < b.sortIndex) return -1
					else if (a.sortIndex > b.sortIndex) return 1
					else 0
				})
		)
	},
	getTotalCount: (state) => {
		return state.totalCount
	},
	isAvailabled: (state) => (externalArticleNumber) => {
		return !!state.internalProducts.find((ip) => ip.externalArticleNumber === externalArticleNumber)
	},
	getProductById: (state) => (internalProductId) => {
		return state.internalProducts.find((ip) => ip._id === internalProductId)
	},
}

const actions = {
	fetchProducts: async function ({ commit }) {
		try {
			commit('SET_LOADING', true)
			const products = await getActiveProducts()
			const categories = await getAllCategories()
			commit('SET_INTERNAL_PRODUCTS', {
				categories: categories.data,
				products: products.data,
				totalCount: products.data.length,
			})
			commit('SET_LOADING', false)
		} catch (error) {
			showError(error)
		}
	},
	createProduct: async ({ commit }, data) => {
		try {
			console.log(data)
			const res = await createProduct(data)

			console.log(res.created)

			commit('EXTEND_INTERNAL_PRODUCTS', { products: [res.created] })
		} catch (error) {
			showError(error)
		}
	},
	deleteProduct: async ({ commit }, data) => {
		try {
			const res = await deleteProduct(data)

			commit('REDUCE_INTERNAL_PRODUCTS', res.deletedId)
		} catch (error) {
			showError(error)
		}
	},
	// extendProducts: async function ({ commit }, data) {
	// 	try {
	// 		const response = await getByCategory(data)
	// 		commit('EXTEND_INTERNAL_PRODUCTS', response.data)
	// 	} catch (error) {
	// 		showError(error)
	// 	}
	// },
	resetProducts({ commit }) {
		commit('SET_INTERNAL_PRODUCTS', { products: [], totalCount: 0, categories: [] })
		// commit('SET_INTERNAL_PRODUCT_CATEGORIES', [])
	},
}

const mutations = {
	updateField,
	SET_INTERNAL_PRODUCTS: function (state, payload) {
		state.categories = payload.categories
		state.internalProducts = payload.products
		state.totalCount = payload.totalCount
	},
	EXTEND_INTERNAL_PRODUCTS: function (state, payload) {
		state.internalProducts = state.internalProducts.concat(payload.products)
		// state.totalCount = payload.totalCount
	},
	REDUCE_INTERNAL_PRODUCTS: function (state, deletedId) {
		const idx = state.internalProducts.map((ip) => ip._id).indexOf(deletedId)
		if (idx >= 0) state.internalProducts.splice(idx, 1)
	},
	SET_LOADING: function (state, loading) {
		state.loading = loading
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
