import { getField, updateField } from 'vuex-map-fields'
import { create as createRequest } from '@/api/requests.api'

const state = {
	// CUSTOMER
	customer: {
		company: '',
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		// from here on deprecated
		// name: '',
		// country: '',
		// postalCode: '',
		// address: '',
		// city: '',
	},
	comment: '',
	agbAccepted: false,
	rightsDisclaimer: false,

	// SHIPPING
	shippingType: 'standard', // standard, express, slow

	shippingAddress: '',
	invoiceAddress: false,

	// ADMIN REQUEST
	requestCreatedByAdmin: false,
}

const getters = {
	getField,
	getShippingAddress: (state) => {
		return state.shippingAddress
	},
}

const actions = {
	resetCustomer: ({ commit }) => {
		commit('RESET_CUSTOMER')
	},
	setCustomer: ({ commit }, customerData) => {
		commit('SET_CUSTOMER', customerData)
	},
	setShippingAddress: ({ commit }, id) => {
		console.log(id)
		commit('SET_SHIPPING_ADDRESS', id)
	},
	setInvoiceAddress: ({ commit }, id) => {
		commit('SET_INVOICE_ADDRESS', id)
	},
	setRequestCreatedByAdmin: ({ commit }, value) => {
		commit('SET_REQUEST_CREATED_BY_ADMIN', value)
	},
	insertCustomerData: ({ commit, rootState }) => {
		commit('RESET_CUSTOMER')
		commit('SET_CUSTOMER', rootState.Auth.customer)

		if (rootState.Auth.customerAddresses) {
			rootState.Auth.customerAddresses.forEach((address) => {
				if (address.mainAddress) commit('SET_SHIPPING_ADDRESS', address._id)
			})
		}
	},
	sendCustomerRequest: async ({ state, rootState }) => {
		const data = {
			customer: state.customer,
			shippingAddress: state.shippingAddress,
			invoiceAddress: state.invoiceAddress,

			shippingType: state.shippingType,
			comment: state.comment,
			agbAccepted: state.agbAccepted,
			rightsDisclaimer: state.rightsDisclaimer,

			configurations: rootState.Configurator.customerRequest.configurations,
			files: rootState.ConfiguratorFiles.files,
			sendMail: true,
			requestCreatedByAdmin: state.requestCreatedByAdmin,
		}

		if (rootState.Auth.continueAsGuest) {
			data.localCustomerAddresses = rootState.Auth.customerAddresses
		}

		console.log(data)
		const promise = createRequest(data)
		return promise
	},
}

const mutations = {
	updateField,

	RESET_CUSTOMER: function (state) {
		state.shippingType = 'standard'
		state.comment = ''
		state.agbAccepted = false
		state.customer = {
			name: '',
			company: '',
			firstname: '',
			lastname: '',
			email: '',
			country: '',
			postalCode: '',
			phone: '',
		}
	},
	SET_CUSTOMER: function (state, customerData) {
		state.shippingType = 'standard'
		state.comment = ''
		state.agbAccepted = false

		// const mainAddress = customerData.address.find((address) => address.mainAddress === true)

		state.customer = {
			// name: `${customerData.surename} ${customerData.familyname}`,
			company: customerData.name,
			firstname: customerData.surename,
			lastname: customerData.familyname,
			email: customerData.email,
			// country: mainAddress.country.name || 'Deutschland',
			// postalCode: mainAddress.zip,
			phone: customerData.phone,
			// address: mainAddress.street,
			// city: mainAddress.city,
			_id: customerData._id,
		}
	},

	SET_SHIPPING_ADDRESS: function (state, id) {
		state.shippingAddress = id
	},
	SET_INVOICE_ADDRESS: function (state, id) {
		state.invoiceAddress = id
	},
	SET_REQUEST_CREATED_BY_ADMIN: function (state, value) {
		state.requestCreatedByAdmin = value
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
