<template>
	<div>
		<v-bottom-sheet v-model="showCookiePrompt" persistent>
			<v-sheet
				class="text-center cookie-sheet d-flex flex-column justify-center"
				height="250px"
				:style="{ 'background-color': 'black' }"
			>
				<div class="py-5 content">
					<h1>cookies & datenschutz</h1>
					<p>
						Die EU-Gesetzgebung zum Datenschutz und zur Sicherung der Privatsphäre – die Allgemeine Datenschutz-Grundverordnung
						(DSGVO) und die ePrivacy Richtlinie – haben einen erheblichen Einfluss darauf, wie Ihre Website Cookies und
						Online-Tracking Ihrer Benutzer verwenden darf.
					</p>
					<div class="py-2 d-flex justify-center">
						<button class="strong" @click="agreeToCookies()">Cookies zulassen</button>
						<button @click="denyCookies()">Keine Cookies zulassen</button>
					</div>
					<div class="py-2 d-flex justify-center">
						<a href="/imprint">Impressum</a>
						<a href="/privacy">Datenschutz</a>
						<a href="/agb">AGB</a>
					</div>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: 'Cookie',
	data() {
		return {
			hasCookie: true,
			showCookiePrompt: false,
		}
	},
	mounted() {
		// this.$cookie.delete('wercster_cookie')
		this.checkForCookies()
	},
	methods: {
		checkForCookies() {
			let cookieCheck = this.$cookie.get('wercster_cookie')
			if (!cookieCheck) {
				this.showCookiePrompt = true
			} else if (cookieCheck && cookieCheck == 'allowed') {
				this.showCookiePrompt = false
				// fullstory
				if (process.env.NODE_ENV === 'production') {
					let fullStoryScript = document.createElement('script')
					fullStoryScript.src = '/fullstory.js'
					document.head.appendChild(fullStoryScript)
				}
			} else {
				this.showCookiePrompt = false
			}
		},
		agreeToCookies() {
			this.$cookie.set('wercster_cookie', 'allowed', 1)
			this.checkForCookies()
		},
		denyCookies() {
			this.$cookie.set('wercster_cookie', 'denied', 1)
			this.checkForCookies()
		},
	},
}
</script>

<style scoped lang="sass">
.cookie-sheet
	background-color: black
.content
	color: white
	display: inline-flex
	justify-content: center
	flex-direction: column
	align-items: center
	h1
		font-size: 2.5em
		font-weight: 700
		letter-spacing: -0.03em
	p
		width: 600px
		color: white
	button
		display: inline-block
		background-color: black
		width: 200px
		padding: 10px 0
		border: solid 2px white
		font-weight: 700
		margin: 0 5px
		&:hover
			background: lighten(black, 10%)
		&.strong
			background: white
			color: black
			&:hover
				background: darken(white, 10%)
	a
		color: white
		opacity: 0.8
		margin: 0 5px
		&:hover
			opacity: 1
</style>