// @TODO: https://ajv.js.org/guide/getting-started.html#install
import Ajv from 'ajv'

import TEXTILE_SCHEMA from './schemes/textile.schema'
import FINISH_SCHEMA from './schemes/finish.schema'
import VARIANT_SCHEMA from './schemes/variant.schema'

export const textileValidate = () => {
	const ajv = new Ajv({ strict: false, allErrors: true })
	// Ajv options allErrors and jsonPointers are required
	require('ajv-errors')(ajv /*, {singleError: true} */)

	ajv.addKeyword({
		keyword: 'isNotEmpty',
		type: 'string',
		validate: function validate(schema, data) {
			return typeof data === 'string' && data.trim() !== ''
		},
		errorMessage: { type: 'String should not be empty' },
		errors: true,
	})

	const validate = ajv.compile(TEXTILE_SCHEMA)

	return validate
}

export const finishValidate = () => {
	const ajv = new Ajv({ strict: false, allErrors: true })
	// Ajv options allErrors and jsonPointers are required
	require('ajv-errors')(ajv /*, {singleError: true} */)

	ajv.addKeyword({
		keyword: 'isNotEmpty',
		type: 'string',
		validate: function validate(schema, data) {
			return typeof data === 'string' && data.trim() !== ''
		},
		errorMessage: { type: 'String should not be empty' },
		errors: true,
	})

	const validate = ajv.compile(FINISH_SCHEMA)

	return validate
}

export const variantValidate = () => {
	const ajv = new Ajv({ strict: false, allErrors: true })
	// Ajv options allErrors and jsonPointers are required
	require('ajv-errors')(ajv /*, {singleError: true} */)

	ajv.addKeyword({
		keyword: 'isNotEmpty',
		type: 'string',
		validate: function validate(schema, data) {
			return typeof data === 'string' && data.trim() !== ''
		},
		errorMessage: { type: 'String should not be empty' },
		errors: true,
	})

	const validate = ajv.compile(VARIANT_SCHEMA)

	return validate
}
