import { getField, updateField } from 'vuex-map-fields'
import {
	login,
	requestMagicLink,
	validateMagicToken,
	getCustomerData,
	getCustomerDataById,
	validatePostalCode,
	loginWithOneTimeToken,
	requestLoginCode,
	validateLoginCode,
} from '@/api/auth.api'
import { addEmail, deleteEmail, updateCustomerData } from '@/api/customer.api'
import { getCustomerAddresses, newAddress, deleteUserAddress, updateUserAddress } from '@/api/customerAddresses.api'
import { v4 as uuidv4 } from 'uuid'

const state = {
	authToken: null,
	tokenTime: 0,
	customer: null,
	customerAddresses: null,
	adminUserData: null,
	continueAsGuest: false,
}

const getters = {
	getField,
	getAuthToken: (state) => {
		return state.authToken
	},
	getAdminAuthToken: (state) => {
		return state.adminUserData && state.adminUserData.accessToken
	},
	hasPermission: (state) => {
		const currentTime = new Date().getTime()
		const twoHours = 7200000
		return state.authToken !== null && currentTime - state.tokenTime < twoHours
	},
	getCustomer: (state) => {
		return state.customer
	},
	getCustomerAddresses: (state) => {
		return state.customerAddresses
	},
	customerIsLoggedIn: (state, getters) => {
		return getters.hasPermission && state.customer !== null && state.customerAddresses && state.customerAddresses.length > 0
	},
}

const actions = {
	checkForLoggedInUser: async function ({ commit, state }) {
		console.log('checkForLoggedInUser')
		// check if customer is available
		if (state.customer && !state.authToken) {
			commit('UNSET_CUSTOMER')
		}
		// check if token is available
		if (!state.customer && state.authToken) {
			commit('UNSET_TOKEN')
		}
		// check if token is still valid
		const currentTime = new Date().getTime()
		const twoHours = 7200000
		if (currentTime - state.tokenTime > twoHours) {
			commit('UNSET_CUSTOMER')
			commit('UNSET_TOKEN')
		}
	},
	login: async function ({ commit }, { username, password }) {
		const result = await login(username, password)
		if (result.auth) {
			commit('SET_TOKEN', result.token)
			return true
		} else {
			commit('SET_TOKEN', null)
			return false
		}
	},
	logout: async function ({ commit, dispatch }) {
		commit('UNSET_TOKEN')
		commit('UNSET_CUSTOMER')
		await dispatch('Requests/unsetRequestForLoggedInCustomer', null, { root: true })
	},
	// eslint-disable-next-line
	requestMagicLink: async function ({ state }, { email, orgUrl }) {
		const result = await requestMagicLink(email, orgUrl)
		if (!result.err) {
			return true
		} else {
			return false
		}
	},
	validateMagicToken: async function ({ commit }, token) {
		const result = await validateMagicToken(token)
		if (result && result.success) {
			return true
		} else {
			commit('UNSET_TOKEN')
			return false
		}
	},
	validatePostalCode: async function ({ commit }, { token, postalCode }) {
		const result = await validatePostalCode(token, postalCode)
		if (result && result.jwtToken) {
			commit('SET_TOKEN', result.jwtToken)
			commit('SET_CUSTOMER', result.customer)
			return true
		} else {
			commit('UNSET_TOKEN')
			return false
		}
	},
	getCustomerData: async function ({ commit }) {
		const promise = getCustomerData()

		promise
			.then((res) => {
				commit('SET_CUSTOMER', res.customer)
			})
			.catch((err) => {
				console.log(err)
			})

		return promise
	},
	getCustomerDataById: async function ({ commit }, customerId) {
		try {
			const promise = getCustomerDataById(customerId)

			promise.then((res) => {
				commit('SET_CUSTOMER', res.customer)
			})

			return promise
		} catch (error) {
			console.error(error)
		}
	},
	fetchCustomerAddresses: async function ({ commit, state }) {
		if (state.customer && state.customer._id) {
			const promise = getCustomerAddresses(state.customer._id)
			promise.then((result) => {
				commit('SET_CUSTOMER_ADDRESSES', result.addresses)
			})
			return promise
		} else {
			return false
		}
	},
	newAddress: async function ({ state }, data) {
		if (state.customer._id) {
			data.customerId = state.customer._id
			data.mainAddress = false
			console.log(data)
			try {
				const resp = await newAddress(data)
				return resp
			} catch (error) {
				console.log(error)
			}
		}
	},
	newAddressLocal: async function ({ commit, rootState }, data) {
		data._id = uuidv4()
		commit('ADD_CUSTOMER_ADDRESS', data)

		// set shipping address if none is selected
		if (!rootState.ConfiguratorRequest.shippingAddress) {
			rootState.ConfiguratorRequest.shippingAddress = data._id
		}
	},
	updateAddress: async function ({ state }, data) {
		if (state.customer._id) {
			console.log(data)
			data.customerId = state.customer._id

			try {
				const resp = await updateUserAddress(data)
				return resp
			} catch (error) {
				console.log(error)
			}
		}
	},
	deleteAddress: async function ({ state }, id) {
		if (state.customer._id) {
			let data = {}
			data.customerId = state.customer._id
			data._id = id

			try {
				const resp = await deleteUserAddress(data)
				return resp
			} catch (error) {
				console.log(error)
			}
		}
	},
	deleteAddressLocal: async function ({ commit }, id) {
		commit('REMOVE_CUSTOMER_ADDRESS', id)
	},
	updateCustomerData: async function ({ commit }, data) {
		const promise = updateCustomerData(data)

		promise
			.then((res) => {
				commit('SET_CUSTOMER', res.customer)
			})
			.catch((err) => {
				console.log(err)
			})

		return promise
	},
	addEmail: async function ({ commit }, email) {
		const result = await addEmail(email)
		commit('SET_CUSTOMER', result.customer)
	},
	deleteEmail: async function ({ commit }, email) {
		const result = await deleteEmail(email)
		commit('SET_CUSTOMER', result.customer)
	},
	loginWithOneTimeToken: async function ({ commit }, token) {
		const promise = loginWithOneTimeToken(token)

		promise.then((res) => {
			commit('SET_ADMIN_USER', res)
		})

		return promise
	},
	// eslint-disable-next-line
	requestLoginCode: async function ({}, email) {
		const promise = requestLoginCode(email)

		promise.catch((err) => {
			console.log(err)
		})

		return promise
	},
	// eslint-disable-next-line
	validateLoginCode: async function ({ commit }, code) {
		const promise = validateLoginCode(code)

		promise
			.then((result) => {
				if (result && result.jwtToken) {
					commit('SET_TOKEN', result.jwtToken)
					commit('SET_CUSTOMER', result.customer)
				} else {
					commit('UNSET_TOKEN')
				}
			})
			.catch((err) => {
				console.error(err)
				commit('UNSET_TOKEN')
			})

		return promise
	},
	continueAsGuest: async function ({ commit, dispatch }, email) {
		dispatch('ConfiguratorRequest/setCustomer', { email }, { root: true })
		commit('SET_CONTINUE_AS_GUEST', true)
	},
}

const mutations = {
	updateField,
	SET_TOKEN: function (state, token) {
		state.authToken = token
		state.tokenTime = new Date().getTime()
	},
	UNSET_TOKEN: function (state) {
		state.authToken = null
		state.tokenTime = 0
	},
	SET_CUSTOMER: function (state, customer) {
		state.customer = customer
	},
	SET_CUSTOMER_ADDRESSES: function (state, addresses) {
		state.customerAddresses = addresses
	},
	ADD_CUSTOMER_ADDRESS: function (state, address) {
		if (!state.customerAddresses) state.customerAddresses = []
		state.customerAddresses.push(address)
	},
	REMOVE_CUSTOMER_ADDRESS: function (state, id) {
		if (!state.customerAddresses) state.customerAddresses = []
		state.customerAddresses = state.customerAddresses.filter((address) => address._id !== id)
	},
	UNSET_CUSTOMER: function (state) {
		state.customer = null
	},
	SET_ADMIN_USER: function (state, user) {
		state.adminUserData = user
	},
	SET_CONTINUE_AS_GUEST: function (state, value) {
		state.continueAsGuest = value
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
