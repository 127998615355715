const techniqueOptions = {
	cap: [
		// { value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
	],
	shirt: [
		{ value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
		// { value: 'inside-print', label: 'Innendruck' },
		// { value: 'labels', label: 'Labels' },
	],
	longsleeve: [
		{ value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
		// { value: 'inside-print', label: 'Innendruck' },
		// { value: 'labels', label: 'Labels' },
	],
	hoodie: [
		{ value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
		// { value: 'inside-print', label: 'Innendruck' },
		// { value: 'labels', label: 'Labels' },
	],
	crewneck: [
		{ value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
		// { value: 'inside-print', label: 'Innendruck' },
		// { value: 'labels', label: 'Labels' },
	],
	other: [
		{ value: 'print', label: 'Druck' },
		{ value: 'stick', label: 'Stick' },
	],
}

const techniqueTypeOptions = {
	labels: [
		{ value: 'standard', label: 'Standard' },
		{ value: 'loop', label: 'Loop' },
	],
}

const sideOptions = {
	cap: {
		// print: [
		// 	{ value: 'front', label: 'Vorne' },
		// 	{ value: 'left', label: 'Links' },
		// 	{ value: 'right', label: 'Rechts' },
		// 	{ value: 'back', label: 'Hinten' },
		// ],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'left', label: 'Links' },
			{ value: 'right', label: 'Rechts' },
			{ value: 'back', label: 'Hinten' },
		],
	},
	shirt: {
		print: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		// 'inside-print': [{ value: 'neck', label: 'Nacken-Innen' }],
		// 'labels--loop': [
		// 	{ value: 'label-sleeve', label: 'Ärmel' },
		// 	{ value: 'label-hem', label: 'Saum' },
		// 	{ value: 'label-loop-neck', label: 'Nacken-Innen' },
		// ],
		// 'labels--standard': [
		// 	{ value: 'label-hip', label: 'Hüfte' },
		// 	{ value: 'label-front', label: 'Brust' },
		// 	{ value: 'label-standard-neck', label: 'Nacken-Innen' },
		// ],
	},
	longsleeve: {
		print: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		// 'inside-print': [{ value: 'neck', label: 'Nacken-Innen' }],
		// 'labels--loop': [
		// 	{ value: 'label-sleeve', label: 'Ärmel' },
		// 	{ value: 'label-hem', label: 'Saum' },
		// 	{ value: 'label-loop-neck', label: 'Nacken-Innen' },
		// ],
		// 'labels--standard': [
		// 	{ value: 'label-hip', label: 'Hüfte' },
		// 	{ value: 'label-front', label: 'Brust' },
		// 	{ value: 'label-standard-neck', label: 'Nacken-Innen' },
		// ],
	},
	hoodie: {
		print: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		// 'inside-print': [{ value: 'neck', label: 'Nacken-Innen' }],
		// 'labels--loop': [
		// 	{ value: 'label-sleeve', label: 'Ärmel' },
		// 	{ value: 'label-hem', label: 'Saum' },
		// 	{ value: 'label-loop-neck', label: 'Nacken-Innen' },
		// ],
		// 'labels--standard': [
		// 	{ value: 'label-hip', label: 'Hüfte' },
		// 	{ value: 'label-front', label: 'Brust' },
		// 	{ value: 'label-standard-neck', label: 'Nacken-Innen' },
		// ],
	},
	crewneck: {
		print: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
			{ value: 'shoulder-sleeve', label: 'Ärmel' },
		],
		// 'inside-print': [{ value: 'neck', label: 'Nacken-Innen' }],
		// 'labels--loop': [
		// 	{ value: 'label-sleeve', label: 'Ärmel' },
		// 	{ value: 'label-hem', label: 'Saum' },
		// 	{ value: 'label-loop-neck', label: 'Nacken-Innen' },
		// ],
		// 'labels--standard': [
		// 	{ value: 'label-hip', label: 'Hüfte' },
		// 	{ value: 'label-front', label: 'Brust' },
		// 	{ value: 'label-standard-neck', label: 'Nacken-Innen' },
		// ],
	},
	other: {
		print: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
		],
		stick: [
			{ value: 'front', label: 'Vorne' },
			{ value: 'back', label: 'Hinten' },
		],
	},
}

const positionOptions = {
	shirt: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
		// neck: [{ label: 'Mittig', value: 'neck-center' }],
		// 'label-sleeve': [
		// 	{ label: 'Links', value: 'label-sleeve-left' },
		// 	{ label: 'Rechts', value: 'label-sleeve-right' },
		// ],
		// 'label-hem': [
		// 	{ label: 'Links', value: 'label-hem-left' },
		// 	{ label: 'Rechts', value: 'label-hem-right' },
		// ],
		// 'label-standard-neck': [{ label: 'Mittig', value: 'label-standard-neck-center' }],
		// 'label-hip': [
		// 	{ label: 'Links', value: 'label-hip-left' },
		// 	{ label: 'Rechts', value: 'label-hip-right' },
		// ],
		// 'label-front': [
		// 	{ label: 'Links', value: 'label-front-left' },
		// 	{ label: 'Rechts', value: 'label-front-right' },
		// ],
		// 'label-loop-neck': [{ label: 'Mittig', value: 'label-loop-neck-center' }],
	},
	'shirt-round-neck': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	'shirt-pocket': {
		front: [
			// { label: 'Mittig', value: 'front-center' },
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	polo: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	hoodie: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	'hoodie-bag': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	'hoodie-zipper': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	longsleeve: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	'longsleeve-pocket': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	crewneck: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Links', value: 'front-left' },
			{ label: 'Mittig', value: 'front-center' },
			{ label: 'Rechts', value: 'front-right' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
		'shoulder-sleeve': [
			{ label: 'Links', value: 'shoulder-sleeve-left-custom' },
			{ label: 'Rechts', value: 'shoulder-sleeve-right-custom' },
		],
	},
	cap: {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-curved-snapback': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-dad': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-dad-belt-buckle': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-flat-snapback': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-unstructured-snapback': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	'cap-strap': {
		front: [
			{ label: 'Custom', value: 'custom-front' },
			{ label: 'Mittig', value: 'front-center' },
		],
		left: [
			{ label: 'Custom', value: 'custom-left' },
			{ label: 'Mittig', value: 'left-center' },
		],
		right: [
			{ label: 'Custom', value: 'custom-right' },
			{ label: 'Mittig', value: 'right-center' },
		],
		back: [
			{ label: 'Custom', value: 'custom-back' },
			{ label: 'Mittig', value: 'back-center' },
		],
	},
	custom: {
		front: [{ label: 'Custom', value: 'custom-front' }],
		back: [{ label: 'Custom', value: 'custom-back' }],
	},
}

const printAreaSizes = {
	shirt: {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 11,
		},
		right: {
			maxWidth: 11,
			maxHeight: 11,
		},
	},
	'shirt-round-neck': {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 11,
		},
		right: {
			maxWidth: 11,
			maxHeight: 11,
		},
	},
	'shirt-pocket': {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 11,
		},
		right: {
			maxWidth: 11,
			maxHeight: 11,
		},
	},
	polo: {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 11,
		},
		right: {
			maxWidth: 11,
			maxHeight: 11,
		},
	},
	hoodie: {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	'hoodie-bag': {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	'hoodie-zipper': {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	longsleeve: {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	'longsleeve-pocket': {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	crewneck: {
		front: {
			maxWidth: 38,
			maxHeight: 45,
		},
		back: {
			maxWidth: 38,
			maxHeight: 45,
		},
		left: {
			maxWidth: 11,
			maxHeight: 45,
		},
		right: {
			maxWidth: 11,
			maxHeight: 45,
		},
	},
	cap: {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-curved-snapback': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-dad': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-dad-belt-buckle': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-flat-snapback': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-unstructured-snapback': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 5,
		},
	},
	'cap-strap': {
		front: {
			maxWidth: 12,
			maxHeight: 6,
		},
		left: {
			maxWidth: 8,
			maxHeight: 5,
		},
		right: {
			maxWidth: 8,
			maxHeight: 5,
		},
		back: {
			maxWidth: 8,
			maxHeight: 3,
		},
	},
	custom: {
		front: {
			maxWidth: 40,
			maxHeight: 40,
		},
		back: {
			maxWidth: 40,
			maxHeight: 40,
		},
	},
}

const referenceDistances = {
	shirt: {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	'shirt-round-neck': {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	'shirt-pocket': {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	polo: {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	hoodie: {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	'hoodie-bag': {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	'hoodie-zipper': {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	longsleeve: {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	'longsleeve-pocket': {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	crewneck: {
		front: 16,
		back: 16,
		left: 11,
		right: 11,
	},
	cap: {
		front: 16,
		back: 16,
		left: 8,
		right: 8,
	},
	'cap-strap': {
		front: 16,
		back: 16,
		left: 8,
		right: 8,
	},
	custom: {
		front: 16,
		back: 16,
	},
}

const maxSizes = {
	shirt: {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 11,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	'shirt-round-neck': {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 11,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	'shirt-pocket': {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 11,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	polo: {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 11,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	hoodie: {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	'hoodie-bag': {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	'hoodie-zipper': {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	longsleeve: {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	'longsleeve-pocket': {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	crewneck: {
		front: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		back: {
			print: {
				maxWidth: 38,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 33,
				maxHeight: 30,
			},
		},
		'shoulder-sleeve': {
			print: {
				maxWidth: 11,
				maxHeight: 45,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 8,
			},
		},
	},
	cap: {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
	},
	'cap-curved-snapback': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	'cap-dad': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	'cap-dad-belt-buckle': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	'cap-flat-snapback': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	'cap-unstructured-snapback': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	'cap-strap': {
		front: {
			print: {
				maxWidth: 12,
				maxHeight: 6,
			},
			stick: {
				maxWidth: 12,
				maxHeight: 6,
			},
		},
		left: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		right: {
			print: {
				maxWidth: 8,
				maxHeight: 5,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 5,
			},
		},
		back: {
			print: {
				maxWidth: 8,
				maxHeight: 3,
			},
			stick: {
				maxWidth: 8,
				maxHeight: 3,
			},
		},
	},
	custom: {
		front: {
			print: {
				maxWidth: 40,
				maxHeight: 40,
			},
			stick: {
				maxWidth: 40,
				maxHeight: 40,
			},
		},
		back: {
			print: {
				maxWidth: 40,
				maxHeight: 40,
			},
			stick: {
				maxWidth: 40,
				maxHeight: 40,
			},
		},
	},
}

export default {
	techniqueOptions,
	techniqueTypeOptions,
	sideOptions,
	positionOptions,
	maxSizes,
	printAreaSizes,
	referenceDistances,
}
